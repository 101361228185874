import React from 'react'
import { Help } from '@material-ui/icons';
import { toastAlert }  from "../actions/toastAlert";
import config from '../lib/config.js'
import Web3 from "web3";
import { Button, TextField } from '@material-ui/core';
import WalletConnectProvider from "@walletconnect/web3-provider";

import {
  iswhitelist
} from '../actions/token';

const WalletModal =(props)=> {

  async function connectMetamask(type){
    if(type == "metamask"){
    localStorage.setItem("lout",true)
    if (window.ethereum) {
        var mynetwork = config.networkversion
        var web3 = new Web3(window.ethereum);
        try {
          if (typeof web3 !== 'undefined') {
            window.ethereum.enable().then(async function() {
            const web3  = new Web3(window.web3.currentProvider)
            if(window.web3.currentProvider.networkVersion == mynetwork){
             if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function(error, result) {
                toastAlert('success',"Wallet Connected Successfully",'success')
                localStorage.setItem("tksmudisgjeush",'yes');
                localStorage.setItem("address",result[0]);
                localStorage.setItem("lout",true)
                window.location.href="/home"
            })
          }
        }else {
          var chainId = web3.utils.toHex(mynetwork);
              var switchres = await web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainId }]
              });
              if(window.web3.currentProvider.networkVersion == mynetwork){
              if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function(error, result) {
                toastAlert('success',"Wallet Connected Successfully",'success')
                localStorage.setItem("tksmudisgjeush",'yes');
                localStorage.setItem("address",result[0]);
                localStorage.setItem("lout",true)
                window.location.href="/home"
            })
          }
        }
          //toastAlert('error',"Please Connect to Mainnet",'error')
        }
        }).catch(async(err)=>{
          if(err && (err.code=="4902"|| err.code==4902)){
            await web3.eth.getAccounts(async function(error, result) {
            window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
            chainId: config.chainid, //"0x"+config.networkversion,  ///        
            chainName: config.chainname,
            nativeCurrency: {
                name: config.chainsymbol,
                symbol: config.chainsymbol,
                decimals: 18
            },
            rpcUrls: [config.rpcUrls],
            blockExplorerUrls: [config.blockExplorerUrls]
            }]
            }).then((succ)=>{
                toastAlert('success',"Wallet Connected Successfully",'success')
                localStorage.setItem("tksmudisgjeush",'yes');
                localStorage.setItem("address",result[0]);
                localStorage.setItem("lout",true)
                window.location.href="/home"
            })
            .catch((error) => {
            console.log(error)
            }) 
          })
          }else{
            console.log(err,"=============================err1")
            toastAlert('error',"Please Connect Metamask External",'error')
          }
         })
        }else{
           toastAlert('error',"Please Add Metamask External",'error')
        }
      }catch(err){
        console.log(err,"=============================err0")
        toastAlert('error',"Please Connect Metamask External",'error')
      }
     }else{
        toastAlert('error',"Please Add Metamask External",'error')
     }
    }else if(type == "trust" ){
      try{
      var provider = new WalletConnectProvider({
        rpc: {
          [config.livechainid]: config.liverpcUrls
        },
        chainId: config.livechainid
      });

      provider.on("connect", () => {
      });

      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (config.networkversion == network) {
        localStorage.setItem("wtype",type)
        localStorage.setItem("tksmudisgjeush",'yes');
        localStorage.setItem("address",result[0]);
        localStorage.setItem("lout",true)
        window.location.href="/home"
        
      } else {
       await provider.disconnect();
        toastAlert('error', "please select Polygon chain network on your wallet", 'network');
      }
    }catch(err){
    localStorage.clear();
    window.location.reload()
   }
   }
  }

  async function confirmwhitelist(){
     if (window.ethereum) {
        var mynetwork = config.networkversion
        var web3 = new Web3(window.ethereum);
        try {
          if (typeof web3 !== 'undefined') {
            window.ethereum.enable().then(async function() {
            const web3  = new Web3(window.web3.currentProvider)
            if(window.web3.currentProvider.networkVersion == mynetwork){
             if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function(error, result) {
                var data = {
                  address : result[0]
                }
                var confir = await iswhitelist(data)
                if(confir && confir.data && confir.data.data && confir.data.data.isapproved=="no"){
                  toastAlert('success',"Already Submitted ",'success')
                }else if(confir && confir.data && confir.data.data && confir.data.data.isapproved=="rejected"){
                  toastAlert('success',"Already Rejected By Admin ",'success')
                }else if(confir && confir.data && confir.data.data && confir.data.data.isapproved=="yes"){
                  toastAlert('success',"Already Approved By Admin ",'success')
                }{
                toastAlert('success',"Request Submitted Successfully",'success')
                window.location.reload()
                }
            })
          }
        }else {
          var chainId = web3.utils.toHex(mynetwork);
              var switchres = await web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainId }]
              });
            if(window.web3.currentProvider.networkVersion == mynetwork){
             if (window.web3.currentProvider.isMetaMask === true) {
              await web3.eth.getAccounts(async function(error, result) {
                var data = {
                  address : result[0]
                }
                var confir = await iswhitelist(data)
                if(confir && confir.data && confir.data.data && confir.data.data.isapproved=="no"){
                  toastAlert('success',"Already Submitted ",'success')
                }else if(confir && confir.data && confir.data.data && confir.data.data.isapproved=="rejected"){
                  toastAlert('success',"Already Rejected By Admin ",'success')
                }else{
                toastAlert('success',"Request Submitted Successfully",'success')
                window.location.reload()
                }
            })
            }
          }
         // toastAlert('error',"Please Connect to Mainnet",'error')
        }
        }).catch(async(err)=>{
          if(err && (err.code=="4902"|| err.code==4902)){
            await web3.eth.getAccounts(async function(error, result) {
            window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
            chainId: config.chainid, //"0x"+config.networkversion,  ///        
            chainName: config.chainname,
            nativeCurrency: {
                name: config.chainsymbol,
                symbol: config.chainsymbol,
                decimals: 18
            },
            rpcUrls: [config.rpcUrls],
            blockExplorerUrls: [config.blockExplorerUrls]
            }]
            }).then((succ)=>{
                toastAlert('success',"Wallet Connected Successfully",'success')
                localStorage.setItem("tksmudisgjeush",'yes');
                localStorage.setItem("address",result[0]);
                localStorage.setItem("lout",true)
                window.location.href="/home"
            })
            .catch((error) => {
            console.log(error)
            }) 
          })
          }else{
            console.log(err,"=============================err1")
            toastAlert('error',"Please Connect Metamask External",'error')
          }
         })
        }else{
           toastAlert('error',"Please Add Metamask External",'error')
        }
      }catch(err){
        toastAlert('error',"Please Connect Metamask External",'error')
      }
     }else{
        toastAlert('error',"Please Add Metamask External",'error')
     }
  }

  return (
    <div>
    <div className="modal fade primary_modal" id="wallet_modal" tabIndex="-1" role="dialog" aria-labelledby="wallet_modal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
           <h5 className="modal-title" id="wallet_modal">Connect to a wallet</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="wallet_panel" onClick={()=>connectMetamask("metamask")}>
              <h2>Metamask</h2>
              <img src={require("../assets/images/icon_meta_mask.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel" onClick={()=>connectMetamask("trust")}>
              <h2>WalletConnect</h2>
              <img src={require("../assets/images/icon_wallet_connect.png")} alt="Icon" className="img-fluid" />
            </div>
           {/* <div className="wallet_panel">
              <h2>Coinbase Wallet</h2>
              <img src={require("../assets/images/coinbaseWalletIcon.svg")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>Fortmatic</h2>
              <img src={require("../assets/images/formatic_icon.png")} alt="Icon" className="img-fluid" />
            </div>
            <div className="wallet_panel">
              <h2>Portis</h2>
              <img src={require("../assets/images/portisIcon.png")} alt="Icon" className="img-fluid" />
            </div> */}
          </div>
        </div>
      </div>
    </div>

    <div className="modal fade primary_modal" id="confirmation_modal" tabIndex="-1" role="dialog" aria-labelledby="wallet_modal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
           <h5 className="modal-title" id="confirmation_modal">Confirmation</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="wallet_panel" >
              <h2>Are you Sure ?? Want to become a creator ?<br></br><br></br>
              <p>Remember to send us 3 of your works to <br /><a href="">nft-sign-up@tattoomoney.io</a> after we will verify the quality of your work we will grant you access to mint on our platform. Don't forget to include your wallet address. </p></h2>
            </div>
            <Button className="primary_btn btn-block" onClick={()=>confirmwhitelist()}>Confirm</Button>
          </div>
        </div>
      </div>
    </div>
    </div>
)
}

export default WalletModal;