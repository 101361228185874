import Web3 from "web3";
import "@metamask/legacy-web3";
import { toast } from "react-toastify";
import config from '../lib/config';
import WalletConnectProvider from "@walletconnect/web3-provider";

export async function getmylog() {
	if(localStorage.getItem("wtype") && localStorage.getItem("wtype")!=null && localStorage.getItem("wtype")!=undefined && localStorage.getItem("wtype")!="" && localStorage.getItem("wtype")=="trust"){
		try{
    var provider = new WalletConnectProvider({
        rpc: {
          [config.livechainid]: config.liverpcUrls
        },
        chainId: config.livechainid
      });


	      provider.on("connect", () => {
	      });
	      await provider.enable();
	      let web3 = new Web3(provider);
	      var network = await web3.eth.net.getId();
	      var result = await web3.eth.getAccounts();
	      if (config.livechainid == network) {
	      localStorage.setItem("wtype","trust")
	      localStorage.setItem("tksmudisgjeush", "yes");
	      localStorage.setItem("address", result[0]);
	      localStorage.setItem("lout",true)
	        var data = {
	        	address : result[0],
	        	provider : provider
	        }
	        return data 

	      } else {
	       await provider.disconnect();
	      }

        provider.on("disconnect", async (code: number, reason: string) => {
            localStorage.clear();
            window.location.href="/"
        });
      }catch(err){
        localStorage.clear();
            window.location.href="/"
      }
	}else{
	 if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          await window.ethereum
            .enable()
            
              const web3 = new Web3(window.web3.currentProvider);
              if (window.web3.currentProvider.isMetaMask === true) {
                if (
                  window.web3 &&
                  window.web3.eth &&
                  window.web3.eth.defaultAccount
                ) {
                  if (
                    window.web3.currentProvider.networkVersion ==
                    config.networkversion
                  ) {
                    var result = await web3.eth.getAccounts();
                	var data = {
			        	address : result[0],
			        }
			        return data 
                }
            }
        }
   }
  }catch(err){
 }
}
}

}