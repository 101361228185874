import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import { toastAlert }  from "../actions/toastAlert";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import CountUp from 'react-countup';
import config from '../lib/config'
import Searchref from "./separate/Search";

import {
  emailsubmit,
  collectorlist,
  getcmsdetails
} from '../actions/token';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Artists(props) {  

  const classes = useStyles();
  const { ...rest } = props;

  const [email,setemail] = React.useState("");
  const [page,setpage] = React.useState(1);
  const [artist,setartist] = React.useState(1);
  const [logo,setlogo] = React.useState(localStorage.getItem("logo"));
  const [status,setstatus] = React.useState(true);

  useEffect(() => {
    getcms()
    getartistlist();
  }, [])

  async function getartistlist(){
    var data = {
      "page" : page
    }
    var result = await collectorlist(data);
    if(result && result.data && result.data.data && result.data.data.length>0) {
      setartist(result.data.data)
      setstatus(true)
    }else{
      setstatus(false)
    }
  }

  async function loadmore(){
    var npage = parseInt(page)+parseInt(1)
    setpage(npage)
    var data = {
      "page" : npage
    }
    var result = await collectorlist(data);
    if(result && result.data && result.data.data && result.data.data.length>0){
      setartist(artist.concat(result.data.data))
      setstatus(true)
    }else{
      setstatus(false)
    }
  }

  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setlogo(config.baseurl+"/cmsimage/"+result.result.headerlogo)
      localStorage.setItem("logo",config.baseurl+"/cmsimage/"+result.result.headerlogo)
    }
    
  }

  async function submitemail(){
    var re = /\S+@\S+\.\S+/;
    var test = re.test(email);
    if(test == true){
      var data ={"email":email}
       var result = await emailsubmit(data);
       if(result.data.err){
        toastAlert("error","Newsletter Already Subscribed", "error")
        setemail("")
       }else{
        toastAlert("success","subscribed Successfully", "success")
        setemail("")
       }
    }else{
      toastAlert("error","Enter your valid email Id", "error")
    }
  }

  const inputChangeemail = (e) => {
    setemail(e.target.value)
  }

  async function checkwall(){
      if(localStorage.getItem("tksmudisgjeush")){

      }else{
        toastAlert("error","Connect Wallet to Move Further", "error")
      }
    }

  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logo} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
    
      <div className="main">

        {/* Artworks Section */}
        <section className="explore_section">
          <div className="container">
            <div className="title_flex">
              <h2 className="main_title">Top trending Collectors</h2>
              <Searchref />
            </div>
            <div className="explore_sec_img">
              <div className="masonry">
                {artist && artist.length>0 && artist.map((item)=>{
                  return(
                     <div className="item">
                      <Link to={item && item.name && item.name!=null && item.name!=undefined && item.name!=""? `/user/${item.name}`:`/user/${item._id.address}`} >
                      {item && item.vip &&
                        <div class="ribbon ribbon-top-left"><span>VIP</span></div>
                      }
                      {item && item.image!=undefined && item.image!=null && item.image!="" ?
                        <img src={`${config.baseurl}/profile/${item.image}`} alt="Collections" className="img-fluid" />
                        :
                        <img src={`${config.baseurl}/images/noimage.png`} alt="Collections" className="img-fluid" />
                      }
                      </Link>
                      <div className="item_content">
                        <h2 className="font-weight-bold">{item.name!=undefined && item.name!=null && item.name!=""? item.name : (item._id.address).substring(0, 6)+"......."+(item._id.address).substring(37,42)}</h2>
                        <div className="d-flex justify-content-between align-items-end mb-2"> 
                          <div>
                            <h6 className="m-0">Value of works</h6>
                            <h3>{(parseFloat(item.buyamt)+parseFloat(item.sellamt)).toFixed(4)} {config.symbol}</h3>
                          </div>
                          <div>
                            <h6 className="m-0">Highest sell price</h6>
                            <h4>{parseFloat(item.max).toFixed(4)} {config.symbol}</h4>
                          </div>                
                        </div>
                        <div className="d-flex justify-content-between align-items-end mb-2"> 
                          <div>
                            <h6 className="m-0">Average sell price</h6>
                            <h3>{parseFloat(item.sellamt)>0 && parseFloat(item.sellcnt)>0 ? (parseFloat(item.sellamt)/parseFloat(item.sellcnt)).toFixed(4):0} {config.symbol}</h3>
                          </div>
                          <div>
                            <h6 className="m-0">Works</h6>
                            <h4>{item.creatorcount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                })}
              </div>
              <div className="text-center mt-5">
              {status &&
                <Button className="primary_btn" onClick={()=>loadmore()}>Load More</Button>
              }
              </div>
            </div>
          </div>
        </section>
        
        {/* Newsletter Section */}
         <div className="newsletter_section">
          <div className="container">
            <h2>Newsletter</h2>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">                        
                  <input type="email" className="form-control primary_inp" onChange={inputChangeemail} id="email" value={email} placeholder="Email*"/>
                </div>
              </div>
              {/*<div className="custom_checkbox">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck2"/>
                  <label className="custom-control-label" htmlFor="customCheck2">Lorem ipsum dolor sit amet rivacy elit sed.</label>
                </div>
              </div>*/}
              <div className="mt-3 text-center">
                <Button className="primary_btn" onClick={submitemail}>Subscribe</Button>
              </div>
            </form>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}
