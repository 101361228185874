import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button, Hidden, Drawer, IconButton, Toolbar, AppBar } from "@material-ui/core";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { Link, NavLink } from "react-router-dom";
import WalletModal from "components/WalletModal.js";
import {
  getcmsdetails
} from '../../actions/token';
import config from "../../lib/config"
const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [headercontent,setheadercontent] = React.useState("");


  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setheadercontent(result.result.headercontent)
    }
    
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });

  async function movetoperofile(){
    window.location.href="/profile"
  }

  const brandComponent = <a className={classes.title + " logo_div"} href={config.fronturl+"/home"}>{brand}</a>;
  return (
      <div>
        <AppBar className={appBarClasses}>
        <div className="header_top">
          <Toolbar className="container">
            {leftLinks !== undefined ? brandComponent : null}
            <div className={classes.flex}>
              {leftLinks !== undefined ? (
                <Hidden mdDown implementation="css">
                  {leftLinks}
                </Hidden>
              ) : (
                brandComponent
              )}
            </div>
            <Hidden mdDown implementation="css">
              {rightLinks}
            </Hidden>
            <Hidden lgUp>
              <IconButton
                className="hamburger_icon"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Toolbar>
        </div>
        <div className="header_bottom">
          <div className="container">
            <h4>{headercontent}</h4>
            <List className={classes.list + " main_navbar"}>
              {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!="" ? 
               <ListItem className={classes.listItem}>
                 <Button className={classes.navLink + " create_btn"} onClick={()=>movetoperofile()}>{(localStorage.getItem("address")).substring(0, 6)+"......."+(localStorage.getItem("address")).substring(37,42)}</Button>
               </ListItem>
               :
                <ListItem className={classes.listItem}>
                  <Button className={classes.navLink + " create_btn"} data-toggle="modal" data-target="#wallet_modal">Connect Your Wallet</Button>
                </ListItem>
              }
              <ListItem className={classes.listItem}>
                <Button className={classes.navLink + " create_btn"}><a href="https://tattoomoney.io/buy.html" target="_blank">Buy TAT2</a></Button>
              </ListItem>
            </List>
          </div>
        </div>
        <Hidden lgUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper + " mobile_nav"
            }}
            onClose={handleDrawerToggle}
          >
            <div className={classes.appResponsive}>
              {leftLinks}
              {rightLinks}
            </div>
          </Drawer>
        </Hidden>
      </AppBar>

      <WalletModal/>
    </div>
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
