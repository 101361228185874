import axios from "axios";
import config from '../lib/config';
import Web3 from 'web3';

export const getusd = async () =>{
  try {
    // var web3 = new Web3(window.ethereum);
    //   if (web3&&web3.eth) {
    //     var CoursetroContract = new web3.eth.Contract(chainlink,config.chainlink);
    //     var usd = await CoursetroContract.methods.latestAnswer().call()
    //     return {
    //       "usd" : parseFloat(usd)/100000000
    //     }

    //   }

  var response = await axios.get("https://app.tattoomoney.io/exchange.php")
      if(response && response.data && response.data.rates){
        var price = response.data.rates
        if(price["uTAT2"] && price["uTAT2"]!=undefined && price["uTAT2"]!=null && price["uTAT2"]!=""){
         var tat2price = price["uTAT2"]
        return {
          loading: false,
          usd : tat2price,
        }
       }
      }else{

        return {
            loading: false,
            usd : 0,
          }
       }  
  }
  catch (err) {
    return {
            loading: false,
            usd : 0,
          }
    console.log(err,"=======err")
  }
      
}


export const TokenCountsDetail = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/token/TokenCountsDetail`,
            data
        });
        return {
            loading: true,
            result: respData
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getterms = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/token/getterms`,
            data
        });
        return {
            loading: true,
            result: respData
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getstatus = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/user/getstatus`,
            data
        });
        return {
            loading: true,
            result: respData
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getcmsdetails = async () => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/token/getcmsdetails`,
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const accepttattoorequest  = async (data) =>{
   try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/token/accepttattoo`,
            data
        });
        return {
            loading: false,
            status: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const tattoorequest = async (data) =>{
  try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/token/gettattoorequest`,
            data
        });
        return {
            loading: false,
            status: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const ReportRequest = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseurl}/api/user/submit-report`,
            'data':data
        });
        return {
            loading: false,
            status: respData.data.status
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const requestcheck = async (data) => {
  try{
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/requestcheck`,
      "data": data
    });
    return {
      "data": respData.data
    }
  }
  catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const TattooRequest = async (data) => {
  try{
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/TattooRequest`,
      "data": data
    });
    return {
      "data": respData.data
    }
  }
  catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

 export const headerSearch = async (data) => {

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseurl}/api/token/header-search?search=`+data.search,
        });
        return {
            loading: false,
            result: respData.data.result,
            userList: respData.data.users
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const getrecord = async(data) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/getuserrecords`,
      "data": data
    });
    return {
      "data": respData.data
    }
  }
  catch (err) {
  }
}

export const GetCategoryAction = async (payload) => {
  try {
    let respData = await axios({
      'method': 'get',
      'url': `${config.baseurl}/api/token/category/list`,
      data: payload
    });
    return {
      data: respData.data
    }
  }
  catch (err) {
  }
}

export const Bidding_Detail_Action =async (payload)=>{
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/bid/bidtotalamount`,
      data: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const viplist = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/viplist`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const artistlist = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/artistlist`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const collectorlist = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/collectorlist`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const HistoryList = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/token-history`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const viewupdate = async (data) => {
  try {
        let respData = await axios({
          'method': 'post',
          'url': `${config.baseurl}/api/token/viewupdate`,
          data: data
      });

      return {
          loading: false,
          data: respData.data

      }

  }
  catch (err) {
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const GetCollections = async (data) => {
  try {
        let respData = await axios({
          'method': 'post',
          'url': `${config.baseurl}/api/token/collection/GetCollections`,
          data: data
      });

      return {
          loading: false,
          data: respData.data

      }

  }
  catch (err) {
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const Check_name = async (payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/Check_name`,
      data: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}


export const ipfsmetadatafunciton = async (payload) => {
  try {
          let respData = await axios({
                  'method': 'post',
                  'url': `${config.baseurl}/api/token/ipfsmetadata`,
                  data:payload,

          });
          return {
                  data: respData.data
          }
  }
  catch (err) {
          return {
        }
  }
}
export const ipfsImageHashGet = async (payload) => {
 
  try {
          let respData = await axios({
                  'method': 'post',
                  'url': `${config.baseurl}/api/token/ipfsImageHashGet`,
                  data:payload,

          });
          return {
                  data: respData.data
          }
  }
  catch (err) {
          return {
          }
  }
}

export const TokenAddItemAction = async (payload) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/add/item`,
      'headers': {
        'Content-Type': 'multipart/form-data'
      },
      data: payload
    });
    return { data: respData.data }
  }
  catch (err) {
    return { error: err }
  }
}

export const TokenAddOwnerAction = async (payload) => {
  try {
    var SendData = {}
    if(payload.Count) { SendData.Count = payload.Count; }
    if(payload.Price) { SendData.Price = payload.Price; }
    if(payload.Owner) { SendData.Owner = payload.Owner; }
    if(payload.Balance) { SendData.Balance = payload.Balance; }
    if(payload.Quantity) { SendData.Quantity = payload.Quantity; }
    if(payload.ContractAddress) { SendData.ContractAddress = payload.ContractAddress; }
    if(payload.Type) { SendData.Type = payload.Type; }
    if(payload.HashValue) { SendData.HashValue = payload.HashValue; }
    if(payload.Status) { SendData.Status = payload.Status; }
    if(payload.network) { SendData.network = payload.network; }
    let resp1Data = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/add/owner`,
      data: SendData
    });
    return { data: resp1Data.data }
  }
  catch (err) {
    return {
    //errors: err.response.data
    }
  }
}

export const deletechangeprice = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/deletechangeprice`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const initialchangeprice = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/initialchangeprice`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const deletecancelorder = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/deletecancelorder`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const initialcancelorder = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/initialcancelorder`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const deleteburn = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/deleteburn`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const initialburn = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/initialburn`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const deletetransfer = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/deletetransfer`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const initialtransfer = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/initialtransfer`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const TokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/tokenCounts`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const CollectiblesList_Home = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/collectibles/list/home`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const ownCollectiblesList_myitem = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/collectibles/list/collectionlist`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const CollectiblesList_MysectionItems = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/collectibles/list/mysectionitem`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const changeReceiptStatus_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/changereceiptstatus`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}



export const CollectiblesList_myitem = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/collectibles/list/myownitem`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const GetAllCollections = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/GetAllCollections`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}


export const AddLikeAction = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/like`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const BidApply_ApproveAction = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/bid/apply`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const BurnField = async (data) => {
  try {
    
        let respData = await axios({
          'method': 'post',
          'url': `${config.baseurl}/api/token/BurnField`,
        
          data: data
      });
      return {
          loading: false,
      }
  }
  catch (err) {
      return {
          loading: false,
          error: err.response.data.errors
      }
  }
}

export const CancelBid_Action = async (payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/bid/cancel`,
      data: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const GetLikeDataAction = async (payload) => {
  try {
    let respData = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/like/list`,
      data: payload
    });
    return {
      data: respData.data
    }
  }
  catch (err) {
  }
}

export const TransferNow_Complete_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/transfer/complete`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const PurchaseNow_Complete_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/purchase/complete`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const TokenPriceChange_update_Action = async (payload) => {
  try {
    let resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/price/change`,
      data: payload
    });
    return {
      data: resp.data
    }
  }
  catch (err) {
  }
}

export const acceptBId_Action = async (payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/bid/accept`,
      data: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const iswhitelist = async (payload)=>{
    try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/v2/routesAdmin/iswhitelist`,
      data: payload
    });
    return {
      data: Resp.data
    }
  }
  catch (err) {
  }
}

export const getfees = async ()=>{
  try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseurl}/api/token/getfee`,
        });
        return {
            loading: false,
            result: respData,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const Collection_Create = async (Payload) => {
  var  bodyFormData = new FormData();
  bodyFormData.append("useraddress", Payload.useraddress);
  bodyFormData.append("newcontract", Payload.newcontract);
  bodyFormData.append("collectionname", Payload.collectionname);
  bodyFormData.append("collectionsymbol", Payload.collectionsymbol);
  bodyFormData.append("collectiondescription", Payload.collectiondescription);
  bodyFormData.append("imageUser", Payload.imageUser);
  bodyFormData.append("type", Payload.type);
  bodyFormData.append("url", Payload.url);
  try {
          let respData = await axios({
                  'method': 'post',
                  'url': `${config.baseurl}/api/token/collection/create`,
                  data:bodyFormData,
          });
          return {
                  data: respData.data
          }
  }
  catch (err) {
          return {
                  error: err.response.data
          }
  }
}

export const emailsubmit = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/newslettersub`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

