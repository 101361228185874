import React, { useEffect , useState , useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {  
  useParams,  
  useHistory  
} from "react-router-dom";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import ConnectWallet from './separate/Connect-Wallet';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Button } from "@material-ui/core";
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { LikeRef } from './separate/LikeRef';
import { PlaceABidRef } from './separate/PlaceABidRef';
import { BurnRef } from './separate/BurnRef'
import { TransferRef } from './separate/TransferRef'
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { TattooNowRef } from './separate/TattooNowRef';
import TokenItem from './separate/Token-Item'; 
import tat2abi from '../ABI/TAT2.json';
import CountUp from 'react-countup';
import config from '../lib/config';
import moment from "moment"
import  {Helmet, HelmetProvider } from 'react-helmet-async';
import Searchref from "./separate/Search";
import { toastAlert }  from "../actions/toastAlert";
import $ from "jquery";
import Web3 from 'web3';
import '@metamask/legacy-web3'
import { getmylog } from '../helper/walletconnect';

import {
  getCurAddr
} from '../actions/users';
import {
  CollectiblesList_Home,
  emailsubmit,
  getcmsdetails
} from '../actions/token';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
export default function Home(props) {  

  const classes = useStyles();
  const { ...rest } = props;
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [fullLoader, Set_fullLoader] = useState(false);
  const [tokenlist, settokenlist] = useState([]);
  const [item, Set_item] = useState({}); 
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);//my bidding balance
  const [TokenBalance, Set_TokenBalance] = useState(0);//my item balance
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [sort, setsort] = React.useState('recent');
  const [page, setpage] = React.useState(1);
  const [HitItem, Set_HitItem] = useState({});
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [totaluserbidAmt,setTotaluserbidAmt] = React.useState(0);
  const [email,setemail] = React.useState("");
  const [toplist,settoplist] = React.useState([]);
  const [loadstatus,setloadstatus] = React.useState(true);
  const [logo,setlogo] = React.useState(localStorage.getItem("logo"));

  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var TransferForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var TattooForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    getInit();
  }, [])

  async function getInit() {
    getcms();
    TokenListCall();
    TopCollectionList()
  }

  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setlogo(config.baseurl+"/cmsimage/"+result.result.headerlogo)
      localStorage.setItem("logo",config.baseurl+"/cmsimage/"+result.result.headerlogo)
    }
    
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  async function onLoadMore(){
    setpage(parseInt(page)+1)
    TokenListCall({
      page :  parseInt(page)+1
      
    });
  }

  async function AfterWalletConnected() {
    try{
      if(localStorage.getItem("tksmudisgjeush")){
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
        var curAddr = await web3.eth.getAccounts();
        var CoursetroContract = new web3.eth.Contract(tat2abi, config.tat2address);
        var tokenBal = await CoursetroContract.methods.balanceOf(curAddr[0]).call();
        var tokenBalance = tokenBal / config.decimalvalues;
        Set_MyTokenBalance(tokenBalance.toFixed(5));
    }
    }catch(err){
    }
    try{
     LikeForwardRef && LikeForwardRef.current && LikeForwardRef.current.getLikesData();
    }catch(err){

    }
  }

  async function TopCollectionList(data={}){
    var currAddr = await getCurAddr();
    var name = "All";
    var filter = "recent"
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: config.limit,
      page: parseInt(page)>0?page:1,
      currAddr: currAddr,
      CatName: name,
      topcollection: true,
      "filter" : filter,
      from:'Home'
    }
    var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      settoplist(resp.data.list);
      }
    else {
      settoplist("")
       }
  }

  async function TokenListCall(data={}) {
    var currAddr = await getCurAddr();
    var name = "All";
    var filter = "recent"
    if (data.CatName) {
      name = data.CatName
    }
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: 9,
      page: data.page? data.page: parseInt(page)>0?page:1,
      currAddr: currAddr,
      CatName: name,
      "filter" : filter,
      from:'Home'
    }
    var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      settokenlist(tokenlist.concat(resp.data.list));
      setloadstatus(true)
      }
    else {
      setloadstatus(false)
       }
  }

  async function submitemail(){
    var re = /\S+@\S+\.\S+/;
    var test = re.test(email);
    if(test == true){
      var data ={"email":email}
       var result = await emailsubmit(data);
       if(result.data.err){
        toastAlert("error","Newsletter Already Subscribed", "error")
        setemail("")
       }else{
        toastAlert("success","subscribed Successfully", "success")
        setemail("")
       }
    }else{
      toastAlert("error","Enter your valid email Id", "error")
    }
  }

  const inputChangeemail = (e) => {
    setemail(e.target.value)
  }

  const handleScroll = async (e) => {
    const position = window.pageYOffset;
    var pos = $('#scrolltop').scrollTop();
    if (pos == 0 && position >=150 && loadstatus) {
      if(loadstatus == true && document.getElementById("loadmre")){
      document.getElementById("loadmre").click();
    }
    }
  }


  return (
    <HelmetProvider>
    <div className="home_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_TokenBalance = {Set_TokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        TokenBalance = {TokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        MyItemAccountAddr={UserAccountAddr}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <TransferRef
        ref={TransferForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
       <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <TattooNowRef
        UserAccountAddr={UserAccountAddr}
        item={item}
        Set_item={Set_item}
        ref={TattooForwardRef}
        Accounts={Accounts}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <Helmet>
                <meta charSet="utf-8" />
                <title>Tattoo Money</title>
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logo} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 20,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
    
      <div className="main">

        {/* TOP Collection */}
       {/* <section className="explore_section">
          <div className="container">
            <div className="title_flex">
              <h2 className="main_title">Top Collection</h2>
            </div>            
            <div className="explore_sec_img">
              <div className="masonry">
                {(toplist  && toplist.length > 0)?(toplist.map((item) => {
                  return (
                     <TokenItem
                      id = {item._id}
                      item={item}
                      Set_item={Set_item}
                      LikedTokenList={LikedTokenList}
                      hitLike={LikeForwardRef.current.hitLike}
                      UserAccountAddr={UserAccountAddr}
                      UserAccountBal={UserAccountBal}
                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                      PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                      Set_Bids={Set_Bids}
                      Bids = {Bids}
                      Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                      Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                      Set_MyTokenBalance = {Set_MyTokenBalance}
                      Set_MyTokenDetail={Set_MyTokenDetail}
                      Set_TokenBalance={Set_TokenBalance}
                      Set_AllowedQuantity={Set_AllowedQuantity}
                      Set_YouWillPay = {Set_YouWillPay}
                      Set_YouWillPayFee = {Set_YouWillPayFee}
                      Set_YouWillGet = {Set_YouWillGet}
                      Burn_Click={BurnForwardRef.current.Burn_Click}
                      Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                      CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                      WalletConnected={WalletConnected}
                      SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                      ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                      from = {0}
                    />
                  )})):('No record Found')
                  }                               
              </div>
              <div className="text-center mt-5">
                <Button className="primary_btn">Load More</Button>
              </div>
            </div>
          </div>
        </section> */}

        {/* Artworks Section */}
        <section className="explore_section" id="scrolltop" onScroll={handleScroll}>
          <div className="container">
            <div className="title_flex">
              <h2 className="main_title">New Artworks</h2>
              <Searchref />
            </div>            
            <div className="explore_sec_img">
              <div className="masonry" >
                {(tokenlist  && tokenlist.length > 0)?(tokenlist.map((item) => {
                  return (
                     <TokenItem
                      id = {item._id}
                      item={item}
                      Set_item={Set_item}
                      LikedTokenList={LikedTokenList}
                      hitLike={LikeForwardRef.current.hitLike}
                      UserAccountAddr={UserAccountAddr}
                      UserAccountBal={UserAccountBal}
                      PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                      PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                      PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                      Set_Bids={Set_Bids}
                      Bids = {Bids}
                      Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                      Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                      Set_MyTokenBalance = {Set_MyTokenBalance}
                      Set_MyTokenDetail={Set_MyTokenDetail}
                      Set_TokenBalance={Set_TokenBalance}
                      Set_AllowedQuantity={Set_AllowedQuantity}
                      Set_YouWillPay = {Set_YouWillPay}
                      Set_YouWillPayFee = {Set_YouWillPayFee}
                      Set_YouWillGet = {Set_YouWillGet}
                      // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                      Burn_Click={BurnForwardRef.current.Burn_Click}
                      Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                      CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                      WalletConnected={WalletConnected}
                      Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                      SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                      ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                      from = {0}
                    />
                  )})):('No record Found')
                  }                               
              </div>
              {loadstatus &&
              <div className="text-center mt-5">
                <Button className="primary_btn" id="loadmre" onClick={()=>onLoadMore()}>Load More</Button>
              </div>
              }
            </div>
          </div>
        </section>
        
        {/* Newsletter Section */}
         <div className="newsletter_section">
          <div className="container">
            <h2>Newsletter</h2>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">                        
                  <input type="email" className="form-control primary_inp" onChange={inputChangeemail} id="email" value={email} placeholder="Email*"/>
                </div>
              </div>
              {/*<div className="custom_checkbox">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck2"/>
                  <label className="custom-control-label" htmlFor="customCheck2">Lorem ipsum dolor sit amet rivacy elit sed.</label>
                </div>
              </div>*/}
              <div className="mt-3 text-center">
                <Button className="primary_btn" onClick={submitemail}>Subscribe</Button>
              </div>
            </form>
          </div>
        </div>

      </div>
      <Footer />

      {/* Report Modal */}
      <div class="modal fade primary_modal" id="report_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal" role="document">
          <div class="modal-content">
              <div class="modal-header text-center">
              <h5 class="modal-title" id="report_modalLabel">Why are you reporting?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              </div>
              <div class="modal-body">
              <p>Tell us how this user violates the rules of the site</p>
              <form>
                  <label className="primary_label" htmlFor="name">Message</label>
                  <textarea className="form-control primary_inp" rows="3" name="description" placeholder="Tell us some details"></textarea>                  
                  <div className="report_btn_grp mt-3">                    
                    <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Report</Button>
                    <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                  </div>
              </form>
              </div>
          </div>
        </div>
      </div>
      {/* End Report Modal */}

      {/* Share Modal */}
      <div className="modal fade primary_modal" id="share_modal" tabindex="-1" role="dialog" aria-labelledby="share_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="share_modalLabel">Share this NFT</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="share_nft_modal">
                <a href="#" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                  <p>Telegram</p>
                </a>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                  <p>Twitter</p>
                </a>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                  <p>Facebook</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Share Modal */}
  
    </div>
    </HelmetProvider>

  );
}
