import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import { Button, TextField } from '@material-ui/core';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';
import tat2abi from '../../ABI/TAT2.json';
import { getmylog } from '../../helper/walletconnect';

import {
    AddLikeAction,
    GetLikeDataAction
} from '../../actions/token';

import {
    getCurAddr
} from '../../actions/users';



export const WalletRef = forwardRef((props, ref) => {

    useImperativeHandle(
        ref,
        () => ({
            async GetUserBal() {   
             var mydata = await getmylog();
             const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
             var currAddr = await web3.eth.getAccounts();
                if (
                    typeof currAddr !== 'undefined'
                    && currAddr
                    
                ) {
                    props.Set_UserAccountAddr(currAddr[0]);
                    props.Set_WalletConnected(true);
                    var result = await web3.eth.getAccounts()
                    var setacc = result[0];
                    var tat2contract = new web3.eth.Contract(tat2abi,config.tat2address);
                    var val= await tat2contract.methods.balanceOf(result[0]).call();
                    var balance = val / 1000000000000000000;
                    props.Set_UserAccountBal(balance);
                } else {
                    props.Set_UserAccountBal(0);
                }

            }
        }),
    )

})