import React, { useEffect , useState , useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { idText } from "typescript";
import ConnectWallet from './separate/Connect-Wallet';
import ArtistBackground from '../assets/images/artist_banner.png';
import { toastAlert }  from "../actions/toastAlert";
import { profileupdate } from "../actions/users";
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { LikeRef } from './separate/LikeRef';
import { PlaceABidRef } from './separate/PlaceABidRef';
import { BurnRef } from './separate/BurnRef'
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { TransferRef } from './separate/TransferRef'
import { CancelOrderRef } from './separate/CancelOrderRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { TattooNowRef } from './separate/TattooNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import TokenItem from './separate/Token-Item'; 
import Searchref from "./separate/Search";
import config from '../lib/config';
import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import axios from "axios"
import {
  getCurAddr,
} from '../actions/users';
import {
  CollectiblesList_Home,
  emailsubmit,
  getcmsdetails
} from '../actions/token';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Market(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [Categorylist,setCategorylist] = useState([])
  const [CatName, setCatName] = React.useState('All');
  const [sort, setsort] = React.useState('recent');
  const [page, setpage] = React.useState(0);
  const [TokenList, setTokenList] = React.useState([]);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [item, Set_item] = useState({}); 
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);//my bidding balance
  const [TokenBalance, Set_TokenBalance] = useState(0);//my item balance
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [HitItem, Set_HitItem] = useState({});
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [totaluserbidAmt,setTotaluserbidAmt] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [fullLoader, Set_fullLoader] = useState(false);
  const [logo,setlogo] = React.useState(localStorage.getItem("logo"));
  const [tokenlistpage,settokenlistpage] = useState(1);
  const [tokenliststatus,settokenliststatus] = useState(false);

  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var TattooForwardRef = useRef();
  var TransferForwardRef = useRef();

  useEffect(() => {
    getcms()
    getInit();
  }, [])

  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setlogo(config.baseurl+"/cmsimage/"+result.result.headerlogo)
      localStorage.setItem("logo",config.baseurl+"/cmsimage/"+result.result.headerlogo)
    }
    
  }

  async function getInit() {
    CategoryListCall();
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  async function CategoryListCall() {
    axios
    .get(`${config.baseurl}/api/token/category/list`)
    .then(response => {
      if (response && response.data && response.data.list) {
        setCategorylist(response.data.list);
        if(response.data.list[0]){
          TokenListCall({page:1})
        }
      }
    })
    .catch(e => console.log(e))
  }

  async function AfterWalletConnected() {
    try{
      if(localStorage.getItem("tksmudisgjeush")){
        var curAddr = await getCurAddr();
        var datas = {
          address: curAddr
        }
    }
    }catch(err){
    }
  }

  async function categorychange(categ){
    setTokenList("")
    setCatName(categ)
    settokenlistpage(1)
    TokenListCall({"CatName":categ,page:1})
  }

  async function TokenListCall(data={}) {
    var currAddr = await getCurAddr();
    var name = CatName;
    var filter = "recent"
    if (data.CatName) {
      name = data.CatName
    }
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: 9,
      page: data.page ?  data.page : parseInt(tokenlistpage)>0?tokenlistpage:1,
      currAddr: currAddr,
      CatName: name,
      "filter" : filter,
      from:'Home'
    }
    var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(resp.data.list);
      settokenliststatus(true)
    }
    else {
      settokenliststatus(false)
    }
  }

  const onLoadMoretokenlist = async () => {
    settokenlistpage(parseInt(tokenlistpage)+1)
    var data = ({
      page :  parseInt(tokenlistpage)+1
    });

    var currAddr = await getCurAddr();
    var name = CatName;
    var filter = "recent"
    if (data.CatName) {
      name = data.CatName
    }
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: 9,
      page: data.page ?  data.page : parseInt(tokenlistpage)>0?tokenlistpage:1,
      currAddr: currAddr,
      CatName: name,
      "filter" : filter,
      from:'Home'
    }
    var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      settokenliststatus(true)
    }
    else {
      settokenliststatus(false)
    }
  }


  const [email,setemail] = React.useState("");


  async function submitemail(){
    var re = /\S+@\S+\.\S+/;
    var test = re.test(email);
    if(test == true){
      var data ={"email":email}
       var result = await emailsubmit(data);
       if(result.data.err){
        toastAlert("error","Newsletter Already Subscribed", "error")
        setemail("")
       }else{
        toastAlert("success","subscribed Successfully", "success")
        setemail("")
       }
    }else{
      toastAlert("error","Enter your valid email Id", "error")
    }
  }

  const inputChangeemail = (e) => {
    setemail(e.target.value)
  }

  return (
    <div className="inner_header">
    <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_TokenBalance = {Set_TokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        TokenBalance = {TokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        MyItemAccountAddr={UserAccountAddr}
      />
      <TattooNowRef
        UserAccountAddr={UserAccountAddr}
        item={item}
        Set_item={Set_item}
        ref={TattooForwardRef}
        Accounts={Accounts}
      />
      <TransferRef
        ref={TransferForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
       <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={logo} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="container mb-3">
                <div className="title_flex">
                  <nav className="masonry_tab_nav items_tab_outer mr-5">
                    <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                      <a className="nav-link active" id="all-tab" onClick={()=>categorychange("All")} data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                        <div className="tab_head">All</div>                    
                      </a>
                      {Categorylist && Categorylist.length>0 && Categorylist.map((item,i)=>{
                      return(
                      <a className="nav-link" id="all-tab" onClick={()=>categorychange(item._id)} data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                        <div className="tab_head">{item.category}</div>                    
                      </a>
                      )})}
                    </div>
                  </nav>
                  <Searchref />
                </div>
              </div>              
            </GridItem>
          </GridContainer>
        </div>        

        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>                            
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="masonry">
                     {(TokenList && TokenList.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}                         
                  </div>
                  {tokenliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMoretokenlist}>Load More</Button>
                  </div>
                  }
                </div>
                <div className="tab-pane fade" id="tattoo" role="tabpanel" aria-labelledby="tattoo-tab">
                  <div className="proposal_panel_overall">
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="primary_btn">Browse Marketplace</Button>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
                  <div className="proposal_panel_overall">
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="primary_btn">Browse Marketplace</Button>
                      </div>
                    </div>

                  </div>
                </div>                
              </div>
            </GridItem>
          </GridContainer>
        </div>  

        {/* Newsletter Section */}
         <div className="newsletter_section">
          <div className="container">
            <h2>Newsletter</h2>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">                        
                  <input type="email" className="form-control primary_inp" onChange={inputChangeemail} id="email" value={email} placeholder="Email*"/>
                </div>
              </div>
              {/*<div className="custom_checkbox">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck2"/>
                  <label className="custom-control-label" htmlFor="customCheck2">Lorem ipsum dolor sit amet rivacy elit sed.</label>
                </div>
              </div>*/}
              <div className="mt-3 text-center">
                <Button className="primary_btn" onClick={submitemail}>Subscribe</Button>
              </div>
            </form>
          </div>
        </div>

      </div>
      <Footer />

    </div>
  );
}
