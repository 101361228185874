import React, {
    forwardRef,
    useImperativeHandle
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction
} from '../../actions/token';
import multiple
 from '../../ABI/multiple.json';
 import { getmylog } from '../../helper/walletconnect';

import single from '../../ABI/single.json';
import {
    getCurAddr
} from '../../actions/users';
import {
    convertion,
    BurnField,
    TokenCounts_Get_Detail_Action,
    initialburn,
    deleteburn
  } from '../../actions/token';
import { Button, TextField } from '@material-ui/core';
import {
    useHistory
} from "react-router-dom";
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;


export const BurnRef = forwardRef((props, ref) => {
    const history = useHistory();
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
    const [onwer_price,set_owner_price]=React.useState({})
    const [burnLoading, setBurnLoading]= React.useState(1);
    const[noofitems,setnoofitem]=React.useState(1);
    const [showingLoader, setshowingLoader] = React.useState(false);
    const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = React.useState({});
    const [OwnersDetailFirst, Set_OwnersDetailFirst] = React.useState({});
    const [tokenCounts_Detail, Set_tokenCounts_Detail] = React.useState({});
    const [Bids, Set_Bids] = React.useState({});
    const [MyTokenBalance, Set_MyTokenBalance] = React.useState(0);
    const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
    const [Burndata,setBurndata] = React.useState(0);
    var  [item, Set_item] = React.useState({}); 
    var  [item1, Set_item1] = React.useState({}); 
    // address from,
    // uint256 tokenId,
    // address token,
    // uint256 _type,
    // uint256 NOFToken
    var {
        item,
        UserAccountAddr,
        GetUserBal,
       // Set_item
    } = props;

    async function FormSubmit(item,Burntoken){
        var connectwallet=localStorage.getItem("tksmudisgjeush");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
              if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(single,item.contractAddress);
              let contract = (item.type === config.singleType) ? item.contractAddress : item.contractAddress; 
              setBurnLoading('processing');
              var initburn = {
                tokenOwner: UserAccountAddr,
                tokenCounts: item.tokenCounts,
                contractAddress:item.contractAddress,
                type:item.type,
                balance:noofitems,
                quant:item.balance
              }
              var priorupdate = await initialburn(initburn)
              CoursetroContract.methods
                  .burn(
                      // UserAccountAddr,
                      item.tokenCounts,
                      // contract,
                      // MyTokenDetail.type,
                      // noofitems
                  )
                  .send({ from: props.Accounts })
                  .then(async (result) => {
                      setBurnLoading('done');
                      var postData = {
                      tokenOwner: UserAccountAddr,
                      tokenCounts: item.tokenCounts,
                      blockHash: result.blockHash,
                      transactionHash: result.transactionHash,
                      contractAddress:item.contractAddress,
                      type:item.type,
                      balance:noofitems,
                      currAddr:UserAccountAddr,
                      quant:item.balance
                      }
                      var updateBurnField = await BurnField(postData)
                      if(updateBurnField){
                      toast.success('Burned successfully',toasterOption)
                      // window.$('#burn_token_modal').modal('hide');
                      document.getElementById('closeburn').click()
                      window.location.reload()
                      }
                  })
                  .catch(async(error) => {
                    var initburn = {
                      tokenOwner: UserAccountAddr,
                      tokenCounts: item.tokenCounts,
                      contractAddress:item.contractAddress,
                      type:item.type,
                      balance:noofitems,
                      quant:item.balance
                    }
                    var priordelete = await deleteburn(initburn)
                    setBurnLoading('try');
                    console.log('error : ', error);
                    toast.error('Order not placed', toasterOption)
                  })
            }else{
              var CoursetroContractmul = new web3.eth.Contract(multiple,item.contractAddress);
              if(noofitems>MyTokenDetail.balance){
                toast.error('Available Tokens'+""+MyTokenDetail.balance,toasterOption)
                return false;
              }
              setBurnLoading('processing');
              var initburn = {
                tokenOwner: UserAccountAddr,
                tokenCounts: item.tokenCounts,
                contractAddress:item.contractAddress,
                type:item.type,
                balance:noofitems,
                quant:item.balance
              }
              var priorupdate = await initialburn(initburn)
              CoursetroContractmul.methods
                  .burn(
                      UserAccountAddr,
                      item.tokenCounts,
                      noofitems
                   )
                  .send({ from: props.Accounts })
                  .then(async (result) => {
                      setBurnLoading('done');
                      var postData = {
                      tokenOwner: UserAccountAddr,
                      tokenCounts: item.tokenCounts,
                      blockHash: result.blockHash,
                      transactionHash: result.transactionHash,
                      contractAddress:item.contractAddress,
                      type:item.type,
                      balance:noofitems,
                      currAddr:UserAccountAddr,
                      quant:item.balance
                      }
                      var updateBurnField = await BurnField(postData)
                      if(updateBurnField){
                      toast.success('Burned successfully',toasterOption)
                      // window.$('#burn_token_modal').modal('hide');
                      document.getElementById('closeburn').click()
                      window.location.reload()
                      }
                  })
                  .catch(async(error) => {
                    var initburn = {
                      tokenOwner: UserAccountAddr,
                      tokenCounts: item.tokenCounts,
                      contractAddress:item.contractAddress,
                      type:item.type,
                      balance:noofitems,
                      quant:item.balance
                    }
                    var priordelete = await deleteburn(initburn)
                    setBurnLoading('try');
                    console.log('error : ', error);
                    toast.error('Order not placed', toasterOption)
                  })
            }
             
    }
    const TokenCounts_Get_Detail_Call = async (payload) => {
      var curAddr = await getCurAddr();
      setshowingLoader(true)
      var Resp = await TokenCounts_Get_Detail_Action(payload);
      setTimeout(() => {
        // setshowingLoader(true)
        // change
        setshowingLoader(false)
      }, 2000);
  
      if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
  
        var TokenResp = Resp.data.Detail.Resp;
        if (
          TokenResp
          && TokenResp.Token
          && TokenResp.Token[0]
          && TokenResp.Token[0].tokenowners_current
        ) {
          for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
            const element = TokenResp.Token[0].tokenowners_current[i];
            set_owner_price(element)
            if (element.tokenPrice > 0 && element.tokenOwner != curAddr) {
              Set_BuyOwnerDetailFirst(element);
              break;
            }
            if (element.tokenPrice > 0 && element.tokenOwner == curAddr) {
              Set_OwnersDetailFirst(element);
              break;
            }
          }
        }
        Set_tokenCounts_Detail(TokenResp);
        if (TokenResp.Bids) {
          Set_Bids(TokenResp.Bids);
        }
  
        var IndexVal = -1;
  
        if (TokenResp.Token[0].tokenowners_all && curAddr) {
          var tokenowners_all = TokenResp.Token[0].tokenowners_all;
          IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() == curAddr.toString() && val.balance > 0));
        }
      
        if (IndexVal > -1) {
          Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
          Set_MyTokenDetail(tokenowners_all[IndexVal])
        }
        else {
          Set_MyTokenDetail({});
          Set_MyTokenBalance(0);
        }
  
        if (TokenResp.Token && TokenResp.Token[0]) {
          //(TokenResp.Token[0]);
          Set_item1(TokenResp.Token[0]);
        }
      
    }
    }
    const handleChange = (e) => {
        if (e.target && e.target.value)
          if(MyTokenDetail>=e.target.value){
          setnoofitem(e.target.value)
            setBurnLoading('init');}
          else if(e.target.value == 0){
            setBurnLoading('zero');
          }
          else if(e.target.value == "" ){
            setBurnLoading('empty');
          }
          else if(e.target.value == undefined ){
            setBurnLoading('empty');
          }
          else{
            setBurnLoading('errors');
          }
      }

    async function cleardata(){
      setnoofitem(1)
    }

    useImperativeHandle(
        ref,
        () => ({
            async Burn_Click(item,burndata) {
                setBurndata(burndata);
                var curAddr = await getCurAddr();
                    var payload = {
                      curAddr: curAddr,
                      tokenCounts: item.tokenCounts
                    };
                    TokenCounts_Get_Detail_Call(payload);
                window.$('#burn_token_modal').modal('show');
               
            },
        }),
    )

     async function burnmodal(){
        setTimeout(() => window.$('#burn_token_modal').modal('hide'), 600);
    }

    return (
        <div class="modal fade primary_modal" id="burn_token_modal" tabindex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
		<div class="modal-content">
			<div class="modal-header text-center">
			<h5 class="modal-title" id="burn_token_modalLabel">Burn token</h5>
			<button type="button" id="closeburn" class="close" data-dismiss="modal" aria-label="Close" onClick={burnmodal}>
				<span aria-hidden="true">&times;</span>
			</button>
			</div>
			<div class="modal-body px-0">
				<form className="bid_form">
        <div className="px-4">
						<p className="checkout_text_light_white text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
					</div> 
					<div className="px-4">
						<p className="checkout_text_light_white">Are you sure to burn this token? This action cannot be undone. Token will be transfered to zero address</p>
					</div>  
					<div className="px-4 mt-4 pt-2">   
         {Burndata.type==721?    
         <div>     
						<input 
							id = "burn" 
							name="burn" 
							class="form-control"
					     value={noofitems}
               onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
             // onChange = {(e) => { handleChange(e)}}
						/>
             <div className="text-center mt-3">
              <Button 
                className="burn_btn_red primary_btn btn-block" 
                onClick={(() => FormSubmit(item1,MyTokenDetail))}
                disabled = { (burnLoading == 'done' || burnLoading=='processing' ) }
                > Burn
                  {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                  {burnLoading == 'init' && 'Continue'}
                  {burnLoading == 'processing' && 'In-progress...'}
                  {burnLoading == 'done' && 'Done'}
                  {burnLoading == 'try' && 'Try Again'}
                 
                </Button>
              <Button className="primary_btn btn-block" onClick={()=>cleardata()} data-dismiss="modal" aria-label="Close" >Cancel</Button>
            </div>
            </div>
            :
            <div>
              <input 
              id = "burn" 
              name="burn" 
              class="form-control"
              value={noofitems}
              onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
              onChange = {(e) => { handleChange(e)}}
            />
              <div className="text-center mt-3">
             { noofitems && parseFloat(noofitems)>0?
              <Button 
                className="burn_btn_red primary_btn btn-block" 
                onClick={(() => FormSubmit(item1,MyTokenDetail))}
                disabled = { (burnLoading == 'done' || burnLoading=='processing' || burnLoading == 'zero'||burnLoading == 'errors'||burnLoading == 'empty') }
                >Burn
                  {burnLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                  {burnLoading == 'init' && 'Continue'}
                  {burnLoading == 'processing' && 'In-progress...'}
                  {burnLoading == 'done' && 'Done'}
                  {burnLoading == 'try' && 'Try Again'}
                  {burnLoading == 'errors' && 'Check Balance'}
                  {burnLoading == 'zero' && "Qty can't be Zero"}
                  {burnLoading == 'empty' && "Qty can't be Emptty"}
                </Button>
                :
                <Button 
                className="burn_btn_red primary_btn btn-block" 
                >Please Choose Valid Qty
                </Button>
              }
              <Button className="primary_btn btn-block" onClick={()=>cleardata()} data-dismiss="modal" aria-label="Close">Cancel</Button>
            </div>
          </div>
        
         }
					
					</div>
				</form>
			</div>
		</div>
		</div>
	</div>
    )
})

