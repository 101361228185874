import React, {
    useEffect,
    forwardRef,
    useImperativeHandle,
    useState
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import { getmylog } from '../../helper/walletconnect';

import multiple from '../../ABI/multiple.json'
import single from '../../ABI/single.json';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action,
    getfees,
    initialchangeprice,
    deletechangeprice
} from '../../actions/token';

import {
    getCurAddr,
    halfAddrShow
} from '../../actions/users';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;


export const PutOnSaleRef = forwardRef((props, ref) => {

    const history = useHistory();
    const [singlefee, setsinglefee] = useState(0);
    const [multiplefee, setmultiplefee] = useState(0);
    const [BuyerName, Set_BuyerName] = React.useState('');
    const [blns, Set_blns] = React.useState('');
    const [bidProfile1, Set_bidProfile1] = React.useState([]);
    const [roy,setroy] = React.useState(0);
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');

    const [ValidateError, Set_ValidateError] = React.useState({});
    const [YouWillGet, Set_YouWillGet] = React.useState(0);

    const [intPrice, Set_intPrice] = React.useState(0);
    const [TokenPrice, Set_TokenPrice] = React.useState(0);
    const [TokenPrice_Initial, Set_TokenPrice_Initial] = React.useState(0);

    const inputChange = async (e) => {
        var value = e.target.value
        var name  = e.target.name
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
         var sfee = await web3.utils.toWei((singlefee).toString())
         var mfee = await web3.utils.toWei((multiplefee).toString())
        if( value != 'undefined' && value != null && value != undefined && value != ""  && name) {
          switch(name) {
            case 'TokenPrice':
                Set_TokenPrice(value);
                if(value != '' && isNaN(value) == false && value > 0) {
                    var royval = parseFloat(value)*((item.tokenRoyality)/100)
                    var weii = await web3.utils.toWei((value).toString());
                    if(item.type== 721){
                      var per = (weii * (sfee)) / 1e20;
                    }else{
                      var per = (weii * (mfee)) / 1e20;  
                    }
                    setroy(royval)
                    Set_YouWillGet( (parseFloat((weii - per) / config.decimalvalues)-parseFloat(royval)).toFixed(config.toFixed) );

                }
                ItemValidation({TokenPrice:value});
              break;
            default:
              // code block
          }
        }
    }

    const ItemValidation = async (data={}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
    
        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if(TokenPrice_Initial > 0 && Chk_TokenPrice >= TokenPrice_Initial) {
          ValidateError.TokenPrice = '"Token Price" must be less than '+TokenPrice_Initial;
        }else{
            delete ValidateError.TokenPrice;
        }
        // else if(Chk_TokenPrice > UserAccountBal) {
        //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        // }
        // else if(Chk_TokenPrice > UserAccountBal) {
        //     ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance1';
        // }
        // else {
        //     await props.GetUserBal();
        //     if(Chk_TokenPrice > UserAccountBal) {
        //         ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance2';
        //     }
        //     else {
        //         delete ValidateError.TokenPrice;
        //     }
        // }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function FormSubmit(){
        var connectwallet=localStorage.getItem("tksmudisgjeush");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }
        var errors = await ItemValidation();
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toast.error("Form validation error. Fix mistakes and submit again", toasterOption);
            return false;
        }
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
        if(item.type==721){
            var CoursetroContract = new web3.eth.Contract(single, item.contractAddress);
            // alert(TokenPrice)
            // if(TokenPrice==0||TokenPrice==null){
                var value = await web3.utils.toWei((TokenPrice).toString())
                value = await convert(value)
                Set_FormSubmitLoading('processing');
                var initData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        contractAddress: item.contractAddress
                    }
                var initupdate = await initialchangeprice(initData)
                CoursetroContract.methods
                .orderPlace(
                    props.item.tokenCounts,
                    (value).toString()
                )
                .send({ from: props.Accounts })
                .then(async (result) => {
                    Set_FormSubmitLoading('done');
                    var postData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        blockHash: result.blockHash,
                        transactionHash: result.transactionHash,
                        type:"changeprice",
                        contractAddress: item.contractAddress
                    }
                    var Resp = await TokenPriceChange_update_Action(postData)
                    if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                        toast.success("Collectible price changed successfully", toasterOption)
                        window.$('#PutOnSale_modal').modal('hide');
                       setTimeout(() => { window.location.reload(); }, 2000);
                    }
                })
                .catch(async(error) => {
                    var deleteData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        contractAddress: item.contractAddress
                    }
                   var deleteupdate = await deletechangeprice(deleteData)
                    Set_FormSubmitLoading('error');
                    toast.error('Order not placed', toasterOption)
                })
        }else{
            var CoursetroContract = new web3.eth.Contract(multiple, item.contractAddress);
            // if(TokenPrice==0||TokenPrice==null){
                var value = await web3.utils.toWei((TokenPrice).toString())
                value = await convert(value)
            Set_FormSubmitLoading('processing');
            var initData = {
                tokenOwner: UserAccountAddr,
                tokenCounts: props.item.tokenCounts,
                tokenPrice: TokenPrice,
                contractAddress: item.contractAddress,
            }
            var initupdate = await initialchangeprice(initData)
            CoursetroContract.methods
            .orderPlace(
                props.item.tokenCounts,
                (value).toString()
            )
            .send({ from: props.Accounts })
            .then(async (result) => {
                Set_FormSubmitLoading('done');
                var postData = {
                    tokenOwner: UserAccountAddr,
                    tokenCounts: props.item.tokenCounts,
                    tokenPrice: TokenPrice,
                    blockHash: result.blockHash,
                    transactionHash: result.transactionHash,
                    type:"changeprice",
                    contractAddress: item.contractAddress
                }
                var Resp = await TokenPriceChange_update_Action(postData)
                if (Resp.data && Resp.data.RetType && Resp.data.RetType=='success') {
                    toast.success("Collectible price changed successfully", toasterOption)
                    window.$('#PutOnSale_modal').modal('hide');
                    setTimeout(() => { window.location.reload(); }, 2000);
                }
            })
            .catch(async(error) => {
                var deleteData = {
                        tokenOwner: UserAccountAddr,
                        tokenCounts: props.item.tokenCounts,
                        tokenPrice: TokenPrice,
                        contractAddress: item.contractAddress
                    }
                var deleteupdate = await deletechangeprice(deleteData)
                Set_FormSubmitLoading('error');
                toast.error('Order not placed', toasterOption)
            })
        }
    }

    var {
        item,
        UserAccountAddr,
        UserAccountBal
    } = props;
    useEffect(() => {
        Set_ValidateError({});
        getfee();

    }, []);

    async function getfee(){
     var Resp = await getfees();
     if(Resp && Resp.result && Resp.result.data){
        setsinglefee(Resp.result.data.singleservicefee)
        setmultiplefee(Resp.result.data.multipleservicefee)
     }
    }

     async function putonclose(){
        setTimeout(() => window.$('#PutOnSale_modal').modal('hide'), 600);
    }

    useImperativeHandle(
        ref,
        () => ({
            async PutOnSale_Click(item,ownerdetail) {
                props.Set_HitItem(item);
                // Set_TokenPrice(ownerdetail.tokenPrice);
                Set_TokenPrice_Initial(ownerdetail.tokenPrice);
                Set_ValidateError({});
                window.$('#PutOnSale_modal').modal('show');
            }
        }),
    )

    function convert(n){
      var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead,decimal,pow] = n.toString()
       .replace(/^-/,"")
       .replace(/^([0-9]+)(e.*)/,"$1.$2")
       .split(/e|\./);
       return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }
    

    return (
        <div class="modal fade primary_modal" id="PutOnSale_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                        <h5 class="modal-title" id="accept_modalLabel">{TokenPrice_Initial==0 || TokenPrice_Initial==null ?'Put On Sale':'Change Price'}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={putonclose}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-0">
                        <div className="img_accept text-center">
                            {
                              item && item.image && item.image.split('.').pop() == "mp4" ?
                               <video src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" controls autoPlay controls playsInline loop />
                               :
                               item && item.image && item.image.split('.').pop() == "mp3" ?
                               <img src={require("../../assets/images/music.png")} style={{backgroundColor:"white"}} alt="Collections" className="img-fluid" />
                               :
                               <img src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
                            }
                        </div>
                        <p className="text-center accept_desc" >
                            <span className="buy_desc_sm" styel={{ fontSize: 12 }}>You are about to Place Order for</span>
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break">{item.tokenName}</span>
                           {/* <span className="buy_desc_sm pl-2" styel={{ fontSize: 12 }} >for</span><br />
                            <span className="buy_desc_sm_bold pl-1 bold_red owner_break" styel={{ fontSize: 10 }}>
                                {
                                item.userprofile && item.userprofile.name ?
                                <span >{item.userprofile.name}</span>
                                :
                                item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner && <span >{halfAddrShow(item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner)}</span>
                                }
                            </span> */}
                        </p>
                        <form className="bid_form" action="#">
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <div className="mx-0 pb-3"></div>
                                <label for="qty">Enter Price</label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        name="TokenPrice"
                                        id="TokenPrice"
                                        onChange={inputChange}
                                        placeholder="e.g. 10"
                                        autoComplete="off"
                                    />
                                    {ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>
                        </form>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{item.type==721?singlefee:multiplefee} % <span>{config.symbol}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Royalty fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{item.tokenRoyality} % <span>{config.symbol}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will get</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                            {item && item.tokenCreator && item.tokenCreator == props.Accounts ?
                                <p className="buy_desc_sm_bold">{(parseFloat(YouWillGet) + parseFloat(roy)).toFixed(5)} <span>{config.symbol}</span></p>
                                :
                                <p className="buy_desc_sm_bold">{YouWillGet} <span>{config.symbol}</span></p>
                            }
                            </div>
                        </div>
                        <form className="px-4">
                            {parseFloat(TokenPrice)>0 &&
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="create_btn btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Sign sell order
                                </Button>
                            </div>
                          }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
})

