import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';
import { ToastContainer} from "react-toastify";
// pages 
import Home from "views/Home.js";
import Signup from "views/signup.js";
import Signin from "views/signin.js";
import Artists from "views/artists.js";
import Artist from "views/artist.js";
import Profile from "views/profile.js";
import Art from "views/art.js";
import Collectors from "views/collectors.js";
import Market from "views/market.js";
import Create from "views/Create.js";
import HowitWorks from "views/how-it-works.js";
import Privacypolicy from "views/Privacypolicy.js";
import Terms from "views/Termsofservice.js";
import Vip from "views/vip.js"
import {Helmet} from "react-helmet";


ReactDOM.render(
  <BrowserRouter basename="/" >
  <ToastContainer/>
    <Switch>
      <Route path="/terms" component={Terms} />
      <Route path="/privacy" component={Privacypolicy} />
      <Route path="/how-it-works" component={HowitWorks} />
      <Route path="/create" component={Create} />
      <Route path="/market" component={Market} />
      <Route path="/collectors" component={Collectors} />
      <Route exact path="/art/:tokenname/:tokenidval" component={Art} />
      <Route path="/profile" component={Profile} />
      <Route path="/artist" component={Artist} />
      <Route path="/artists" component={Artists} />
      <Route path="/signin" component={Signin} />
      <Route path="/signup" component={Signup} />
      <Route path="/home" component={Home} />
      <Route path="/vip" component={Vip} />
      <Route path="/user/:paramAddress" component={Profile} />
      <Route exact path="/*" component={Home}>
        <Redirect to="/home" />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
