/*eslint-disable*/
import React , { useEffect , useState} from "react";
import { Notifications, AccountBalanceWallet } from '@material-ui/icons';
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button, Hidden } from "@material-ui/core";

// core components 
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import {
  getfees,getstatus
} from '../../actions/token';
import {
  getCurAddr,  
} from '../../actions/users';
import config from "../../lib/config"

const useStyles = makeStyles(styles);
                                        
export default function HeaderLinks(props) {
  const classes = useStyles();
  const [whiteliststatus,setwhiteliststatus] = useState(false)
  const [addressstatus,setaddressstatus] = useState(false)
  const [status,setstatus] = useState(false)

  useEffect(() => {
    getInit();
  }, [])

  async function getInit(){
    await getCurAddr();
    var setting = await getfees()
    if(setting && setting.result && setting.result.data && setting.result.data.whitelist){
      setwhiteliststatus(setting.result.data.whitelist)
    }
    getaddressstatus();

  }

  async function getaddressstatus(){
    if(localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address")){
      var datas = {
        address : localStorage.getItem("address")
      }
      var statuee = await getstatus(datas)
      if(statuee && statuee.result && statuee.result.data && statuee.result.data.status ){
        setaddressstatus(statuee.result.data.status)
      }else{
        setaddressstatus(false)
      }
      setstatus(true)
    }else{
      setstatus(true)
    }
  }

  async function movetoperofile(){
    window.location.href="/profile"
  }

  async function logout(){
    localStorage.clear();
    window.location.href="/"
  }

  return (
    <List className={classes.list + " main_navbar"}>
      {/* <ListItem className={classes.listItem}>
      <div className="search_inp_group">
        <input type="text" className="search_inp" placeholder="Search collections / creators" />
        <div className="search_inp_group_append">
          <i className="fas fa-search"></i>
        </div>
      </div>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <a className={classes.navLink} href={config.fronturl+"/market"}>Market</a>
      </ListItem>

      <ListItem className={classes.listItem}>
        <a className={classes.navLink} href={config.fronturl+"/vip"}>VIP</a>
      </ListItem>
                                     
      <ListItem className={classes.listItem}>
        <a className={classes.navLink} href={config.fronturl+"/artists"}>Artists</a>
      </ListItem>
      <ListItem className={classes.listItem}>
        <a className={classes.navLink} href={config.fronturl+"/collectors"}>Collectors</a>
      </ListItem>
      {/*{
        localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""  ?
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/profile">Profile</NavLink>
      </ListItem>
      :
      <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="#" data-toggle="modal" data-target="#wallet_modal">Profile</NavLink>
      </ListItem>
    } */}

      

      {status && whiteliststatus ?
        localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!="" && addressstatus ?
          <ListItem className={classes.listItem}>
             <a className={classes.navLink} href={config.fronturl+"/create"}>Mint NFT</a>
          </ListItem>
        : status &&
        <ListItem className={classes.listItem}>
         {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!="" ? 
            <a className={classes.navLink} href="#" data-toggle="modal" data-target="#confirmation_modal">Become a Creator</a>
            :
             <a className={classes.navLink} to="#" data-toggle="modal" data-target="#wallet_modal">Become a Creator</a>
           }
        </ListItem>
        :status &&
        <ListItem className={classes.listItem}>
       {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!="" ? 
          <a className={classes.navLink} href={config.fronturl+"/create"}>Mint NFT</a>
          :
          <a className={classes.navLink} href="#" data-toggle="modal" data-target="#wallet_modal">Mint NFT</a>
        }
      </ListItem>
      }
      {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!=""  &&
        <ListItem className={classes.listItem}>
          <a className={classes.navLink} href="#" onClick={()=>logout()}>Log Out</a>
        </ListItem>
      }

     {/* <ListItem className={classes.listItem}>
        <NavLink className={classes.navLink} to="/signin">Sign In</NavLink>
      </ListItem> */}

      <Hidden lgUp>
        {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("tksmudisgjeush")=="yes"  && localStorage.getItem("address")!=null && localStorage.getItem("address")!=undefined && localStorage.getItem("address")!="" ? 
         <ListItem className={classes.listItem}>
           <Button className={classes.navLink + " create_btn"} onClick={()=>movetoperofile()}>{(localStorage.getItem("address")).substring(0, 6)+"......."+(localStorage.getItem("address")).substring(37,42)}</Button>
         </ListItem>
         :
          <ListItem className={classes.listItem}>
            <Button className={classes.navLink + " create_btn"} data-toggle="modal" data-target="#wallet_modal">Connect Your Wallet</Button>
          </ListItem>
          }
        
        <ListItem className={classes.listItem}>
          <Button className={classes.navLink + " create_btn"}><a href="https://tattoomoney.io/buy.html" target="_blank">Buy TAT2</a></Button>
        </ListItem>
      </Hidden>

      {/* <ListItem className={classes.listItem + " menu_dropdown"}>
        <CustomDropdown
          noLiPadding
          buttonText="Hi, Tofi"
          dropdownList={[
            <NavLink to="/profile" className={classes.dropdownLink}>
              Profile
            </NavLink>,   
            <div className="noti_single">
              <h4>Balance</h4>
              <h5>0.258 ETH</h5>
            </div>       
          ]}
        />
      </ListItem> */}


      {/* <ListItem className={classes.listItem + " menu_dropdown"}>
        <CustomDropdown
          noLiPadding
          buttonText="More"
          dropdownList={[
            <NavLink to="/community" className={classes.dropdownLink}>
              Community
            </NavLink>,        
            <NavLink to="/activity" className={classes.dropdownLink}>
              Activity
            </NavLink>,
            <NavLink to="/token" className={classes.dropdownLink}>
              Token
            </NavLink>,
            <NavLink to="/discussion" className={classes.dropdownLink}>
              Discussion
            </NavLink>,
            <NavLink to="/voting" className={classes.dropdownLink}>
              Voting
            </NavLink>
          ]}
        />
      </ListItem> */}
      
      {/* <ListItem className={classes.listItem}>
        <Button className={classes.navLink + " create_btn"}>
          <Link to="/create">Create</Link>
        </Button>
      </ListItem> */}

      {/* <ListItem className={classes.listItem}>        
        <NavLink to="/notifications" color="transparent" className={classes.navLink}>
          <Notifications className="menu_icons"/>
        </NavLink>
      </ListItem> */}

      {/* <ListItem className={classes.listItem + " menu_dropdown dropdown_header_ul noti_ul"}>
        <CustomDropdown
          noLiPadding
          buttonText={ <div><Notifications className="menu_icons"/></div>}        
          dropdownList={[
            <div className="noti_parent">
              <p className="noti_head">Notifications</p>
              <div className="noti_single">
                <h4>10 Hours  before</h4>
                <h5>Cngratulations you successfully created Multiple NFT</h5>
              </div>
              <div className="noti_single">
                <h4>10 Hours  before</h4>
                <h5>Cngratulations you successfully created Multiple NFT</h5>
              </div>
            </div>
          ]}
        />
      </ListItem>

       */}

    </List>
  );
}
