/*eslint-disable*/
import React , { useState , useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ScrollButton from '../ScrollButton';
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import {
  getcmsdetails
} from '../../actions/token';
import config from "../../lib/config"

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const [logo,setlogo] = useState("")
  const [footercontent,setfootercontent] = useState("")
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  useEffect(() => {
    getInit();
  }, [])

  async function getInit() {
    getcms();
  }

  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setlogo(config.baseurl+"/cmsimage/"+result.result.footerlogo)
      setfootercontent(result.result.footercontent)
    }
    
  }

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className="footer_left">          
          <img src={logo} alt="Logo" className="img-fluid"/>
          <h3>{footercontent}</h3>
          <ScrollButton />
        </div>        
      </div>
      <div className="footer_right">
        <div className="container">
          <p className="copyright_txt">© {1900 + new Date().getYear()} TattooMoney. All Rights Reserved.</p>
          <ul>
            <li><a href={config.baseurl+"/Terms/ToS.pdf"} target='_blank'>Terms Of Services</a></li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
