import React, {
    useEffect,
    forwardRef,
    useImperativeHandle,
    useState
} from 'react';
import {
    useHistory
} from "react-router-dom";
import { Button, TextField } from '@material-ui/core';

import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import single from '../../ABI/single.json'
import TAT2ABI from '../../ABI/TAT2.json'
import multiple from '../../ABI/multiple.json'
import tat2abi from '../../ABI/TAT2.json'
import config from '../../lib/config';
import { toastAlert }  from "../../actions/toastAlert";
import { getmylog } from '../../helper/walletconnect';

import {
    AddLikeAction,
    GetLikeDataAction,
    TokenPriceChange_update_Action,
    PurchaseNow_Complete_Action,
    getfees
} from '../../actions/token';

import {
    getCurAddr,
} from '../../actions/users';



export const PurchaseNowRef = forwardRef((props, ref) => {

    const history = useHistory();

    var {
        UserAccountAddr,
        UserAccountBal,
        MyTokenBalance,
        MyItemAccountAddr,
    } = props;

    const [ApproveCallStatus, setApproveCallStatus] = React.useState('init');
    const [PurchaseCallStatus, setPurchaseCallStatus] = React.useState('init');
    const [price, Set_Price] = React.useState(0);
    const [singlefee, setsinglefee] = useState(0);
    const [multiplefee, setmultiplefee] = useState(0);
    const [BuyerName, Set_BuyerName] = React.useState('');
    const [blns, Set_blns] = React.useState('');
    const [bidProfile1, Set_bidProfile1] = React.useState([]);
    const [MultipleWei, Set_MultipleWei] = React.useState(0);
    const [NoOfToken, Set_NoOfToken] = React.useState(1);
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
    const [ValidateError, Set_ValidateError] = React.useState({});
    const [YouWillPay, Set_YouWillPay] = React.useState(0);
    const [TokenPrice, Set_TokenPrice] = React.useState(0);

    async function buymodal(){
        setTimeout(() => window.$('#PurchaseNow_modal').modal('hide'), 600);
        //setTimeout(() => window.location.reload(false), 900);
    }

    const PriceCalculate = async (data={}) => {
         var mydata = await getmylog();
         const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
         var sfee = await web3.utils.toWei((singlefee).toString())
         var mfee = await web3.utils.toWei((multiplefee).toString())
         var price = (typeof data.price != 'undefined') ? data.price : TokenPrice;
         var mynewprice = await web3.utils.toWei((price).toString())
         var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;
        var newPrice = data.type == 721 ? mynewprice : quantity *(mynewprice);
        var per =  data.type == 721 ? (newPrice * parseFloat(sfee)) / 1e20 : (newPrice * parseFloat(mfee)) / 1e20
        var mulWei = parseFloat(newPrice) + parseFloat(per);
        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
        Set_MultipleWei(mulWei);
        Set_Price(newPrice)
    }
  
    const onKeyUp = (e) => {
        var charCode = e.keyCode;
        if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
          var ValidateError = {};
          Set_ValidateError(ValidateError);
        }else{
          var ValidateError = {};
          ValidateError.NoOfToken = '"Quantity" must be a number';
          Set_NoOfToken("");
          Set_ValidateError(ValidateError);
        }
      }
      
    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
            var value = e.target.value;
            switch(e.target.name) {
                case 'NoOfToken':
                    Set_NoOfToken(value);
                    PriceCalculate({quantity:value});
                    break;
                case 'TokenPrice':
                    Set_TokenPrice(value);
                    if(value != '' && isNaN(value) == false && value > 0) {
                        PriceCalculate({price:value});
                    }
                    break;
                default:
            }
        }
    }
    const ItemValidation = async (data={}) => {
        var ValidateError = {};

        var Chk_TokenPrice = (typeof data.TokenPrice!='undefined')?data.TokenPrice:TokenPrice;
        var quantity = (typeof data.quantity != 'undefined') ? data.quantity : NoOfToken;

        var Collectible_balance = 0;
        if(item && item.tokenowners_current && item.tokenowners_current.balance) {
            Collectible_balance = item.tokenowners_current.balance;
        }

        if(quantity == '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
        }
        else if(quantity == 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than 0';;
        } 
        else if(isNaN(quantity) == true) {
          ValidateError.NoOfToken = '"Quantity" must be a number';
        }
        if(quantity > Collectible_balance) {
            ValidateError.NoOfToken = '"Quantity" must be below on '+Collectible_balance;
        }

        if(Chk_TokenPrice == '') {
            ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
        }
        else if(Chk_TokenPrice == 0) {
            ValidateError.TokenPrice = '"Token Price" must be greater than 0';;
        } 
        else if(isNaN(Chk_TokenPrice) == true) {
          ValidateError.TokenPrice = '"Token Price" must be a number';
        }
        else if(Chk_TokenPrice > UserAccountBal) {
            ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
        }
        else {
            await props.GetUserBal();
            if(Chk_TokenPrice > UserAccountBal) {
                ValidateError.TokenPrice = 'Insufficient balance, Check your wallet balance';
            }
            else {
                delete ValidateError.TokenPrice;
            }
        }
        Set_ValidateError(ValidateError);
        return ValidateError;
    }

    async function FormSubmit(){
        var errors = await ItemValidation();

        if (UserAccountBal==0) {
            toastAlert("error","Insufficient balance","error")
            return false;
        }

        if (TokenPrice>UserAccountBal) {
            toastAlert("error","Insufficient balance","error")
            return false;
        }
        var connectwallet=localStorage.getItem("tksmudisgjeush");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }
        
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
            toastAlert("error","Form validation error. Fix mistakes and submit again","error")
            return false;
        }
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 

        if(config.PurchaseTransferType == 'token') {
                    window.$('.modal').modal('hide');
                    window.$('#PurchaseStep_modal').modal('show');
                }
                else {
                    var tokenContractAddress = item.contractAddress.toString();
                    var tokenType = item.type.toString();
                    var bal = parseInt(item.balance);
                    if(item.type==721){
                        var CoursetroContract = new web3.eth.Contract(single,item.contractAddress);
                        var tat2contract = new web3.eth.Contract(tat2abi,config.tat2address);
                        var tokenamount = await web3.utils.toWei((item.tokenowners_current.tokenPrice).toString());
                        var feepercent = parseFloat(tokenamount)*singlefee
                        var sendAmount = parseFloat(tokenamount)+parseFloat(feepercent)
                        Set_FormSubmitLoading('processing');
                        var getAllowance = await tat2contract.methods.allowance(props.Accounts,item.contractAddress).call();
                        var sendVal=parseFloat(sendAmount)+parseFloat(getAllowance);
                        sendVal =await convert(sendVal)
                        tat2contract
                        .methods
                        .approve(
                          (item.type==721)?item.contractAddress:item.contractAddress,
                          sendVal.toString()
                        )
                        .send({from: props.Accounts})
                        .then(async (result) => {
                        
                        CoursetroContract.methods
                        .salewithToken(
                            config.symbol,
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            tokenamount.toString()
                        )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            Set_FormSubmitLoading('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                transactionHash: result.transactionHash
                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type=='success') {
                                toastAlert("success","Collectible purchase successfully","success")
                                window.$('.PurchaseNow_modal').modal('hide');
                                setTimeout(() => { window.location.reload(false); }, 2000);
                            }
                        })
                        .catch((error) => {
                            Set_FormSubmitLoading('error');
                            toastAlert("error",'Order not placed', "error");
                        })
                        }).catch((error) => {
                          Set_FormSubmitLoading('error');
                          toastAlert("error",'Order not placed', "error");
                        })
                    }else{
                        var CoursetroContract = new web3.eth.Contract(multiple,item.contractAddress);
                        var sendAmount = await web3.utils.toWei((item.tokenowners_current.tokenPrice).toString());
                        var value=sendAmount*NoOfToken
                        var payamt = (MultipleWei).toString()
                        Set_FormSubmitLoading('processing');
                        var tat2contract = new web3.eth.Contract(tat2abi,config.tat2address);
                        var getAllowance = await tat2contract.methods.allowance(props.Accounts,item.contractAddress).call();
                        var sendVal=parseFloat(payamt)+parseFloat(getAllowance);
                        sendVal =await convert(sendVal)
                        tat2contract
                        .methods
                        .approve(
                          item.contractAddress,
                          sendVal.toString()
                        )
                        .send({from: props.Accounts})
                        .then(async (result) => {

                        CoursetroContract.methods
                        .saleWithToken(
                            item.tokenowners_current.tokenOwner,
                            item.tokenCounts,
                            value.toString(),
                            NoOfToken,
                            config.symbol
                        )
                        .send({
                            from: props.Accounts
                        })
                        .then(async (result) => {
                            Set_FormSubmitLoading('done');
                            var postData = {
                                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                                UserAccountAddr: UserAccountAddr, // new owner
                                tokenCounts: item.tokenCounts,
                                tokenType: item.type,
                                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                                transactionHash: result.transactionHash
                            }
                            var Resp = await PurchaseNow_Complete_Action(postData);
                            if (Resp.data && Resp.data.toast && Resp.data.toast.type=='success') {
                                toastAlert("success",'Collectible purchase successfully', "success");
                                window.$('.PurchaseNow_modal').modal('hide');
                                setTimeout(() => { window.location.reload(false); }, 2000);
                            }
                        })
                        .catch((error) => {
                            Set_FormSubmitLoading('error');
                            toastAlert("error",'Order not placed', "error");
                        })
                      })
                    .catch((error) => {
                        Set_FormSubmitLoading('error');
                        toastAlert("error",'Order not placed', "error");
                    })
                    }
                  
                }
    }

    async function FormSubmit_StepOne(){
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
        var tokenContractAddress = item.contractAddress.toString();
        var tokenType = item.type.toString();
        var bal = parseInt(item.balance);

        var CoursetroContract = new web3.eth.Contract(
            TAT2ABI,
            config.tat2address
        )
        var sendAmount = await web3.utils.toWei((YouWillPay).toString());

        setApproveCallStatus('processing');

        CoursetroContract.methods
        .approve(
            item.contractAddress,
            sendAmount
        )
        .send({
            from: props.Accounts,
        })
        .then(async (result) => {
            setApproveCallStatus('done');
        })
        .catch((error) => {
            setApproveCallStatus('tryagain');
            toastAlert("error",'Order not approved',"error");
        })
    }


    async function FormSubmit_StepTwo(){
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);

        var tokenContractAddress = item.contractAddress.toString();
        var tokenType = item.type.toString();
        var bal = parseInt(item.balance);
        var CoursetroContract = new web3.eth.Contract(
            multiple,
            item.contractAddress
        );
        var sendAmount = await web3.utils.toWei((item.tokenPrice).toString());
        sendAmount = await convert(sendAmount)
        setPurchaseCallStatus('processing');
        CoursetroContract.methods
        .salewithToken(
            config.symbol,
            item.tokenowners_current.tokenOwner,
            item.tokenCounts,
            sendAmount
        )
        .send({
            from: props.Accounts,
            value:MultipleWei
        })
        .then(async (result) => {
            setPurchaseCallStatus('done');
            var postData = {
                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                UserAccountAddr: UserAccountAddr, // new owner
                tokenCounts: item.tokenCounts,
                tokenType: item.type,
                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                transactionHash: result.transactionHash
            }
            var Resp = await PurchaseNow_Complete_Action(postData);
            if (Resp.data && Resp.data.toast && Resp.data.toast.type=='success') {
                toastAlert("success","Collectible purchase successfully", "success")
                window.$('.PurchaseNow_modal').modal('hide');
                setTimeout(() => { window.location.reload(false); }, 2000);
            }
        })
        .catch((error) => {
            setPurchaseCallStatus('tryagain');
            toastAlert("error",'Order not placed',"error");
        })
           
    }

    const [item, Set_item] = React.useState(props.item);

    useEffect(() => {
        Set_ValidateError({});
        ItemValidation({TokenPrice:TokenPrice});
        getfee();
    }, [
        TokenPrice
    ]);

     async function getfee(){
     var Resp = await getfees();
     if(Resp && Resp.result && Resp.result.data){
        setsinglefee(Resp.result.data.singleservicefee)
        setmultiplefee(Resp.result.data.multipleservicefee)
     }
    }

    useImperativeHandle(
        ref,
        () => ({
            async PurchaseNow_Click(item, BuyOwnerDetail={}) {
                if(BuyOwnerDetail && typeof BuyOwnerDetail.tokenOwner != 'undefined') {
                    item.tokenowners_current = {};
                    item.tokenowners_current = BuyOwnerDetail;
                }
                if(item && item.tokenowners_current && item.tokenowners_current.tokenPrice) {
                    Set_item(item);
                    Set_TokenPrice(item.tokenowners_current.tokenPrice);
                    Set_NoOfToken(1);
                    PriceCalculate({quantity:1,price:item.tokenowners_current.tokenPrice,type:item.type});
                    window.$('#PurchaseNow_modal').modal('show');
                }
            }
        }),
    )

    function convert(n){
      var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead,decimal,pow] = n.toString()
       .replace(/^-/,"")
       .replace(/^([0-9]+)(e.*)/,"$1.$2")
       .split(/e|\./);
       return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }


    return (
        <div>
            <div class="modal fade primary_modal PurchaseNow_modal" id="PurchaseNow_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header text-center">
                    <h5 class="modal-title" id="buy_modalLabel">Checkout</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="close9" onClick={(buymodal)}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body px-0">
                        <div className="row mx-0">
                            <div className="col-12 col-sm-3 px-4">
                                <p className="buy_title_sm">Owner</p>
                            </div>
                            <div className="col-12 col-md-9 px-4 whispace_wrap">
                                   <span>{item.userprofile && item.userprofile.name? item.userprofile && item.userprofile.name : item&&item.tokenowners_current&&item.tokenowners_current.tokenOwner}</span> 
                                       </div>
                        </div>
                        <div className="row mx-0">
                            <div className="col-12 col-md-3 px-4">
                                <p className="buy_title_sm">Buyer</p>
                            </div>
                            <div className="col-12 col-md-9 px-4 whispace_wrap">
                               <span> {MyItemAccountAddr ? MyItemAccountAddr : UserAccountAddr}</span>
                            </div>
                        </div>
                        <div className="bor_bot_modal mb-3 px-4 "></div>
                        <form className="bid_form" action="#">
                            {item.type == 721 ?(''):
                            <div className="bor_bot_modal mb-3 px-4 ">
                                <label for="qty">Quantity ( {item && item.tokenowners_current && item.tokenowners_current.balance} Available )</label>
                                <div class="mb-3 input_grp_style_1">
                                    <input
                                        type="text"
                                        className="form-control primary_inp text-center"
                                        name="NoOfToken"
                                        id="NoOfToken"
                                        onChange={inputChange}
                                        placeholder="e.g. 2"
                                        autoComplete="off"
                                        value={NoOfToken}
                                        onKeyUp={onKeyUp}
                                    />
                                    {ValidateError.NoOfToken && <span className="text-danger">{ValidateError.NoOfToken}</span>}
                                    {!ValidateError.NoOfToken && ValidateError.TokenPrice && <span className="text-danger">{ValidateError.TokenPrice}</span>}
                                </div>
                            </div>}
                        </form>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Your balance</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{UserAccountBal} {config.symbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Price</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{TokenPrice} {config.symbol}</p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">Service fee</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{item.type==721?singlefee:multiplefee}% <span>{config.symbol}</span></p>
                            </div>
                        </div>
                        <div className="row mx-0 pb-3">
                            <div className="col-12 col-sm-6 px-4">
                                <p className="buy_desc_sm">You will pay</p>
                            </div>
                            <div className="col-12 col-sm-6 px-4 text-sm-right">
                                <p className="buy_desc_sm_bold">{YouWillPay} <span>{config.symbol}</span></p>
                            </div>
                        </div>
                        <form className="px-4">
                            <div className="text-center">
                                <Button
                                    type="button"
                                    className="primary_btn btn-block"
                                    onClick={() => FormSubmit()}
                                    disabled={(FormSubmitLoading=='processing')}
                                >
                                    {FormSubmitLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                    Proceed to payment
                                </Button>
                                <Button className="cancel_btn  btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            <div class="modal fade primary_modal PurchaseStep_modal" id="PurchaseStep_modal" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="PurchaseStepCenteredLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                        <h5 class="modal-title" id="PurchaseStepLabel">Follow Steps</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="text-center">
                                    <p className="mt-3 purchase_desc text-center">Approve the transaction</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepOne()}
                                        className={"primary_btn " + ( (ApproveCallStatus=='processing' || ApproveCallStatus=='done') ? 'cancel_btn' : 'btn-block')}
                                        disabled={(ApproveCallStatus=='processing' || ApproveCallStatus=='done')}
                                    >
                                        {ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {ApproveCallStatus == 'init' && 'Approve'}
                                        {ApproveCallStatus == 'processing' && 'In-progress...'}
                                        {ApproveCallStatus == 'done' && 'Done'}
                                        {ApproveCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                                <div className="text-center my-3">
                                <p className="mt-3 purchase_desc text-center">Send transaction with your wallet</p>
                                    <Button
                                        type="button"
                                        onClick={() => FormSubmit_StepTwo()}
                                        className={"primary_btn " + ( (ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done') ? 'cancel_btn' : 'btn-block')}
                                        disabled={(ApproveCallStatus!='done' || PurchaseCallStatus=='processing' || PurchaseCallStatus=='done')}
                                    >
                                        {PurchaseCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                                        {PurchaseCallStatus == 'init' && 'Purchase'}
                                        {PurchaseCallStatus == 'processing' && 'In-progress...'}
                                        {PurchaseCallStatus == 'done' && 'Done'}
                                        {PurchaseCallStatus == 'tryagain' && 'Try Again'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

