import React, {useEffect,useState} from "react";
import { Link } from "react-router-dom";
import { Button, TextField } from '@material-ui/core';
import Countdown from "react-countdown";
import { toastAlert }  from "../../actions/toastAlert";
import config from '../../lib/config';
import isEmpty from '../../lib/isEmpty';
import {
    getCurAddr,
    getprofilerawdetail
  } from '../../actions/users';
  
import {
    TokenCounts_Get_Detail_Action,
    BidApply_ApproveAction,
    acceptBId_Action,
    getusd
  } from '../../actions/token';
import { TwitterIcon, FacebookIcon, FacebookShareButton, FacebookShareCount, TwitterShareButton, TelegramShareButton, WhatsappShareButton } from 'react-share'
const moment        = require("moment");
//const IPFS_IMGurl   = config.IPFS_IMG;
const IPFS_IMGurl = config.baseurl+"/nftImg"
let localstore      = localStorage.getItem("tksmudisgjeush")  

export default function TokenItem(props) {

  const [usd,setusd] = useState(0)
  const [block,setblock] = useState(1)

  useEffect(() => {
    getInit();
  }, [])

  async function getInit() {
    var usd = await getusd()
    if(usd && usd.usd){
      setusd(usd.usd)
    }
    if(localStorage.getItem("tksmudisgjeush")){
      var datas = {
        address : localStorage.getItem("address")
      }
      var userblock = await getprofilerawdetail(datas)
      if(userblock && userblock.data && userblock.data.data && userblock.data.data.data){
        setblock(userblock.data.data.data.deleted)
      }
    }
  }

    async function showAllwithPro(data) {
        var curAddr = await getCurAddr();
        var payload = {
          curAddr:curAddr,
          tokenCounts:data.tokenCounts
        };
        TokenCounts_Get_Detail_Call(payload);
    }
    const TokenCounts_Get_Detail_Call = async (payload) => {
        var curAddr = await getCurAddr();
        var Resp = await TokenCounts_Get_Detail_Action(payload);
        if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
          var TokenResp = Resp.data.Detail.Resp;
          if(
            TokenResp
            && TokenResp.Token
            && TokenResp.Token[0]
            && TokenResp.Token[0].tokenowners_current
          ) {
            for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
              const element = TokenResp.Token[0].tokenowners_current[i];
              if(element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
                Set_BuyOwnerDetailFirst(element);
                break;
              }
            }
          }
          Set_tokenCounts_Detail(TokenResp); 
          if(TokenResp.Bids) {
            Set_Bids(TokenResp.Bids);
          }
          var IndexVal = -1;
          if(TokenResp.Token[0].tokenowners_all && curAddr) {
            var tokenowners_all = TokenResp.Token[0].tokenowners_all;
            IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
          }
          var newMyTokenBalance = 0;
          if(IndexVal > -1) {
            newMyTokenBalance = tokenowners_all[IndexVal].balance
            Set_TokenBalance(newMyTokenBalance);
            Set_MyTokenDetail(tokenowners_all[IndexVal]);
          }
          else {
            newMyTokenBalance = 0;
            Set_TokenBalance(0);
            Set_MyTokenDetail({});
          }
          if(TokenResp.TotalQuantity) {
            Set_AllowedQuantity(parseInt(TokenResp.TotalQuantity) - parseInt(newMyTokenBalance));
          }
          else {
            Set_AllowedQuantity(0);
          }
          if(TokenResp.Token && TokenResp.Token[0]) {
            Set_item(TokenResp.Token[0]);
          } 
        }
      }
    async function buyToken() {
    }
    var {
        item,
        LikedTokenList,
        hitLike,
        UserAccountAddr,
        UserAccountBal,
        PutOnSale_Click,
        PlaceABid_Click,
        PurchaseNow_Click,
        Burn_Click,
        Transfer_Click,
        CancelOrder_Click,
        WalletConnected,
        ShareSocial_Click,
        SubmitReport_Click,
        Tattoorequest_Click,
        TokenBalance,
        Set_item,
        Set_Bids,
        Set_BuyOwnerDetailFirst,
        Set_tokenCounts_Detail,
        Set_MyTokenBalance,
        Set_TokenBalance,
        Set_MyTokenDetail,
        Set_AllowedQuantity,
        Bids,
        from
    } = props;
    
    const renderer1 = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s to start</span>;
        }
    };
    const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s left</span>;
        }
    };
    var currDate = new Date();
    var startdate = new Date(item.clocktime);
    var enddate = new Date(item.endclocktime);
    var auction = "false";
    var finish = "";
    var enddate1 = "";
    var showlist = "true";
    var display_item = 0;
    var myqty = ""
    let mytr = ""
    if(item && item.tokenowners_all && item.tokenowners_all.length>0){
      var tokenallowner = item.tokenowners_all
      let myitem = (tokenallowner).findIndex(indexitem => (indexitem.tokenOwner == UserAccountAddr))
      myqty = item.tokenowners_all[myitem]
    }
    if (item.type==721&&item.PutOnSaleType=="TimedAuction") {
        auction = "true";
        var a = moment(item.clocktime);
        var b = moment(item.endclocktime);
        var c = moment();
        a.diff(b); // 86400000
        var diffInMs = a.diff(c);
        finish = b.diff(c);
        enddate1 = parseFloat(diffInMs);
        display_item = a.diff(c)
      // if(currDate<startdate){
      //   var auctionTxt = "Start";
      // }else
      if(display_item >0){
        showlist = "false";
      }else if (finish > 0) {
        showlist = "true";
        } else {
        var auctionTxt = "Ended";
        showlist = "false";
        }
    }
    if(item && item.Tattoorequest && item.Tattoorequest.length>0 && UserAccountAddr && UserAccountAddr!=null && UserAccountAddr!=undefined && UserAccountAddr!=""){
       mytr = (item.Tattoorequest).filter(x=>x.useraddress==UserAccountAddr)
    }

    async function checkwall(){
      if(localStorage.getItem("tksmudisgjeush")){

      }else{
        toastAlert("error","Connect Wallet to Move Further", "error")
      }
    }

   
    return (
       
        (item.tokenowners_current && from!=item.tokenCounts)?
        <div className="item" key = {item._id}>
          <a  href={config.fronturl+"/art/"+item.tokenName+"/"+item.tokenCounts}  className="label_position">
            {
            item.image.split('.').pop() == "mp4" ?
            <video src={`${IPFS_IMGurl}/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
            :
            item.image.split('.').pop() == "mp3" ?
            <img src={require("../../assets/images/music.png")} alt="Collections" className="img-fluid" style={{backgroundColor:"white"}}/>
            :
            <img src={`${IPFS_IMGurl}/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
            }
            {item.istattooed==true && item.isskintattoo == true ?
              <p className="label_new">Tattooed</p>
              :
              item.isskintattoo == true  && mytr && mytr.length>0 && 
              <p className="label_new">Tattoo Had Been Requested</p>
            }
            </a>
            {(item.image.split('.').pop() == 'mp3') ?
              <audio src={`${IPFS_IMGurl}/${item.tokenCreator}/${item.image}`}  type="audio/mp3"controls className="audio audio_widyth">
              </audio>:""
             }
            <div className="item_content">
            {item && item.PutOnSaleType == "TimedAuction"&& showlist == "true" && display_item <=0 &&
             <div className="countdown_panel">
            <div className="countdown_panel_div">
              <Countdown
                date={enddate}
                autoStart={true}
                onStart={() => startdate}
                renderer={renderer}
              ></Countdown>
            </div>
          </div>}
           {item && item.PutOnSaleType == "TimedAuction"&& showlist == "false" && display_item >0 &&
          <div className="countdown_panel">
            <div className="countdown_panel_div">
              <Countdown
                date={startdate}
                autoStart={true}
                onStart={() => Date.now()}
                renderer={renderer1}
              ></Countdown>
            </div>
          </div>
          }
          {showlist=='false'&&auction=="true" &&
            <div className="countdown_panel">
            <div className="countdown_panel_div">
            {auctionTxt}
            </div>
                </div>
                }
              <div className="d-flex align-items-center justify-content-between">
                <h2>{item.tokenName +"   "}   

                {
                (
                  (item.TabName!='' && (item.TabName=='onsale' || item.TabName=='created' || item.TabName=='owned' ))|| item.from=='art'
                )
                ?
                  //{/*item.tokenowners_my_balance+' of '+item.tokenowners_my_quantity*/}
                  item.tokenowners_my_balance+' of '+item.tokenQuantity
                :
                  (
                    item.TabName!=''
                  )
                  ?
                    item.tokenowners_all_balance+' of '+item.tokenQuantity
                  :
                    
                    item.balance+' of '+item.tokenQuantity
                }
                </h2>  
                <div className="dropdown d-inline-block" onClick={()=>showAllwithPro(item)}>
                  <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                  <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" >
                  {(WalletConnected == true && block== 1 && item.tokenowners_current && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice > parseFloat(0) &&  item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr && localstore) &&
                    <a className="dropdown-item" href="#" onClick={() => PurchaseNow_Click(item)}>Purchase Now</a>
                   }
                   {(WalletConnected == true && block== 1 && item.tokenowners_current  && (item.PutOnSaleType=='FixedPrice'|| (item.PutOnSaleType=='TimedAuction' && showlist == "true") || item.PutOnSaleType=='UnLimitedAuction' ) && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr) &&
                     ((Bids && Bids.myBid
                        && Bids.myBid.status
                        && !Bids.myBid.status)||Bids.myBid==undefined || Bids.myBid==null || Bids.myBid=="") && localstore == "yes"
                     ?
                    <a className="dropdown-item" href="#" onClick={() => PlaceABid_Click(item)}>New Bid</a>
                    :
                    Bids && block== 1
                    && Bids.myBid
                    && Bids.myBid.status
                    && Bids.myBid.status == 'pending' && localstore && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr ?
                    <a className="dropdown-item" href="#" onClick={() => PlaceABid_Click(item)}>Edit Bid</a>
                        :  block== 1 && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner != UserAccountAddr && showlist!="false" && WalletConnected == true &&
                    <a className="dropdown-item" href="#" onClick={() => PlaceABid_Click(item)}>New Bid</a>

                  }
                  {( item.tokenowners_current && block== 1 && item.tokenowners_current.tokenOwner && item.tokenowners_current.tokenOwner == UserAccountAddr && parseFloat(item.tokenowners_current.balance) > 0  && localstore) &&
                  <div className="content" id={"editnotshow" + item.tokenCounts}>
                      <a className="dropdown-item" href="#" onClick={() => PutOnSale_Click(item, item.tokenowners_current)}>{item.tokenowners_current.tokenPrice > parseFloat(0) ?'Change price':'Put on sale'}</a>
                      <a className="dropdown-item" href="#" onClick={() => Transfer_Click(item, item.tokenowners_current)}>Transfer</a>
                  {item.tokenowners_current.tokenPrice > parseFloat(0) &&
                      <a className="dropdown-item" href="#" onClick={() => CancelOrder_Click(item,item.tokenowners_current)}>Cancel Order</a>
                  }
                      <a className="dropdown-item" href="#" onClick={() => Burn_Click(item, item.tokenowners_current)}>Burn</a>
                  
                  </div>
                  }
                  {(WalletConnected == true &&  block== 1 && item.tokenowners_current &&  item.tokenowners_current.tokenOwner && (item.tokenowners_current.tokenOwner == UserAccountAddr || (myqty && myqty.balance && parseFloat(myqty.balance)>0)) && item.tokenCreator!=UserAccountAddr && localstore && item.istattooed==false && item.isskintattoo == true) &&
                    <a className="dropdown-item" href="#" onClick={() => Tattoorequest_Click(item)}>Request Tattoo</a>
                   }

                    <a className="dropdown-item" href="#"  onClick={() => ShareSocial_Click(item)}>Share</a>
                    <a className="dropdown-item" href="#" onClick={() => SubmitReport_Click(item)}>Report</a>
                  </div>
                </div>
              </div>
              {(item.PutOnSale == false || (item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice == 0)) && 
              <h6 className="m-0">Not for sale</h6>
              }
              {(item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice > 0) && 
              <h6 className="m-0">Price </h6>
              }
              {(item.PutOnSale == true && item.PutOnSaleType=='FixedPrice' && item.tokenowners_current.tokenPrice > 0) && 
              <div className="d-flex justify-content-between align-items-end">                      
                <h3>{item.tokenowners_current.tokenPrice} {config.symbol}</h3>
                <h4>${(parseFloat(usd)*parseFloat(item.tokenowners_current.tokenPrice)).toFixed(4)}</h4>                
              </div>
              }
              {(item.PutOnSale == true && item.PutOnSaleType=='TimedAuction') && 
              <h6 className="m-0">Minimum Bid</h6>
              }
              {(item.PutOnSale == true && item.PutOnSaleType=='TimedAuction') && 
               <div className="d-flex justify-content-between align-items-end">                      
                <h3>{item.minimumBid} {config.symbol}</h3>
                <h4>${(parseFloat(usd)*parseFloat(item.minimumBid)).toFixed(4)}</h4>                
              </div>
              }
              {(item.PutOnSale == true && item.PutOnSaleType=='UnLimitedAuction') && 
              <h6 className="m-0">Open For Bids</h6>
              } 
              <div className="creators_details">
              {
              item
              && item.type == 1155
              && item.tokenowners_current_count
              && item.tokenowners_current_count.count
              && item.tokenowners_current_count.count > 1
              ?
                  ('')
              :
              item
              && item.type == 1155 && !isEmpty(item.tokenOwnerInfo) && item.tokenOwnerInfo.curraddress && item.tokenOwnerInfo.image && item.tokenOwnerInfo && item.tokenOwnerInfo.image && item.tokenOwnerInfo && item.tokenOwnerInfo.image!=null && item.tokenOwnerInfo && item.tokenOwnerInfo.image!=undefined && item.tokenOwnerInfo && item.tokenOwnerInfo.image!=""?
              
                <Link to={item && item.tokenOwnerInfo && item.tokenOwnerInfo.name && item.tokenOwnerInfo.name!=null && item.tokenOwnerInfo.name!=undefined && item.tokenOwnerInfo.name!=""?`/user/${item.tokenOwnerInfo.name}`:`/user/${item.tokenOwnerInfo.curraddress}`}  title={`Owner : ${item.tokenOwnerInfo.name}`}>
                  <img src={`${config.baseurl}/profile/${item.tokenOwnerInfo.image}`} alt="Owner" className="img-fluid" />
                  <span>{item.tokenOwnerInfo.name!=undefined && item.tokenOwnerInfo.name!=null && item.tokenOwnerInfo.name!=""?item.tokenOwnerInfo.name:item.tokenOwnerInfo.curraddress && (item.tokenOwnerInfo.curraddress).substring(0, 6)+"......."+(item.tokenOwnerInfo.curraddress).substring(37,42)}</span>
                </Link>
              :item
              && item.type == 721 && !isEmpty(item.tokenOwnerInfo) && item.tokenOwnerInfo.curraddress && item.tokenOwnerInfo.image && item.tokenOwnerInfo && item.tokenOwnerInfo.image && item.tokenOwnerInfo && item.tokenOwnerInfo.image!=null && item.tokenOwnerInfo && item.tokenOwnerInfo.image!=undefined && item.tokenOwnerInfo && item.tokenOwnerInfo.image!=""?
                <Link to={item && item.tokenOwnerInfo && item.tokenOwnerInfo.name && item.tokenOwnerInfo.name!=null && item.tokenOwnerInfo.name!=undefined && item.tokenOwnerInfo.name!=""?`/user/${item.tokenOwnerInfo.name}`:`/user/${item.tokenOwnerInfo.curraddress}`}  title={`Owner : ${item.tokenOwnerInfo.name}`}>
                  <img src={`${config.baseurl}/profile/${item.tokenOwnerInfo.image}`} alt="Owner" className="img-fluid" />
                  <span>{item.tokenOwnerInfo.name!=undefined && item.tokenOwnerInfo.name!=null && item.tokenOwnerInfo.name!=""?item.tokenOwnerInfo.name:item.tokenOwnerInfo.curraddress && (item.tokenOwnerInfo.curraddress).substring(0, 6)+"......."+(item.tokenOwnerInfo.curraddress).substring(37,42)}</span>
                </Link>
              :

                <Link to={item && item.tokenOwnerInfo && item.tokenOwnerInfo.name && item.tokenOwnerInfo.name!=null && item.tokenOwnerInfo.name!=undefined && item.tokenOwnerInfo.name!=""?`/user/${item.tokenOwnerInfo.name}`:`/user/${item.tokenowners_current.tokenOwner}`} title={`Owner : ${item.tokenowners_current.tokenOwner}`}>
                  <img src={`${config.baseurl}/images/noimage.png`} alt="Owner" className="img-fluid" />
                  <span>{item.tokenOwnerInfo.name!=undefined && item.tokenOwnerInfo.name!=null && item.tokenOwnerInfo.name!=""?item.tokenOwnerInfo.name:item.tokenOwnerInfo.curraddress && (item.tokenOwnerInfo.curraddress).substring(0, 6)+"......."+(item.tokenOwnerInfo.curraddress).substring(37,42)}</span>
                </Link>
              }
              </div> 
            </div>
          </div>
        :('')
    )
}