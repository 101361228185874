import axios from 'axios';
import config from '../lib/config';
import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";

export const getCurAddr = async () => {
  if(localStorage.getItem("wtype") && localStorage.getItem("wtype")!=null && localStorage.getItem("wtype")!=undefined && localStorage.getItem("wtype")!="" && localStorage.getItem("wtype")=="trust"){
   try{
    var provider = new WalletConnectProvider({
        rpc: {
          [config.livechainid]: config.liverpcUrls
        },
        chainId: config.livechainid
      });
        provider.on("connect", () => {
        });
        await provider.enable();
        let web3 = new Web3(provider);
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if (config.livechainid == network) {
        localStorage.setItem("wtype","trust")
        localStorage.setItem("tksmudisgjeush", "yes");
        localStorage.setItem("address", result[0]);
        localStorage.setItem("lout",true)
          var address = result[0]
          return address 
        } else {
         await provider.disconnect();
        }
        provider.on("disconnect", async (code: number, reason: string) => {
            localStorage.clear();
            window.location.reload()
        });
      }catch(err){
        localStorage.clear();
        window.location.reload()
      }
  }else{
   if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
           await window.ethereum.enable()
              const web3 = new Web3(window.web3.currentProvider);
              if (window.web3.currentProvider.isMetaMask === true) {
                if (
                  window.web3 &&
                  window.web3.eth &&
                  window.web3.eth.defaultAccount
                ) {
                  if (
                    window.web3.currentProvider.networkVersion ==
                    config.networkversion
                  ) {
                    var result = await web3.eth.getAccounts();
                    var address = result[0]
                    return address 
                }
            }
        }
    
   }
  }catch(err){
 }
}
}

  // var currAddr = '';
  // if (window.ethereum) {
  //   var web3 = new Web3(window.ethereum);
  //   if(
  //     window.web3
  //     && window.web3.eth
  //     && window.web3.eth.defaultAccount
  //   ) {
  //     var accVal = await web3.eth.getAccounts();
  //     if(accVal[0]) {
  //       currAddr = accVal[0];
  //     }
  //   }
  // }
  // return currAddr;
}

export const User_Following_List_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/follow/following-list/`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const getuserblock = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/getuserblock/`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const getitemdetail = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/token/getitemdetail/`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const User_Follow_Get_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/follow/get-follow/`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const User_Followers_List_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/follow/followers-list/`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const FollowChange_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/follow/change`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/address/details/getorsave`,
      'data': Payload
    })
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    }
  }
}

export const updateprofile = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/profileupdate`,
      'data': Payload
    })
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    }
  }
}

export const getprofilerawdetail = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/getprofilerawdetail`,
      'data': Payload
    })
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    }
  }
}

export const getprofiledetail = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/getprofile`,
      'data': Payload
    })
    return {
      data: Resp
    }
  }
  catch (err) {
    return {
    }
  }
}

export const imageupdate = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseurl}/api/user/imageupdate`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    }
  }
}


export const halfAddrShow = (addr) => {
  if(addr) {
    return addr.substring(0, 5)+'....'+addr.slice(addr.length - 5);
  }
  else {
    return '';
  }
}



