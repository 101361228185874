import React, { useEffect , useState , useRef } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link , useParams} from "react-router-dom";
import { idText } from "typescript";
import ConnectWallet from './separate/Connect-Wallet';
import ArtistBackground from '../assets/images/artist_banner.png';
import { toastAlert }  from "../actions/toastAlert";
import { profileupdate } from "../actions/users";
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { LikeRef } from './separate/LikeRef';
import { PlaceABidRef } from './separate/PlaceABidRef';
import { BurnRef } from './separate/BurnRef'
import { TransferRef } from './separate/TransferRef'
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { TattooNowRef } from './separate/TattooNowRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import TokenItem from './separate/Token-Item'; 
import { getmylog } from '../helper/walletconnect';

import config from '../lib/config';
import CryptoJS from 'crypto-js';
import $ from 'jquery';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import axios from "axios"
import {
  getCurAddr,
  getprofiledetail,
  updateprofile,
  imageupdate,
  FollowChange_Action,
  User_Following_List_Action,
  User_Followers_List_Action,
  User_Follow_Get_Action,
} from '../actions/users';
import {
  CollectiblesList_myitem,
  ownCollectiblesList_myitem,
  GetAllCollections,
  CollectiblesList_MysectionItems,
  changeReceiptStatus_Action,
  emailsubmit,
  getrecord,
  getcmsdetails,
  tattoorequest
} from '../actions/token';
const dashboardRoutes = [];
//const IPFS_IMGurl   = config.IPFS_IMG;
const IPFS_IMGurl = config.baseurl+"/nftImg"

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Profile(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const[ name , setname ] = React.useState('');
  const[ email , setemail ] = React.useState('');
  const[ fb , setfb ] = React.useState('');
  const[ insta , setinsta ] = React.useState('');
  const[ lastname , setlastname ] = React.useState('');
  const[ bio , setbio ] = React.useState('');
  const[ twitter , settwitter ] = React.useState('');
  const[ otherlink , setotherlink ] = React.useState('');
  const[ website , setwebsite ] = React.useState('');
  const [TokenList, setTokenList] = React.useState([]);
  const [Artcount,setArtcount] = React.useState(0);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [item, Set_item] = useState({}); 
  const [Creatorcount,setCreatorcount] = React.useState(0);
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [fullLoader, Set_fullLoader] = useState(false);
  const [profiledetail,setprofiledetail] = useState(false);
  var noimg = config.baseurl+"/images/noimage.png"
  var nocover =  config.baseurl+"/images/nocover.jpeg"
  const [coverurl,setcoverurl] = React.useState(nocover);
  const [profileimage,setprofileimage] = React.useState(noimg)
  const [Categorylist,setCategorylist] = useState([])
  const [CatName, setCatName] = React.useState('All');
  const [sort, setsort] = React.useState('recent');
  const [page, setpage] = React.useState(0);
  const [CatBasedTokenList, setCatBasedTokenList] = React.useState({'loader':false,'All':{page:1,list:[],onmore:true}});
  const [Bids, Set_Bids] = useState([]);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);//my bidding balance
  const [TokenBalance, Set_TokenBalance] = useState(0);//my item balance
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [HitItem, Set_HitItem] = useState({});
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [tokenCounts, Set_tokenCounts] = useState(0);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [totaluserbidAmt,setTotaluserbidAmt] = React.useState(0);
  const [mycollection,setmycollection] = React.useState([]);
  const [collectionlist,setcollectionlist] = React.useState([]);
  const [paramAccountAddress, setparamAccountAddress] = useState("");
  const [followingCount, Set_followingCount] = useState(0);
  const [followersCount, Set_followersCount] = useState(0);
  const [followingStatus, Set_followingStatus] = useState(true);
  const [FollowingUserList, Set_FollowingUserList] = React.useState([]);
  const [FollowerUserList, Set_FollowerUserList] = React.useState([]);
  const [OnSale_Count, Set_OnSale_Count] = useState(0);
  const [OnSale_List, Set_OnSale_List] = useState([]);
  const [vip,setvip] = useState(false)
  const [Collectibles_Count, Set_Collectibles_Count] = useState(0);
  const [Collectibles_List, Set_Collectibles_List] = useState([]);

  const [Created_Count, Set_Created_Count] = useState(0);
  const [Created_List, Set_Created_List] = useState([]);

  const [Owned_Count, Set_Owned_Count] = useState(0);
  const [Owned_List, Set_Owned_List] = useState([]);

  const [Liked_Count, Set_Liked_Count] = useState(0);
  const [Liked_List, Set_Liked_List] = useState([]);

  const [CurTabName, Set_CurTabName] = React.useState('OnSale');
  const [collectionaddress,setcollectionaddress] = React.useState([]);

  const [is_follow, Set_is_follow] = useState("no");
  const [requestlist,setrequestlist] = useState([]);

  const [tokenlistpage,settokenlistpage] = useState(1);
  const [tokenliststatus,settokenliststatus] = useState(false);
  const [onsalelistpage,setonsalelistpage] = useState(1);
  const [onsaleliststatus,setonsaleliststatus] = useState(false);
  const [collectiblelistpage,setcollectiblelistpage] = useState(1);
  const [collectibleliststatus,setcollectibleliststatus] = useState(false);
  const [createdlistpage,setcreatedlistpage] = useState(1);
  const [createdliststatus,setcreatedliststatus] = useState(false);
  const [ownedlistpage,setownedlistpage] = useState(1);
  const [ownedliststatus,setownedliststatus] = useState(false);
  const [likedlistpage,setlikedlistpage] = useState(1);
  const [likedliststatus,setlikedliststatus] = useState(false);
  const [collectionlistpage,setcollectionlistpage] = useState(1);
  const [collectionliststatus,setcollectionliststatus] = useState(false);
  const [counts,setcounts] = useState([]);
  const [logo,setlogo] = React.useState(localStorage.getItem("logo"));
  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var TattooForwardRef = useRef();
  var TransferForwardRef = useRef();

  var { paramUsername, paramAddress } = useParams();

  async function submitemail(){
    var re = /\S+@\S+\.\S+/;
    var test = re.test(email);
    if(test == true){
      var data ={"email":email}
       var result = await emailsubmit(data);
       if(result.data.err){
        toastAlert("error","Newsletter Already Subscribed", "error")
        setemail("")
       }else{
        toastAlert("success","subscribed Successfully", "success")
        setemail("")
       }
    }else{
      toastAlert("error","Enter your valid email Id", "error")
    }
  }

  useEffect(() => {
    if(localStorage.getItem("tksmudisgjeush")){
      Tab_Click("collectibles")
    }else{
       getinfo();
    }
    getInit();
  }, [])

  const inputChangeemail = (e) => {
    setemail(e.target.value)
  }

  async function getInit() {
    getcms();
    
  }

  async function getinfo(){
     try{
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
        if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
         var isadd = await web3.utils.isAddress(paramAddress)
         if(isadd){
           currAddr = paramAddress;
           setparamAccountAddress(currAddr)
           var datas = {
            address: currAddr
           }
         }else{
           var nameparam = paramAddress
           var datas = {
            "name": nameparam
          }
         }
        }else{
           currAddr = await getCurAddr();
           var datas = {
            address: currAddr
          }
        }
        

        window.$('#AfterWalletConnected_two').click();
        var details = await getprofiledetail(datas)
        var record  = await getrecord(datas)
        if(record && record.data && record.data.data){
          setcounts(record.data.data)
        }
        if(details && details.data && details.data.data && details.data.data.data){
          if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){setparamAccountAddress(details.data.data.data.curraddress);await getallcollectible(details.data.data.data.curraddress);await CategoryListCall(details.data.data.data.curraddress);}else{
            await getallcollectible(currAddr)
            await CategoryListCall(currAddr);
          }          var restdet = details.data.data.data
          if(restdet.vip!=undefined && restdet.vip!=null && restdet.vip!="" ){setvip(restdet.vip)}else{setvip(false)}
          if(restdet.name!=undefined && restdet.name!=null){setname(restdet.name)}else{setname("")}
          if(restdet.email!=undefined && restdet.email!=null){setemail(restdet.email)}else{setemail("")}
          if(restdet.bio!=undefined && restdet.bio!=null){setbio(restdet.bio)}else{setbio("")}
          if(restdet.personalsite!=undefined && restdet.personalsite!=null){setwebsite(restdet.personalsite)}else{setwebsite("")}
          if(restdet.twitter!=undefined && restdet.twitter!=null){settwitter(restdet.twitter)}else{settwitter("")}
          if(restdet.instagram!=undefined && restdet.instagram!=null){setinsta(restdet.instagram)}else{setinsta("")}
          if(restdet.facebook!=undefined && restdet.facebook!=null){setfb(restdet.facebook)}else{setfb("")}
          if(restdet.lastname!=undefined && restdet.lastname!=null){setlastname(restdet.lastname)}else{setlastname("")}
          if(restdet.otherlink!=undefined && restdet.otherlink!=null){setotherlink(restdet.otherlink)}else{setotherlink("")}
          if(restdet.coverimage!=undefined && restdet.coverimage!=null && restdet.coverimage!=""){
            var urls =config.baseurl+"/cover/"+restdet.coverimage 
            setcoverurl(urls)
          }else{
            var urls =config.baseurl+"/images/noimage.png"
            setcoverurl(urls)
          }
          if(restdet.image!=undefined && restdet.image!=null && restdet.image!=""){
            var urls =config.baseurl+"/profile/"+restdet.image 
            setprofileimage(urls)
          }else{
            var urls =config.baseurl+"/images/noimage.png"
            setprofileimage(urls)
          }
        }else{
          await getallcollectible(currAddr)
          setprofiledetail("")
          await CategoryListCall(currAddr);
        }
      }catch(err){
    }
  }

  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setlogo(config.baseurl+"/cmsimage/"+result.result.headerlogo)
      localStorage.setItem("logo",config.baseurl+"/cmsimage/"+result.result.headerlogo)
    }
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  async function CategoryListCall(addru) {
    axios
    .get(`${config.baseurl}/api/token/category/list`)
    .then(async response =>{
      if (response && response.data && response.data.list) {
        setCategorylist(response.data.list);
        if(response.data.list[0]){
         setCatName(response.data.list[0]._id)
         await TokenListCall({CatName:response.data.list[0]._id,addr:addru})
        }
      }
    })
    .catch(e => console.log(e))
  }

  const namechange = async (e) => {
    setname(e.target.value)
  }

  const lastnamechange = async (e) => {
    setlastname(e.target.value)
  }

  const emailchange = async (e) => {
    setemail(e.target.value)
  }

  const fbchange = async (e) => {
    setfb(e.target.value)
  }

  const instachange = async (e) => {
    setinsta(e.target.value)
  }

  const biochange = async (e) => {
    setbio(e.target.value)
  }

  const sitechange = async (e) => {
    setwebsite(e.target.value)
  }

  const twitterchange = async (e) => {
    settwitter(e.target.value)
  }

  const otherchange = async (e) => {
    setotherlink(e.target.value)
  }

  const selectFileChange = async (e) => {
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'mp3' , 'jpeg' , 'jpg']; //array of valid extensions
    if(e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        toastAlert("error","Only these file types are accepted : "+validExtensions.join(', '),"error")
        $("input[name='image']").val("");
        return false;
      }
      else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toastAlert("success","File size exceeds 30 MB","success")
          $("input[name='image']").val("");
          return false;
        } else {
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if(reader.result) {
            }
          }.bind(this);
          var currAddr = "";
          if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
             currAddr = paramAccountAddress;
          }else{
             currAddr = await getCurAddr();
          }
          var formData = new FormData();
          if(file) {
            formData.append('CoverImage', file);
          }
          formData.append('addr', currAddr);
          var Resp = await imageupdate(formData);
          if (Resp && Resp.data && Resp.data.data) {
            toastAlert("success","Cover Updated Successfully","success")
            setcoverurl(config.baseurl+"/cover/"+Resp.data.data.coverimage)
            setTimeout(() => { window.location.reload(false); }, 2000)
          }
        }
      }
    }
  }

  async function categorychange(categ){
    setCatName(categ)
    settokenlistpage(1)
    TokenListCall({"CatName":categ,page:1})
  }

  async function collectionchange(addre){
    setcollectionlistpage(1)
    setcollectionaddress(addre)
    CollectionListCall({"conadd":addre,page:1})
  }
  

  const selectProfileChange = async (e) => {
    var validExtensions = ["png", 'gif', 'webp', 'mp4', 'mp3' , 'jpeg' , 'jpg']; //array of valid extensions
    if(e.target && e.target.files) {
      var reader = new FileReader()
      var file = e.target.files[0];
      var fileName = file.name;
      var fileNameExt = fileName.substr(fileName.lastIndexOf('.') + 1);
      if ($.inArray(fileNameExt, validExtensions) == -1) {
        toastAlert("error","Only these file types are accepted : "+validExtensions.join(', '),"error")
        $("input[name='image']").val("");
        return false;
      }
      else {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 30) {
          toastAlert("success","File size exceeds 30 MB","success")
          $("input[name='image']").val("");
          return false;
        } else {
          var url = reader.readAsDataURL(file);
          reader.onloadend = function (e) {
            if(reader.result) {
            }
          }.bind(this);
          var currAddr = "";
          if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
             currAddr = paramAccountAddress;
          }else{
             currAddr = await getCurAddr();
          }
          var formData = new FormData();
          if(file) {
            formData.append('Image', file);
          }
          formData.append('addr', currAddr);
          var Resp = await imageupdate(formData);
          if (Resp && Resp.data && Resp.data.data) {
            toastAlert("success","Profile Updated Successfully","success")
            setTimeout(() => { window.location.reload(false); }, 2000)
            var urls =config.baseurl+"/profile/"+Resp.data.data.image 
            setprofileimage(urls)
            setTimeout(() => { window.location.reload(false); }, 2000)
          }
        }
      }
    }
  }

  var currAddr = "";

  async function AfterWalletConnected() {
    try{
      var mydata = await getmylog();
      const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
      if(localStorage.getItem("tksmudisgjeush")){
        if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
          var isadd = await web3.utils.isAddress(paramAddress)
         if(isadd){
           currAddr = paramAddress;
           setparamAccountAddress(currAddr)
           var datas = {
            address: currAddr
           }
         }else{
           var nameparam = paramAddress
           var datas = {
            "name": nameparam
          }
         }
        }else{
           currAddr = await getCurAddr();
           var datas = {
            address: currAddr
          }
        }        

        window.$('#AfterWalletConnected_two').click();
        var details = await getprofiledetail(datas)
        var record  = await getrecord(datas)
        if(record && record.data && record.data.data){
          setcounts(record.data.data)
        }
        if(details && details.data && details.data.data && details.data.data.data){
          if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){setparamAccountAddress(details.data.data.data.curraddress);await getallcollectible(details.data.data.data.curraddress);await CategoryListCall(details.data.data.data.curraddress);}else{
            await getallcollectible(currAddr)
            await CategoryListCall(currAddr);
          }
          var restdet = details.data.data.data
          if(restdet.vip!=undefined && restdet.vip!=null && restdet.vip!="" ){setvip(restdet.vip)}else{setvip(false)}
          if(restdet.name!=undefined && restdet.name!=null){setname(restdet.name)}else{setname("")}
          if(restdet.email!=undefined && restdet.email!=null){setemail(restdet.email)}else{setemail("")}
          if(restdet.bio!=undefined && restdet.bio!=null){setbio(restdet.bio)}else{setbio("")}
          if(restdet.personalsite!=undefined && restdet.personalsite!=null){setwebsite(restdet.personalsite)}else{setwebsite("")}
          if(restdet.twitter!=undefined && restdet.twitter!=null){settwitter(restdet.twitter)}else{settwitter("")}
          if(restdet.instagram!=undefined && restdet.instagram!=null){setinsta(restdet.instagram)}else{setinsta("")}
          if(restdet.facebook!=undefined && restdet.facebook!=null){setfb(restdet.facebook)}else{setfb("")}
          if(restdet.lastname!=undefined && restdet.lastname!=null){setlastname(restdet.lastname)}else{setlastname("")}
          if(restdet.otherlink!=undefined && restdet.otherlink!=null){setotherlink(restdet.otherlink)}else{setotherlink("")}
          if(restdet.coverimage!=undefined && restdet.coverimage!=null && restdet.coverimage!=""){
            var urls =config.baseurl+"/cover/"+restdet.coverimage 
            setcoverurl(urls)
          }else{
            var urls =config.baseurl+"/images/noimage.png"
            setcoverurl(urls)
          }
          if(restdet.image!=undefined && restdet.image!=null && restdet.image!=""){
            var urls =config.baseurl+"/profile/"+restdet.image 
            setprofileimage(urls)
          }else{
            var urls =config.baseurl+"/images/noimage.png"
            setprofileimage(urls)
          }
        }else{
          await getallcollectible(currAddr)
          await CategoryListCall(currAddr);
          setprofiledetail("")
        }
        
    }
    }catch(err){
      console.log(err,"=============tokenBalancetokenBalancetokenBalanceerrr")
    }
  }

  async function Check_follow(){
    currAddr = await getCurAddr();
    var data = {
      useraddress:currAddr,
      followaddress:paramAccountAddress,
    }
    var resp = await User_Follow_Get_Action(data);
    var foll = (resp && resp.data && resp.data.isFollow)?resp.data.isFollow:"no";
    var followingcnt = (resp && resp.data && resp.data.following)?resp.data.following:"0";
    var followerscnt = (resp && resp.data && resp.data.followers)?resp.data.followers:"0";
    Set_is_follow(foll);
    Set_followingCount(followingcnt);
    Set_followersCount(followerscnt);
  }

  async function AfterWalletConnected_two() {
    Check_follow();
    //Get_Activity_list();
    try{
      LikeForwardRef && LikeForwardRef.current && LikeForwardRef.current.getLikesData();
    }catch(err){

    }
  
    await Tab_Data_Call('Count','onsale',1,true);
    await Tab_Data_Call('List','onsale',1);
    await Tab_Data_Call('Count','created',1);
    await Tab_Data_Call('Count','owned',1);
    await Tab_Data_Call('Count','liked',1);
    await Tab_Data_Call('Count','collectibles',1);
  }

  async function Tab_Data_Call(Target, TabName, page ,init=false) {
    var mydata = await getmylog();
    const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
    var paraacc = ""
    var currAddr = "";
    if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
     var isadd = await web3.utils.isAddress(paramAddress)
     if(isadd){
       currAddr = paramAddress;
       paraacc = currAddr
       setparamAccountAddress(currAddr)
       var datas = {
        address: currAddr
       }
     }else{
       var nameparam = paramAddress
       var datas = {
        "name": nameparam
      }
     }
    }else{
       currAddr = await getCurAddr();
       paraacc=currAddr
       var datas = {
        address: currAddr
      }
    }
     var details = await getprofiledetail(datas)
     if(details && details.data && details.data.data && details.data.data.data){
     if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
      setparamAccountAddress(details.data.data.data.curraddress)
       paraacc = details.data.data.data.curraddress
       currAddr= details.data.data.data.curraddress
     }
     }


    if(currAddr ) {
      var ReqData = {
        Addr:currAddr,
        MyItemAccountAddr:paraacc,
        ParamAccountAddr:paraacc,
        UserAccountAddr:paraacc,
        Target:Target,
        TabName:TabName,
        init:init,
        from:'My-Items'
      };

      var Resp = {};
      Resp = await CollectiblesList_MysectionItems(ReqData);
      var RespNew = await CorrectDataGet(Resp);

      if(
        (Target=='Count' && typeof RespNew.count != 'undefined')
        ||
        (Target=='List' && RespNew.list)
      ) {
        if(TabName=='collectibles') {
          if(Target=='Count') { Set_Collectibles_Count(RespNew.count); }
          if(Target == 'List') { Set_Collectibles_List(RespNew.list); }
        }
        else if(TabName=='onsale') {
          if(Target=='Count') { Set_OnSale_Count(RespNew.count); }
          if(Target == 'List') { Set_OnSale_List(RespNew.list); }
        }
        else if(TabName=='created') {
          if(Target=='Count') { Set_Created_Count(RespNew.count); }
          if(Target == 'List') { Set_Created_List(RespNew.list); }
        }
        else if(TabName=='owned') {
          if(Target=='Count') { Set_Owned_Count(RespNew.count); }
          if(Target == 'List') { Set_Owned_List(RespNew.list); }
        }
        else if(TabName=='liked') {
          if(Target=='Count') { Set_Liked_Count(RespNew.count); }
          if(Target == 'List') { Set_Liked_List(RespNew.list); }
        }
      }
    }
    return true;
  }

  async function CorrectDataGet(Resp,Target) {
    var RetData = { count : 0, list : [] }
    if (
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.list
      && Resp.data.list[0]
    ) {
      if(Resp.data.Target == 'Count' && Resp.data.list[0].count) {
        RetData.count = Resp.data.list[0].count;
      }
      else if(Resp.data.Target == 'List' && Resp.data.list[0]) {
        RetData.list = Resp.data.list;
      }
    }
    if(
      Resp
      && Resp.data
      && Resp.data.Target
      && Resp.data.changeStatusList) {
        changeReceiptStatus_Call(Resp.data.changeStatusList);
    }
    return RetData;
  }

  async function changeReceiptStatus_Call(list) {
    var mydata = await getmylog();
    const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
        
    list.map(async (item) => {
      if (item && typeof item.checkAdd != "undefined" && item.checkAdd.hashValue) {
        try {
          var data = await web3.eth.getTransactionReceipt(item.checkAdd.hashValue);
          var hashValue = item.checkAdd.hashValue;
          if (data == null) {
          } else {
            if(data.status == '0x0') {
            } else {
              var payload = {
                status: 'true',
                hashValue: hashValue
              }
              await changeReceiptStatus_Action(payload);
            }
          }
        }
        catch(err) {
          // console.log('err', err);
        }
      }
    })
  }

  async function loadmoreCollectionListCall(data={}) {
    setcollectionaddress(data.conadd)
    var currAddr = "";
    if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
       currAddr = paramAccountAddress;
    }else{
       currAddr = await getCurAddr();
    }
    var name = "All";
    var filter = "recent"
    
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: 3 ,
      page: data.page? data.page : parseInt(collectionlistpage)>0?collectionlistpage:1,
      currAddr: currAddr,
      CatName: name,
      "filter" : filter,
      contractaddress:data.conadd?data.conadd:collectionaddress,
      from:'Home'
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await ownCollectiblesList_myitem(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setcollectionlist(collectionlist.concat(resp.data.list));
      setcollectionliststatus(true)
    }
    else {
      setcollectionliststatus(false)
    }
  }


  async function CollectionListCall(data={}) {
    setcollectionaddress(data.conadd)
    var currAddr = "";
    if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
       currAddr = paramAccountAddress;
    }else{
       currAddr = await getCurAddr();
    }
    var name = "All";
    var filter = "recent"
    
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: 3 ,
      page: data.page? data.page : parseInt(collectionlistpage)>0?collectionlistpage:1,
      currAddr: currAddr,
      CatName: name,
      "filter" : filter,
      contractaddress:data.conadd?data.conadd:collectionaddress,
      from:'Home'
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await ownCollectiblesList_myitem(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      setcollectionlist(resp.data.list);
      setcollectionliststatus(true)
    }
    else {
      setcollectionlist("")
      setcollectionliststatus(false)
    }
  }

  async function Tab_Click(TabName) {
    Set_CurTabName(TabName);
    if(TabName=="tattoo"){
      gettattoorequest()
    }else{
      await Tab_Data_Call('List',TabName,1);
      await Tab_Data_Call('Count',TabName,1);
    }
    setonsalelistpage(1)
    setcollectiblelistpage(1)
    setcreatedlistpage(1)
    setownedlistpage(1)
    setlikedlistpage(1)

  }

  async function gettattoorequest(){
    currAddr = await getCurAddr();
    var data = {
      address : currAddr
    }
    var list = await tattoorequest(data)
    if(list && list.status && list.status.data){
      setrequestlist(list.status.data)
    }
  }

  async function TokenListCall(data={}) {
    var mydata = await getmylog();
    const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
    var paraacc = ""
    var currAddr = "";
    if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
     var isadd = await web3.utils.isAddress(paramAddress)
     if(isadd){
       currAddr = paramAddress;
       paraacc = currAddr
       setparamAccountAddress(currAddr)
       var datas = {
        address: currAddr
       }
     }else{
       var nameparam = paramAddress
       var datas = {
        "name": nameparam
      }
     }
    }else{
       currAddr = await getCurAddr();
       paraacc=currAddr
       var datas = {
        address: currAddr
      }
    }
     var details = await getprofiledetail(datas)
     if(details && details.data && details.data.data && details.data.data.data){
     if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
      setparamAccountAddress(details.data.data.data.curraddress)
       paraacc = details.data.data.data.curraddress
       currAddr= details.data.data.data.curraddress
     }
     }
    
    var name = "All";
    var filter = "recent"
    if (data.CatName) {
      name = data.CatName
    }else{
      name = CatName
    }
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
     var Target = "list";
    var TabName = "collectibles";
    var payload = {
       Addr:currAddr,
        MyItemAccountAddr:paraacc,
        ParamAccountAddr:paraacc,
        UserAccountAddr:paraacc,
        Target:Target,
        TabName:TabName,
        init:false,
        from:'My-Items',
        CatName: name,
        limit: 3,
        page: data.page ? data.page : 1, 
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await CollectiblesList_myitem(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (resp && resp.data  && resp.data.list && resp.data.list.length > 0) {
      setTokenList(resp.data.list);
      settokenliststatus(true)
    }
    else {
      setTokenList("")
      settokenliststatus(false)
    }
  }

  const onLoadMoretokenlist = async () => {
    var mydata = await getmylog();
    const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
    var paraacc = ""
    var currAddr = "";
    if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
     var isadd = await web3.utils.isAddress(paramAddress)
     if(isadd){
       currAddr = paramAddress;
       paraacc = currAddr
       setparamAccountAddress(currAddr)
       var datas = {
        address: currAddr
       }
     }else{
       var nameparam = paramAddress
       var datas = {
        "name": nameparam
      }
     }
    }else{
       currAddr = await getCurAddr();
       paraacc=currAddr
       var datas = {
        address: currAddr
      }
    }
     var details = await getprofiledetail(datas)
     if(details && details.data && details.data.data && details.data.data.data){
     if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
      setparamAccountAddress(details.data.data.data.curraddress)
       paraacc = details.data.data.data.curraddress
       currAddr= details.data.data.data.curraddress
     }
     }
    settokenlistpage(parseInt(tokenlistpage)+1)
    var data = ({
      page :  parseInt(tokenlistpage)+1
    });
    var Target = "list";
    var TabName = "collectibles";
    var name = "All";
    var filter = "recent"
    if (data.CatName) {
      name = data.CatName
    }else{
      name = CatName
    }
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
        Addr:currAddr,
        MyItemAccountAddr:paraacc,
        ParamAccountAddr:paraacc,
        UserAccountAddr:paraacc,
        Target:Target,
        TabName:TabName,
        init:false,
        from:'My-Items',
        CatName: name,
        limit: 3,
        page: data.page ? data.page : parseInt(tokenlistpage)>0?tokenlistpage:1,     
    }
    CatBasedTokenList.loader = true;
    setCatBasedTokenList(CatBasedTokenList);

    var resp = await CollectiblesList_myitem(payload);
    CatBasedTokenList.loader = false;
    setCatBasedTokenList(CatBasedTokenList);
    if (resp && resp.data && resp.data.list && resp.data.list.length > 0) {
      setTokenList(TokenList.concat(resp.data.list));
      settokenliststatus(true)
    }
    else {
      settokenliststatus(false)
    }
  }

  const onLoadMoresalelist = async () => {
    setonsalelistpage(parseInt(onsalelistpage)+1)
    await Tab_Data_Call('Count','onsale',true,parseInt(onsalelistpage)+1);
    await Tab_Data_Call('List','onsale', parseInt(onsalelistpage)+1);
  }

  const onLoadMorecollectiblelist = async () => {
    setcollectiblelistpage(parseInt(collectiblelistpage)+1)
    await Tab_Data_Call('Count','collectibles',true,parseInt(collectiblelistpage)+1);
    await Tab_Data_Call('List','collectibles',parseInt(collectiblelistpage)+1);
  }

  const onLoadMorecreatedlist = async () => {
    setcreatedlistpage(parseInt(createdlistpage)+1)
    await Tab_Data_Call('Count','created',true, parseInt(createdlistpage)+1);
    await Tab_Data_Call('List','created', parseInt(createdlistpage)+1);
  }

  const onLoadMoreownedlist = async () => {
    setownedlistpage(parseInt(ownedlistpage)+1)
    await Tab_Data_Call('Count','owned',true, parseInt(ownedlistpage)+1);
    await Tab_Data_Call('List','owned',parseInt(ownedlistpage)+1);
  }

  const onLoadMorelikelist = async () => {
    setlikedlistpage(parseInt(likedlistpage)+1)
    await Tab_Data_Call('Count','liked',true, parseInt(likedlistpage)+1);
    await Tab_Data_Call('List','liked',parseInt(likedlistpage)+1);
  }

  const onLoadMorecollectionlist = async () => {
    setcollectionlistpage(parseInt(collectionlistpage)+1)
    loadmoreCollectionListCall({
      page :  parseInt(collectionlistpage)+1
    });
  }

  async function getallcollectible(addd){
    // var currAddr = "";
    // if(paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""){
    //    currAddr = paramAccountAddress;
    // }else{
    //    currAddr = await getCurAddr();
    // }
    var response = await GetAllCollections({
      useraddress: addd,
    });
    if(response && response.data && response.data.RespData){
      setmycollection(response.data.RespData)
      if(response.data.RespData[0]){
        setcollectionaddress(response.data.RespData[0].conAddr)
        CollectionListCall({"conadd":response.data.RespData[0].conAddr})
      }else{
        setmycollection("")
      }
    }else{
      setmycollection("")
    }
  }

  async function profileupdate(){    
     var currAddr = await getCurAddr();
    if(currAddr) {
      if(name && name!=null && name!=undefined && name!=""){
      var mydata = await getmylog();
      const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
        
      // web3.eth.personal
      // .sign(
      //   `Your Created profile as ${name} and Your url is ${config.fronturl}/${currAddr}`,
      //   currAddr,
      //   `Your Created profile as ${name} and Your url is ${config.fronturl}/${currAddr}`
      // )
      // .then(async()=>{

          var data = {
            "name":name,
            "email":email,
            "bio":bio,
            "lastname":lastname,
            "fb":fb,
            "insta":insta,
            "twitter":twitter,
            "otherlink":otherlink,
            "website":website,
            "address": currAddr,
          }
          var Resp = await updateprofile(data);
          if(Resp && Resp.data && Resp.data.data && Resp.data.data.status==false){
            toastAlert("error","Name Already exist","error")
          }else{
            toastAlert("success","Updated Successfully","success")
            window.location.reload()
          }
//       })
        }else{
          toastAlert("error","Please Enter Name","error")
        }
      }else{
          toastAlert("error","Please Login to Update Profile","error")
      }
  }

  async function FollowChange_Call() {

    var mydata = await getmylog();
    const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
    var currAddr = await getCurAddr();
    var Payload = {};
    Payload.currAddr = currAddr;
    Payload.ParamAccountAddr = paramAccountAddress;
    var msg = "I would like to follow user: "+paramAccountAddress
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(msg), 'my-secret-key@123').toString();

    if(is_follow=="yes"){
      msg = "I would like to stop following user: "+paramAccountAddress
      ciphertext = CryptoJS.AES.encrypt(JSON.stringify(msg), 'my-secret-key@123').toString();
    }
    var datass = "Welcome to TattooMoney NFT!"+"\n\n"+"Click to sign in and accept the TattooMoney Terms of Service: "+ config.baseurl+"/Terms/ToS.pdf"+"\n\n"+"This request will not trigger a blockchain transaction or cost any gas fees"+"\n\n"+"Your authentication status will reset after 24 hours."+"\n\n"+"Wallet address: "+currAddr+"\n\n"+"Nonce: "+ciphertext+"\n"
    await  web3.eth.personal.sign(datass, currAddr)
    var Resp = await FollowChange_Action(Payload);

    var foll = (Resp && Resp.data && Resp.data.ChangeType)?Resp.data.ChangeType:"no";
    Set_is_follow(foll);

    if(foll=="yes"){
      toastAlert("success","Follow successfully", "success");
    } else {
      toastAlert("success","Un-Follow successfully", "success");
    }
    Check_follow();
    if(CurTabName == 'followers') {
      Get_Followers_List();
    }
    else if(CurTabName == 'following') {
      Get_Following_List();
    }
  }

  async function Get_Followers_List() {
    Set_CurTabName('followers');
    var currAddr = await getCurAddr();
    var resp = await User_Followers_List_Action({
      addr:paramAccountAddress,
      loginUser:currAddr
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var MyFollowingList = resp.data.list;
      Set_FollowerUserList(MyFollowingList);
    }
    else {
      Set_FollowerUserList([]);
    }
  }

  async function Get_Following_List() {
    Set_CurTabName('following');
    var currAddr = await getCurAddr();
    var resp = await User_Following_List_Action({
      addr:paramAccountAddress,
      loginUser:currAddr
    });
    
    if(
      resp
      && resp.data
      && resp.data.list
    ) {
      var MyFollowingList = resp.data.list;
      Set_FollowingUserList(MyFollowingList);
    }
    else {
      Set_FollowingUserList([]);
    }
  }

  async function checkwall(){
      if(localStorage.getItem("tksmudisgjeush")){

      }else{
        toastAlert("error","Connect Wallet to Move Further", "error")
      }
    }

  async function showAllwithPro(i_item){
    Set_item(i_item)
  }

  const getClickableLink = link => {
    return link.startsWith("http://") || link.startsWith("https://") ?
      link
      : `http://${link}`;
  };

  return (
    <div className="inner_header">
      <div id="AfterWalletConnected_two" onClick={() => AfterWalletConnected_two()}>&nbsp;</div>
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_TokenBalance = {Set_TokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        TokenBalance = {TokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        MyItemAccountAddr={UserAccountAddr}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
      />
      <TransferRef
        ref={TransferForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <TattooNowRef
        UserAccountAddr={UserAccountAddr}
        item={item}
        Set_item={Set_item}
        ref={TattooForwardRef}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
       <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={logo} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="container mb-3">
                <div className="title_flex">
                  <h2 className="main_title">{localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAddress==localStorage.getItem("address")?
                        "My"
                       :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                        "My"
                        :
                        ""
                       } profile</h2>                  
                </div>
              </div>
              <div className="items_bg" style={{backgroundImage: `url(${coverurl})`,}}>
                <div className="container">
                  <div className="bg_card_details_panel">
                    <div className="bg_card_details masonry">
                      <div className="item">
                      <div className="profile_card"> 
                      {profileimage !=undefined && profileimage!=null && profileimage!="" ? (
                           <img src={profileimage} alt="profile" className="img-fluid"/>
                       ):(
                            <img src={profileimage} alt="profile" className="img-fluid"/>
                      )}
                       {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAccountAddress==localStorage.getItem("address")?
                        <Button className="follow_btn ml-3" data-toggle="modal" data-target="#edit_profile_modal">
                          <i className="bi bi-pencil-square mr-2"></i> Edit 
                        </Button>
                       :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                        <Button className="follow_btn ml-3" data-toggle="modal" data-target="#edit_profile_modal">
                          <i className="bi bi-pencil-square mr-2"></i> Edit 
                        </Button>
                        :
                        ""
                       }
                      </div>
                        <div className="item_content">
                          <h2 className="font-weight-bold">{name!=""?name:paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""?(paramAccountAddress).substring(0, 6)+"......."+(paramAccountAddress).substring(37,42) : (localStorage.getItem("address")).substring(0, 6)+"......."+(localStorage.getItem("address")).substring(37,42)}</h2>
                          <div className="d-flex justify-content-between align-items-end mb-2"> 
                            <div>
                              <h6 className="m-0">Value of works</h6>
                              <h3>{counts.length>0 && (parseFloat(counts[0].buyamt)+parseFloat(counts[0].sellamt)).toFixed(4)>0?(parseFloat(counts[0].buyamt)+parseFloat(counts[0].sellamt)).toFixed(4):0} {config.symbol}</h3>
                            </div>
                            <div>
                              <h6 className="m-0">Highest sell price</h6>
                              <h4>{counts.length>0 && parseFloat(counts[0].max).toFixed(4) >0 ? parseFloat(counts[0].max).toFixed(4): 0} {config.symbol}</h4>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-end mb-2"> 
                            <div>
                              <h6 className="m-0">Average sell price</h6>
                              <h3>{counts.length>0 &&  parseFloat(counts[0].sellamt)>0 && parseFloat(counts[0].sellcnt)>0 ? (parseFloat(counts[0].sellamt)/parseFloat(counts[0].sellcnt)).toFixed(4):0} {config.symbol}</h3>
                            </div>
                            <div>
                              <h6 className="m-0">Works</h6>
                              <h4>{counts.length>0 && counts[0].creatorcount && parseFloat(counts[0].creatorcount)>0?counts[0].creatorcount:0}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="bg_card_details_right flex-column align-items-end d-grid dflex_ssssss pb-4">
                       <div className="user_collection_value">
                          <div className="d-flex justify-content-between align-items-end mb-3"> 
                            <div>
                              <h6 className="m-0">Art value</h6>
                             <h3>{counts.length>0 && (parseFloat(counts[0].collectionbuyamount)+parseFloat(counts[0].collectionsellamount)).toFixed(4)>0? (parseFloat(counts[0].collectionbuyamount)+parseFloat(counts[0].collectionsellamount)).toFixed(4): 0} {config.symbol}</h3>
                            </div>
                            <div>
                              <h6 className="m-0">Highest sell price</h6>
                              <h4>{counts.length>0 && parseFloat(counts[0].maxcollection).toFixed(4) >0 ? parseFloat(counts[0].maxcollection).toFixed(4): 0} {config.symbol}</h4>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-end mb-2"> 
                            <div>
                              <h6 className="m-0">Average sell price</h6>
                              <h3>{counts.length>0 &&  parseFloat(counts[0].collectionsellamount)>0 && parseFloat(counts[0].collsellcnt)>0 ? (parseFloat(counts[0].collectionsellamount)/parseFloat(counts[0].collsellcnt)).toFixed(4):0} {config.symbol}</h3>
                            </div>
                            <div>
                              <h6 className="m-0">Collections</h6>
                              <h4>{counts.length>0 && counts[0].collectioncount && parseFloat(counts[0].collectioncount)>0?counts[0].collectioncount:0} </h4>
                            </div>
                          </div>
                       </div>
                    <div>                      
                        <div className="brofilw_ddd">
                        {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAddress==localStorage.getItem("address")?
                          <Button className="follow_btn ml-3" data-toggle="modal" data-target="#edit_profile">
                            <i className="bi bi-pencil-square mr-2"></i> Edit profile
                          </Button>
                        :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                          <Button className="follow_btn ml-3" data-toggle="modal" data-target="#edit_profile">
                            <i className="bi bi-pencil-square mr-2"></i> Edit profile
                          </Button>
                          :
                          ""
                        }
                        {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAccountAddress==localStorage.getItem("address")?
                          <Button className="follow_btn ml-3" data-toggle="modal" data-target="#edit_cover_modal">
                            <i className="bi bi-arrow-up-square mr-2"></i> Update cover
                          </Button>
                        :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                          <Button className="follow_btn ml-3" data-toggle="modal" data-target="#edit_cover_modal">
                            <i className="bi bi-arrow-up-square mr-2"></i> Update cover
                          </Button>
                          :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") &&
                          <Button className="follow_btn ml-3" onClick={FollowChange_Call}>
                            <i className="bi bi-person-plus mr-2"></i> {is_follow=="yes"?'Un-Follow':'Follow'}
                          </Button>
                        }     
                        </div>
                        
                        <div className="d-flex mt-3 justify-content-end pb-3">
                      {vip && 
                          <div className="followers_txt text-center mr-3">
                            <h3>VIP</h3>
                            <p>Status</p>
                          </div>
                      }
                          <div className="followers_txt text-center mr-3">
                            <h3>{followingCount}</h3>
                            <p>following</p>
                          </div>
                          <div className="followers_txt text-center">
                            <h3>{followersCount}</h3>
                            <p>followers</p>
                          </div>
                        </div>
                        <ul className="profile_list">
                          <li><a href={getClickableLink(website)} target="_blank"><i class="fas fa-globe"></i></a></li>
                          <li><a href={getClickableLink(insta)} target="_blank"><i class="fab fa-instagram"></i></a></li>
                          <li><a href={getClickableLink(fb)} target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                          <li><a href={getClickableLink(twitter)} target="_blank"><i class="fab fa-twitter"></i></a></li>                                                    
                        </ul>
                    </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>        

        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div className="title_flex mt-5">
                <h2 className="main_title"> {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAddress==localStorage.getItem("address")?
                        "My"
                       :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                        "My"
                        :
                        ""
                       } art</h2>
                <div>
                {localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") &&
                  <Button className="primary_btn_white mr-2"><Link to="/create"><i className="bi bi-plus-circle mr-2"></i>Create NFT</Link></Button>
                }
                 {/* <Button className="primary_btn_white"><i className="bi bi-gear mr-2"></i>Manage art</Button> */}
                </div>
              </div>
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                 {Categorylist && Categorylist.length>0 && Categorylist.map((item,i)=>{
                  return(
                  <a className={i==0?"nav-link active":"nav-link"} id="all-tab" onClick={()=>categorychange(item._id)} data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                    <div className="tab_head">{item.category}</div>                    
                  </a>
                  )})}
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                  <div className="masonry">
                    
                  {(TokenList && TokenList.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          WalletConnected={WalletConnected}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}
                  </div>
                  {tokenliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMoretokenlist}>Load More</Button>
                  </div>
                  }
                </div>
                
              </div>

            {/***************************************************/}
              <div className="title_flex mt-5">
                <h2 className="main_title">{localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAddress==localStorage.getItem("address")?
                        "My"
                       :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                        "My"
                        :
                        ""
                       } Sections</h2>
                <div>
                 </div>
              </div>
             
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="sale-tab" data-toggle="tab" href="#sale" role="tab" aria-controls="sale" aria-selected="true" onClick={() => Tab_Click('onsale')}>
                    <div className="tab_head" >On Sale</div>                    
                  </a>
                  <a className="nav-link" id="owned-tab" data-toggle="tab" href="#owned" role="tab" aria-controls="owned" aria-selected="true" onClick={() => Tab_Click('collectibles')}>
                    <div className="tab_head" >Collectibles</div>                    
                  </a>
                  <a className="nav-link" id="created-tab" data-toggle="tab" href="#created" role="tab" aria-controls="created" aria-selected="true" onClick={() => Tab_Click('created')}>
                    <div className="tab_head" >Created</div>                    
                  </a>
                  <a className="nav-link" id="owned-tab" data-toggle="tab" href="#owned" role="tab" aria-controls="owned" aria-selected="true" onClick={() => Tab_Click('owned')}>
                    <div className="tab_head" >Owned</div>                    
                  </a>
                 <a className="nav-link" id="collection_one-tab" data-toggle="tab" href="#favour" role="tab" aria-controls="favour" aria-selected="true" onClick={() => Tab_Click('liked')}>
                    <div className="tab_head" >Favourite</div>                    
                  </a>
                  {paramAddress && paramAddress!=null && paramAddress!=undefined && paramAddress!=""?
                    ""
                  :
                    <a className="nav-link" id="collection_one-tab" data-toggle="tab" href="#tattoorequest" role="tab" aria-controls="tattoo" aria-selected="true" onClick={() => Tab_Click('tattoo')}>
                      <div className="tab_head" >Tattoo Request</div>                    
                    </a>
                  }
                  
                {/*  <a className="nav-link" id="collection_one-tab" data-toggle="tab" href="#collection_one" role="tab" aria-controls="collection_one" aria-selected="true">
                    <div className="tab_head" onClick={()=>collectionchange(item.conAddr)}>Following</div>                    
                  </a>
                  <a className="nav-link" id="collection_one-tab" data-toggle="tab" href="#collection_one" role="tab" aria-controls="collection_one" aria-selected="true">
                    <div className="tab_head" onClick={()=>collectionchange(item.conAddr)}>Followers</div>                    
                  </a> */}
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="sale" role="tabpanel" aria-labelledby="sale-tab">
                  <div className="masonry">
                     {(OnSale_List && OnSale_List.length>0 && OnSale_List.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}
                  </div>
                  {onsaleliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMoresalelist}>Load More</Button>
                  </div>
                  } 
                </div>
                <div className="tab-pane fade show" id="owned" role="tabpanel" aria-labelledby="owned-tab">
                  <div className="masonry">
                     {(Collectibles_List && Collectibles_List.length>0 && Collectibles_List.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}

                                                                   
                  </div>
                  {collectibleliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMorecollectiblelist}>Load More</Button>
                  </div>
                  }
                </div>
                <div className="tab-pane fade show" id="created" role="tabpanel" aria-labelledby="created-tab">
                  <div className="masonry">
                     {(Created_List && Created_List.length>0 && Created_List.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}

                                                                   
                  </div>
                  {createdliststatus && 
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMorecreatedlist}>Load More</Button>
                  </div>
                  }
                </div>
                <div className="tab-pane fade show" id="owned" role="tabpanel" aria-labelledby="owned-tab">
                  <div className="masonry">
                     {(Owned_List && Owned_List.length>0 && Owned_List.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}

                                                                   
                  </div>
                  {ownedliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMorecreatedlist}>Load More</Button>
                  </div>
                  }
                </div>
                <div className="tab-pane fade show" id="favour" role="tabpanel" aria-labelledby="favour-tab">
                  <div className="masonry">
                     {(Liked_List && Liked_List.length>0 && Liked_List.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}

                                                                   
                  </div>
                  {likedliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMorelikelist}>Load More</Button>
                  </div>
                  }
                </div>
                <div className="tab-pane fade show" id="tattoorequest" role="tabpanel" aria-labelledby="tattoo-tab">
                  <div className="masonry">
                    {requestlist && requestlist.length>0 && requestlist.map((item,i)=>{
                      return(
                        <div className="item" key = {item._id}>
                          <Link to={"/art/"+item.tokenName+"/"+item.tokenCounts} >
                          {
                          item.tokendetail[0].image.split('.').pop() == "mp4" ?
                          <video src={`${IPFS_IMGurl}/${item.tokendetail[0].tokenCreator}/${item.tokendetail[0].image}`} type="video/mp4" alt="Collections" className="img-fluid" controls />
                          :
                          item.tokendetail[0].image.split('.').pop() == "mp3" ?
                          <img src={require("../assets/images/music.png")} alt="" className="img-fluid" style={{backgroundColor:"white"}}/>
                          :
                          <img src={`${IPFS_IMGurl}/${item.tokendetail[0].tokenCreator}/${item.tokendetail[0].image}`} alt="Collections" className="img-fluid " />
                          }
                          </Link>
                          {(item.tokendetail[0].image.split('.').pop() == 'mp3') ?
                            <audio src={`${IPFS_IMGurl}/${item.tokendetail[0].tokenCreator}/${item.tokendetail[0].image}`}  type="audio/mp3"controls className="audio audio_widyth">
                            </audio>:""
                           }
                           <div className="d-flex align-items-center justify-content-between">
                            <h2>{item.tokendetail[0].tokenName}</h2>
                             <div className="dropdown d-inline-block" >
                              <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" >
                                  <a className="dropdown-item" href="#" onClick={() => TattooForwardRef.current.Tattooaccept_Click(item)}>Accept</a>
                                </div>
                              </div>
                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                            <div className="flex_containe_recived">
                            <h6 className="m-0">Requested By</h6>
                            <h2>{item.useraddress}</h2>
                            </div>

                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                            <h2>{item.description}</h2>
                           </div>
                        </div>
                        )
                    })}

                                                                   
                  </div>
                  {likedliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMorelikelist}>Load More</Button>
                  </div>
                  }
                </div>
              </div>
            {/***************************************************/}

            <div className="title_flex mt-5">
                <h2 className="main_title">{localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && paramAddress!=null && paramAddress!=undefined && paramAddress!="" && paramAddress==localStorage.getItem("address")?
                        "My"
                       :localStorage.getItem("tksmudisgjeush") && localStorage.getItem("address") && (paramAddress==null || paramAddress==undefined || paramAddress=="")?
                        "My"
                        :
                        ""
                       } collections</h2>
                <div>
                  <Button className="primary_btn_white mr-2"><Link to="/create"><i className="bi bi-plus-circle mr-2"></i>Create collection</Link></Button>
                {/*  <Button className="primary_btn_white"><i className="bi bi-gear mr-2"></i>Manage collections</Button>  */}
                </div>
              </div> 
             
             <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                 {mycollection && mycollection.length>0 && mycollection.map((item,i)=>{
                  return(
                  <a className={i==0?"nav-link active":"nav-link"} id="collection_one-tab" data-toggle="tab" href="#collection_one" role="tab" aria-controls="collection_one" aria-selected="true">
                    <div className="tab_head" onClick={()=>collectionchange(item.conAddr)}>{item.symbol}</div>                    
                  </a>
                  )})}
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="collection_one" role="tabpanel" aria-labelledby="collection_one-tab">
                  <div className="masonry">
                     {(collectionlist && collectionlist.map((item) => {
                      return (
                       <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {0}
                        />
                        )}))}

                                                                   
                  </div>
                  {collectionliststatus &&
                  <div className="text-center mt-5">
                    <Button className="primary_btn" onClick={onLoadMorecollectionlist}>Load More</Button>
                  </div>
                  }
                </div>
              </div>


            </GridItem>
          </GridContainer>
        </div>      
      </div>
      <Footer />

      {/* edit_cover Modal */}
      <div className="modal fade primary_modal" id="edit_cover_modal" tabindex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">Update cover</h5>
              <h5 className="modal-title d-none" id="edit_cover_modalLabel_2">Follow Steps</h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">Upload new cover for your profile page. We recommended to upload images in 1140×260 resolution</p>
                <form className="text-center">
                  <div className="file_btn btn primary_btn">Choose image
                    <input className="inp_file" type="file"
                      name="image"
                      onChange={selectFileChange} />
                  </div>
                </form>
              </div>
              <div className="update_cover_div_2 d-none" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}

      {/* edit_profile Modal */}
      <div className="modal fade primary_modal" id="edit_profile_modal" tabindex="-1" role="dialog" aria-labelledby="edit_cover_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="edit_cover_modalLabel_1">Update Profile</h5>
              <h5 className="modal-title d-none" id="edit_cover_modalLabel_2">Follow Steps</h5>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="update_cover_div_1" id="update_cover_div_1">
                <p className="mt-0 approve_desc text-center mb-4">Upload new Profile Image for your profile page.</p>
                <form className="text-center">
                  <div className="file_btn btn primary_btn">Choose image
                    <input className="inp_file" type="file" name="file" onChange={selectProfileChange}/>
                  </div>
                </form>
              </div>
              <div className="update_cover_div_2 d-none" id="update_cover_div_2">
                <div className="media approve_media">
                  {/* <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i> */}
                  <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Preferences</p>
                    <p className="mt-0 approve_desc">Updating cover</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end edit_cover modal */}

      {/* Choose Collection Modal */}
      <div className="modal fade primary_modal" id="report_page_modal" tabindex="-1" role="dialog" aria-labelledby="choose_collection_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="report_page_modalLabel">Report This Profile?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <p className="font_16 font_600 line_20 mb-1">Tell us how this user violates the rules of the site</p>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="primary_label" htmlFor="name">Message</label>
                    <input type="text" className="form-control primary_inp" id="name" placeholder="Tell us some details"/>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Button className="primary_btn w-100">Submit</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Profile Modal */}
      <div className="modal fade primary_modal" id="edit_profile" tabindex="-1" aria-labelledby="EditModalLabel" aria-hidden="true" data-backdrop="static">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="EditModalLabel">Edit profile</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <form>
                <div className="form_white_bg">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="name" onChange={namechange} value={name} placeholder="Name*"/>
                    </div>
                    <div className="form-group col-md-6">                        
                      <input type="text" className="form-control primary_inp" id="desccription" onChange={lastnamechange} value={lastname} placeholder="Last Name*"/>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="email" onChange={emailchange} value={email} placeholder="Email*"/>
                    </div>
                    <div className="form-group col-md-6">                        
                      <input type="text" className="form-control primary_inp" id="desccription" onChange={biochange} value={bio} placeholder="Bio*"/>
                    </div>
                  </div>
                </div>

                <div className="form_grey_bg">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="name" onChange={sitechange} value={website} placeholder="Portfolio Website*"/>
                    </div>
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="desccription" onChange={instachange} value={insta} placeholder="Instagram"/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="name" onChange={fbchange} value={fb} placeholder="Facebook"/>
                    </div>
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="desccription" onChange={twitterchange} value={twitter} placeholder="Twitter"/>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input type="text" className="form-control primary_inp" id="desccription" onChange={otherchange} value={otherlink} placeholder="Other Social Media"/>
                    </div>
                  </div>
                </div>  
                <div className="form_white_bg text-right">
                  <Button type="button" className="cancel_btn mr-2" data-dismiss="modal">Cancel</Button>
                  <Button type="button" className="primary_btn" onClick={()=>profileupdate()}>Update Profile</Button>
                </div>                                            
              </form>
            </div>            
          </div>
        </div>
      </div>


    </div>
  );
}
