import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import { idText } from "typescript";
import ArtistBackground from '../assets/images/artist_banner.png';
import Searchref from "./separate/Search";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Artist(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<Link to="/home"><img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid" /></Link>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="container mb-3">
                <div className="title_flex">
                  <Link to="/artists" className="back_btn"><i className="bi bi-chevron-left mr-2"></i>go back</Link>
                    <Searchref />
                </div>
              </div>
              <div className="items_bg" style={{backgroundImage: `url(${ArtistBackground})`,}}>
                <div className="container">
                  <div className="bg_card_details_panel">
                    <div className="bg_card_details masonry">
                      <div className="item">
                        <img src={require("../assets/images/artist_img.png")} alt="Collections" className="img-fluid" />
                        <div className="item_content">
                          <h2 className="font-weight-bold">@tofi</h2>
                          <div className="d-flex justify-content-between align-items-end mb-2"> 
                            <div>
                              <h6 className="m-0">Value of works</h6>
                              <h3>3K $TAT2</h3>
                            </div>
                            <div>
                              <h6 className="m-0">Highest sell price</h6>
                              <h4>2K $TAT2</h4>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-end mb-2"> 
                            <div>
                              <h6 className="m-0">Average sell price</h6>
                              <h3>1.5K $TAT2</h3>
                            </div>
                            <div>
                              <h6 className="m-0">Works</h6>
                              <h4>3</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg_card_details_right">
                      <div className="followers_txt">
                        <h3>3.2k</h3>
                        <p>followers</p>
                      </div>
                      <Button className="follow_btn ml-3">
                        <i className="bi bi-person-plus mr-2"></i> Follow
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>        

        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2 className="main_title mt-5">Tofi artworks</h2>
              <nav className="masonry_tab_nav mt-4 items_tab_outer">
                <div className="nav nav-tabs masonry_tab primary_tab items_tab" id="nav-tab" role="tablist">
                  <a className="nav-link active" id="art-tab" data-toggle="tab" href="#art" role="tab" aria-controls="art" aria-selected="true">
                    <div className="tab_head">Art</div>                    
                  </a>
                  <a className="nav-link" id="tattoo-tab" data-toggle="tab" href="#tattoo" role="tab" aria-controls="tattoo" aria-selected="false">
                    <div className="tab_head">Tattoo</div>                    
                  </a>
                  <a className="nav-link" id="other-tab" data-toggle="tab" href="#other" role="tab" aria-controls="other" aria-selected="false">
                    <div className="tab_head">Other</div>                    
                  </a>                  
                </div>
              </nav>
              <div className="tab-content explore_tab_content mt-2" id="nav-tabContent">
                <div className="tab-pane fade show active" id="art" role="tabpanel" aria-labelledby="art-tab">
                  <div className="masonry">
                    <div className="item">                    
                      <a href="#">
                        <img src={require("../assets/images/masonary_01.png")} alt="Collections" className="img-fluid" />
                      </a>
                      <div className="item_content">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2>Grand Design</h2>
                          <div className="dropdown d-inline-block">
                            <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#buy_modal">Purchase Now</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                            </div>
                          </div>
                        </div>
                        <h6 className="m-0">Price</h6>
                        <div className="d-flex justify-content-between align-items-end">                      
                          <h3>100 $TAT2</h3>
                          <h4>$300</h4>                
                        </div>
                        <div className="creators_details">
                          <img src={require("../assets/images/myitems-profile.png")} alt="User" className="img-fluid" />
                          <span>John Doe</span>
                        </div> 
                      </div>
                    </div>

                    <div className="item">                    
                      <a href="#">
                        <img src={require("../assets/images/masonary_02.png")} alt="Collections" className="img-fluid" />
                      </a>
                      <div className="item_content">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2>Grand Design</h2>
                          <div className="dropdown d-inline-block">
                            <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#buy_modal">Purchase Now</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                            </div>
                          </div>
                        </div>
                        <h6 className="m-0">Price</h6>
                        <div className="d-flex justify-content-between align-items-end">                      
                          <h3>100 $TAT2</h3>
                          <h4>$300</h4>                
                        </div>
                        <div className="creators_details">                      
                          <img src={require("../assets/images/myitems-profile.png")} alt="User" className="img-fluid" />
                          <span>John Doe</span>
                        </div> 
                      </div>
                    </div>

                    <div className="item">                    
                      <a href="#">
                        <img src={require("../assets/images/masonary_03.png")} alt="Collections" className="img-fluid" />
                      </a>
                      <div className="item_content">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2>Grand Design</h2>
                          <div className="dropdown d-inline-block">
                            <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#buy_modal">Purchase Now</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                            </div>
                          </div>
                        </div>
                        <h6 className="m-0">Price</h6>
                        <div className="d-flex justify-content-between align-items-end">                      
                          <h3>100 $TAT2</h3>
                          <h4>$300</h4>                
                        </div>
                        <div className="creators_details">                      
                          <img src={require("../assets/images/myitems-profile.png")} alt="User" className="img-fluid" />
                          <span>John Doe</span>
                        </div> 
                      </div>
                    </div>

                    <div className="item">                    
                      <a href="#">
                        <img src={require("../assets/images/masonary_04.png")} alt="Collections" className="img-fluid" />
                      </a>
                      <div className="item_content">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2>Grand Design</h2>
                          <div className="dropdown d-inline-block">
                            <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#buy_modal">Purchase Now</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                            </div>
                          </div>
                        </div>
                        <h6 className="m-0">Price</h6>
                        <div className="d-flex justify-content-between align-items-end">                      
                          <h3>100 $TAT2</h3>
                          <h4>$300</h4>                
                        </div>
                        <div className="creators_details">                      
                          <img src={require("../assets/images/myitems-profile.png")} alt="User" className="img-fluid" />
                          <span>John Doe</span>
                        </div> 
                      </div>
                    </div>

                    <div className="item">                    
                      <a href="#">
                        <img src={require("../assets/images/masonary_05.png")} alt="Collections" className="img-fluid" />
                      </a>
                      <div className="item_content">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2>Grand Design</h2>
                          <div className="dropdown d-inline-block">
                            <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#buy_modal">Purchase Now</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                            </div>
                          </div>
                        </div>
                        <h6 className="m-0">Price</h6>
                        <div className="d-flex justify-content-between align-items-end">                      
                          <h3>100 $TAT2</h3>
                          <h4>$300</h4>                
                        </div>
                        <div className="creators_details">                      
                          <img src={require("../assets/images/myitems-profile.png")} alt="User" className="img-fluid" />
                          <span>John Doe</span>
                        </div> 
                      </div>
                    </div>

                    <div className="item">                    
                      <a href="#">
                        <img src={require("../assets/images/masonary_06.png")} alt="Collections" className="img-fluid" />
                      </a>
                      <div className="item_content">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2>Grand Design</h2>
                          <div className="dropdown d-inline-block">
                            <i className="fas fa-ellipsis-h" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#buy_modal">Purchase Now</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#place_bid_modal">New Bid</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#share_modal">Share</a>
                              <a className="dropdown-item" href="#" data-toggle="modal" data-target="#report_modal">Report</a>
                            </div>
                          </div>
                        </div>
                        <h6 className="m-0">Price</h6>
                        <div className="d-flex justify-content-between align-items-end">                      
                          <h3>100 $TAT2</h3>
                          <h4>$300</h4>                
                        </div>
                        <div className="creators_details">                      
                          <img src={require("../assets/images/myitems-profile.png")} alt="User" className="img-fluid" />
                          <span>John Doe</span>
                        </div> 
                      </div>
                    </div>                               
                  </div>
                  <div className="text-center mt-5">
                    <Button className="primary_btn">Load More</Button>
                  </div>
                </div>
                <div className="tab-pane fade" id="tattoo" role="tabpanel" aria-labelledby="tattoo-tab">
                  <div className="proposal_panel_overall">
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="primary_btn">Browse Marketplace</Button>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
                  <div className="proposal_panel_overall">
                    <div className="text-center py-5">
                      <p className="not_found_text">No items found</p>
                      <p className="not_found_text_sub">Come back soon! Or try to browse something for you on our marketplace</p>
                      <div className="mt-3">
                        <Button className="primary_btn">Browse Marketplace</Button>
                      </div>
                    </div>

                  </div>
                </div>                
              </div>
            </GridItem>
          </GridContainer>
        </div>      

      </div>
      <Footer />

    </div>
  );
}
