import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Signup(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div className="inner_header">
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={require("../assets/images/logo.png")} alt="logo" className="img-fluid"/>}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount/>
      <div className={classes.pageHeader + " inner_pageheader"}>        
        <div className="container">
          <GridContainer>            
            <GridItem xs={12} sm={12} md={12}>
              <div className="auth_form_panel">  
                <div className="auth_form_panel_content">
                  <h2>Signup</h2>
                  <h5>We're currently in early access mode, only onboarding a small number of artists each month.</h5>
                  <p>This form is to submit your artisti profile so prove that you're the one we are looking for. Submitted works must be your original artworks, created digitally or physically. Must not be tokenized elsewhere in internet.</p>
                  <p>Also has to be considered as your best works and not inspired by other artists. No illegal stuff please. Thank you for getting in touch.</p>
                </div>
                <form>
                  <div className="form_white_bg">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Name*"/>
                      </div>
                      <div className="form-group col-md-6">                        
                        <input type="text" className="form-control primary_inp" id="desccription" placeholder="Last Name*"/>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Email*"/>
                      </div>
                      <div className="form-group col-md-6">                        
                        <input type="text" className="form-control primary_inp" id="desccription" placeholder="Nickname*"/>
                      </div>
                    </div>
                  </div>

                  <div className="form_grey_bg">
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Portfolio Website*"/>
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="desccription" placeholder="Instagram"/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Facebook"/>
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="desccription" placeholder="Twitter"/>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Tiktok"/>
                      </div>
                      <div className="form-group col-md-6">
                        <input type="text" className="form-control primary_inp" id="desccription" placeholder="Other Social Media"/>
                      </div>
                    </div>
                  </div>

                  <div className="form_white_bg">
                    <div className="form-row">
                      <div className="form-group col-md-12">                        
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Application Video*"/>
                        <p className="authform_notes">Please record around 1 minute video on your phone introducing yourself and your art and anything else you would like to share. Upload to shared google drive (make sure it's open/accessibile) or a video hosting site (YouTube, Vimeo, etc) and paste the link bellow  (english/polish preferable).</p>
                      </div>
                      <div className="form-group col-md-12">                        
                        <input type="text" className="form-control primary_inp" id="name" placeholder="Selection of Your Works*"/>
                        <p className="authform_notes">Please submit 3-5 individual artworks using google drive (make sure it's open/accessibile), if the link is password protected you likely wont get a reply from us.</p>
                      </div>
                      <div className="form-group col-md-12">                        
                        <input type="text" className="form-control primary_inp" id="name" placeholder="About Your Art*"/>
                        <p className="authform_notes">What's the story behind those pieces of art? Why do you think it should be published on TattooMoney NFT Platform?</p>
                      </div>
                      <div className="form-group col-md-12">                        
                        <input type="text" className="form-control primary_inp" id="name" placeholder="How did You Hear About Us?"/>                        
                      </div>
                    </div>
                    <div className="auth_checkbox">
                      <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="customCheck1"/>
                        <label className="custom-control-label" for="customCheck1">I confirm that none of my submitted works containing any infringing or unauthorized copyrighted material.</label>
                      </div>
                    </div>
                    <div className="mt-3 text-center">
                      <Button className="primary_btn">Submit Profile</Button>
                      <p className="authform_notes">It can take up to 3 months to get you verified</p>
                    </div>
                  </div>                                    
                </form>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className="newsletter_section">
          <div className="container">
            <h2>Newsletter</h2>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">                        
                  <input type="email" className="form-control primary_inp" id="name" placeholder="Email*"/>
                </div>
              </div>
              <div className="custom_checkbox">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck2"/>
                  <label className="custom-control-label" for="customCheck2">Lorem ipsum dolor sit amet rivacy elit sed.</label>
                </div>
              </div>
              <div className="mt-3 text-center">
                <Button className="primary_btn">Subscribe</Button>                
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
