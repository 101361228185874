import React, {useEffect,useState,useRef} from "react";
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom'
import Web3 from 'web3';
import '@metamask/legacy-web3'
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import $ from 'jquery';
import axios from 'axios';
import config from '../../lib/config';
import Modal from 'react-modal';
import tat2abi from '../../ABI/TAT2.json';
import WalletConnectProvider from "@walletconnect/web3-provider";

import {
  AddressUserDetails_GetOrSave_Action,
  getuserdetails,
  getCurAddr
} from '../../actions/users';

import { toastAlert }  from "../../actions/toastAlert";

const useStyles = makeStyles(styles);

export default function ConnectWallet(props) {

  const classes = useStyles();
  const { ...rest } = props;

  const {
    WalletConnected
  } = props;

  const timerRef = useRef(null);

  useEffect(() => {
    getInit();
  }, []);

  async function getInit() {
    connect_Wallet();
  }

  async function connect_Wallet() {
    window.$('.modal').modal('hide');
    if(localStorage.getItem("lout")){

      if(localStorage.getItem("wtype") && localStorage.getItem("wtype")!=null && localStorage.getItem("wtype")!=undefined && localStorage.getItem("wtype")!="" && localStorage.getItem("wtype")== 'trust') {
       try{
         var provider = new WalletConnectProvider({
          rpc: {
            [config.livechainid]: config.liverpcUrls
          },
          chainId: config.livechainid,
          //infuraId : "91648cc486154ce699e7a55b9ddcbfba"
        });

        provider.on("connect", () => {
        });

        await provider.enable();
        web3 = new Web3(provider);
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if (config.networkversion == network) {
          props.Set_WalletConnected(true);
          localStorage.setItem("wtype","trust")
          localStorage.setItem("tksmudisgjeush",'yes');
          localStorage.setItem("address",result[0]);
          localStorage.setItem("lout",true)
          props.Set_Accounts(result[0]);
          props.Set_UserAccountAddr(result[0]);
          var tat2contract = new web3.eth.Contract(tat2abi,config.tat2address);
          var val= await tat2contract.methods.balanceOf(result[0]).call();
            var balance = parseFloat(val)/1000000000000000000;
            props.Set_UserAccountBal(balance);
            AfterWalletConnected();
          
        } else {
         await provider.disconnect();
         props.Set_WalletConnected(false);
          toastAlert('error', "please select Polygon chain network on your wallet", 'network');
        }
        provider.on("disconnect", async (code: number, reason: string) => {
            localStorage.clear();
            window.location.href="/"
        });
      }catch(err){
        localStorage.clear();
        window.location.reload()
      }
      }else{
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if (typeof web3 !== 'undefined') {

        }
        else {
          props.Set_WalletConnected(false);
          toastAlert('error',"Connect to Polygon Network",'error')
          return false;
        }

      if (provider) {
        try {
          await provider.enable();
            var web3 = new Web3(window.web3.currentProvider)
            if(window.web3.currentProvider.networkVersion == config.networkversion){
              if (window.web3.currentProvider.isMetaMask === true) {
                if(window.web3 && window.web3.eth && window.web3.eth.defaultAccount) {
                  props.Set_WalletConnected(true);
                  var result = await web3.eth.getAccounts()
                  var setacc = result[0];
                  localStorage.setItem("tksmudisgjeush","yes")
                  localStorage.setItem("address",result[0])
                  localStorage.setItem("lout",true)
                  props.Set_Accounts(setacc);
                  props.Set_UserAccountAddr(setacc);
                  var tat2contract = new web3.eth.Contract(tat2abi,config.tat2address);
                  var val= await tat2contract.methods.balanceOf(result[0]).call();
                    var balance = parseFloat(val)/1000000000000000000;
                    props.Set_UserAccountBal(balance);
                    AfterWalletConnected();
                  }
              }
            }
            else {
              var chainId = web3.utils.toHex(config.networkversion);
              var switchres = await web3.currentProvider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainId }]
              });
              if(window.web3.currentProvider.networkVersion == config.networkversion){
               if (window.web3.currentProvider.isMetaMask === true) {
                if(window.web3 && window.web3.eth && window.web3.eth.defaultAccount) {
                  props.Set_WalletConnected(true);
                  var result = await web3.eth.getAccounts()
                  var setacc = result[0];
                  localStorage.setItem("tksmudisgjeush","yes")
                  localStorage.setItem("address",result[0])
                  localStorage.setItem("lout",true)
                  props.Set_Accounts(setacc);
                  props.Set_UserAccountAddr(setacc);
                  var tat2contract = new web3.eth.Contract(tat2abi,config.tat2address);
                  var val= await tat2contract.methods.balanceOf(result[0]).call();
                    var balance = parseFloat(val)/1000000000000000000;
                    props.Set_UserAccountBal(balance);
                    AfterWalletConnected();
                }
              }
            }
              // localStorage.clear()
              // props.Set_WalletConnected(false);
              // toastAlert('error',"Please Connect to Ploygon Mainnet",'error')
            }
         
        } catch (err) {
          localStorage.clear()
          props.Set_WalletConnected(false);
        }
      }
      else {
        localStorage.clear()
        props.Set_WalletConnected(false);
        toastAlert('error',"Please Add Metamask External",'error')
      }
    }
   }
  }


  async function AfterWalletConnected() {
    await AddressUserDetails_GetOrSave_Call();
    props.AfterWalletConnected();
  }
  async function AddressUserDetails_GetOrSave_Call() {
    var addr = await getCurAddr();
    var ReqData = { addr : addr }
    var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    if (Resp && Resp.data && Resp.data.data && Resp.data.data.User && Resp.data.data.User._id) {      
      props.Set_AddressUserDetails(Resp.data.data.User);
    } else {
      props.Set_AddressUserDetails({});
    }
    return true;
  }

  window.addEventListener('load',async (event) => {
    event.preventDefault();
    var provider = window.ethereum;
    var web3 = new Web3(provider);
    if(window.ethereum) {
      window.ethereum.on('accountsChanged', async function (accounts) {
        if(timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(async() => {
          var result = await web3.eth.getAccounts()
          var setacc = result[0];
          localStorage.setItem("tksmudisgjeush","yes")
          localStorage.setItem("address",result[0])
          localStorage.setItem("lout",true)
          props.Set_Accounts(setacc);
          connect_Wallet();
          window.location.reload()
        }, 1000);
      })

      window.ethereum.on('networkChanged',async function (networkId) {
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if(networkId == config.networkversion){
          if(timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async() => {
            var result = await web3.eth.getAccounts()
            var setacc = result[0];
            localStorage.setItem("tksmudisgjeush","yes")
            localStorage.setItem("address",result[0])
            localStorage.setItem("lout",true)
            props.Set_Accounts(setacc);
            connect_Wallet();
            window.location.reload()
          }, 1000);
          props.Set_WalletConnected(true);
        }
        else {
          localStorage.clear()
          props.Set_WalletConnected(false);
        }
      })
    }
  })

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  let subtitle;

  const [WalletConnectNotifyPopup, Set_WalletConnectNotifyPopup] = React.useState(false);

  function openModal() {
    Set_WalletConnectNotifyPopup(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
   // subtitle.style.color = '#f00';
  }

  function closeModal() {
    Set_WalletConnectNotifyPopup(false);
  }

  var WalletConnectNotifyPopup_test = '';

  if(WalletConnected) {
    WalletConnectNotifyPopup_test = false;
  }
  else {
    WalletConnectNotifyPopup_test = true;
  }

  var pathVal = '';

  const location = useLocation();
  if(location.pathname) {
    if(location.pathname.split('/').length >= 2) {
      pathVal = location.pathname.split('/')[1];
    }
  }

  const [location_pathname, Set_location_pathname] = useState(pathVal);

  const connect_Wallet_call = (type) => {
   
  }

  return (
    <div>
      {props.fullLoader && <div class="loading"></div>}
      <div id="connect_Wallet_call" onClick={() => connect_Wallet_call('metamask')}></div>
    </div>
  )
}