
/*eslint-disable*/
import React , { useEffect , useState} from "react";
import { Notifications, AccountBalanceWallet } from '@material-ui/icons';
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, Button, Hidden } from "@material-ui/core";
import { toastAlert }  from "../../actions/toastAlert";
// core components 
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import {
    headerSearch
} from "../../actions/token"
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import config from '../../lib/config';
const useStyles = makeStyles(styles);
//const IPFS_IMGurl   = config.IPFS_IMG;
const IPFS_IMGurl = config.baseurl+"/nftImg"
                                     
export default function Searchref(props) {
  const classes = useStyles();
  const [searchstatus,setsearchstatus] = React.useState(false)
  const [itemList,setitemList] = React.useState([])
  const [userList,setuserList] = React.useState([])
  const [Keyword,Set_Keyword] = React.useState("")

  const searchChange = async (e) => {
        var value = e.target.value;
        if (value && value != "" &&  value.trim() != '' && value != null && value != undefined) {
            Set_Keyword(value);
            var response = await headerSearch({ search: value });
            setitemList(response.result);
            setuserList(response.userList);
            setsearchstatus(true);
        }else{
            setitemList([]);
            setuserList([]);
            Set_Keyword("");
        }
    }
  async function checkwall(){
      if(localStorage.getItem("tksmudisgjeush")){

      }else{
        toastAlert("error","Connect Wallet to Move Further", "error")
      }
    }

  async function closesearch(){
    //setsearchstatus(false)
  }

  return (
     <div className="input_group search_drob_down">
      <div className="input-group-prepend"><i className="bi bi-search"></i></div>
      <input type="text" className="form-control search_input" value={Keyword} onChange={searchChange} onBlur={closesearch} placeholder="Search Artwork or Artist" />
      <div className={searchstatus?"card_search_result":"card_search_result_hide"} >
      <div className="search_item">
      <h4>Items</h4>
      {itemList && itemList.length>0 && itemList.map((item)=>{
        return(          
          <Link to={localStorage.getItem("tksmudisgjeush")?"/art/"+item.tokenName+"/"+item.tokenCounts:"#"} >
          {
            item.image.split('.').pop() == "mp4" ?
            <video src={`${IPFS_IMGurl}/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid"/>
            :
            item.image.split('.').pop() == "mp3" ?
            <img src={require("../../assets/images/music.png")} alt="Collections" className="img-fluid" style={{backgroundColor:"white"}}/>
            :
            <img src={`${IPFS_IMGurl}/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
            }
          <div className="">
              <h2 className="title_search">{item.tokenName}</h2>
              {/*<p>test search content new</p>*/}
          </div>
          </Link>
          )
      })}
     </div>
     <div className="search_item">
     <h4>Users</h4>
      {userList && userList.length>0 && userList.map((item)=>{
        return(
        <Link to={item && item.name && item.name!=null && item.name!=undefined && item.name!=""?`/user/${item.name}`:`/user/${item.curraddress}`} >

           {item && item.image!=undefined && item.image!=null && item.image!="" ?
              <img src={`${config.baseurl}/profile/${item.image}`} alt="Collections" className="img-fluid" />
              :
              <img src={`${config.baseurl}/images/noimage.png`} alt="Collections" className="img-fluid" />
            }
          <div className="">
              <h2 className="title_search">{item.name}</h2>
              {/*<p>test search content new</p>*/}
          </div>
          </Link>
          )
      })}
    </div>

      </div>
    </div>
  );
}
