import React, {
    forwardRef,
    useImperativeHandle
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';

import {
    AddLikeAction,
    GetLikeDataAction,
    initialtransfer,
    deletetransfer
} from '../../actions/token';
import multiple

 from '../../ABI/multiple.json';
import single from '../../ABI/single.json';
import {
    getCurAddr
} from '../../actions/users';
import {
    convertion,
    BurnField,
    TokenCounts_Get_Detail_Action,
    TransferNow_Complete_Action
  } from '../../actions/token';
import { Button, TextField } from '@material-ui/core';
import {
    useHistory
} from "react-router-dom";
import { getmylog } from '../../helper/walletconnect';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;


export const TransferRef = forwardRef((props, ref) => {
    const history = useHistory();
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
    const [onwer_price,set_owner_price]=React.useState({})
    const [transferLoading, settransferLoading]= React.useState(1);
    const [noofitems,setnoofitem]=React.useState(1);
    const [address,setaddress] = React.useState("");
    const [showingLoader, setshowingLoader] = React.useState(false);
    const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = React.useState({});
    const [OwnersDetailFirst, Set_OwnersDetailFirst] = React.useState({});
    const [tokenCounts_Detail, Set_tokenCounts_Detail] = React.useState({});
    const [Bids, Set_Bids] = React.useState({});
    const [MyTokenBalance, Set_MyTokenBalance] = React.useState(0);
    const [MyTokenDetail, Set_MyTokenDetail] = React.useState(0);
    const [Transferdata,setTransferdata] = React.useState(0);
    var  [item, Set_item] = React.useState({}); 
    var  [item1, Set_item1] = React.useState({}); 
    // address from,
    // uint256 tokenId,
    // address token,
    // uint256 _type,
    // uint256 NOFToken
    var {
        item,
        UserAccountAddr,
        GetUserBal,
       // Set_item
    } = props;

    async function FormSubmit(){
      settransferLoading('processing')
    if(parseInt(noofitems)<=parseInt(Transferdata.balance)){
      if(address!=""){
    Set_FormSubmitLoading("processing");
    var connectwallet = localStorage.getItem("tksmudisgjeush");
    if (!connectwallet) {
      settransferLoading('try');
      Set_FormSubmitLoading("error");
      window.location.href = "/connect";
      return false;
    }
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
        var isadd = await web3.utils.isAddress(address)
        if(isadd){
          if((props.Accounts).toLowerCase()!=(address).toLowerCase()){
          if (Transferdata.type == 721) {
            var initdata = {
                  tokenOwner: UserAccountAddr, // old owner
                  UserAccountAddr: address, // new owner
                  tokenCounts: Transferdata.tokenCounts,
                  tokenType: Transferdata.type,
                  NoOfToken: 1,
                  contractAddress:Transferdata.contractAddress
                };
            var Resp = await initialtransfer(initdata);

            var CoursetroContract = new web3.eth.Contract(
              single,
              Transferdata.contractAddress
            );
            CoursetroContract.methods.safeTransferFrom(
              UserAccountAddr,
              address,
              Transferdata.tokenCounts
              ).send({ from: props.Accounts })
              .then(async (result) => {
                Set_FormSubmitLoading("done");
                var postData = {
                  tokenOwner: UserAccountAddr, // old owner
                  UserAccountAddr: address, // new owner
                  tokenCounts: Transferdata.tokenCounts,
                  tokenType: Transferdata.type,
                  NoOfToken: 1,
                  transactionHash: result.transactionHash,
                  mintHash: result.transactionHash,
                  contractAddress:Transferdata.contractAddress
                };
                var Resp = await TransferNow_Complete_Action(postData);

                if (
                  Resp.data &&
                  Resp.data.toast &&
                  Resp.data.toast.type == "success"
                ) {
                  settransferLoading('done');
                  toast.success(
                    "Collectible Transferred successfully",
                    toasterOption
                  );
                 window.location.reload()
                }
              })
              .catch(async(error) => {
                var initdata = {
                  tokenOwner: UserAccountAddr, // old owner
                  UserAccountAddr: address, // new owner
                  tokenCounts: Transferdata.tokenCounts,
                  tokenType: Transferdata.type,
                  NoOfToken: 1,
                  contractAddress:Transferdata.contractAddress
                };
                var Resp = await deletetransfer(initdata);
                settransferLoading('try');
                Set_FormSubmitLoading("error");
                toast.error("Order not placed", toasterOption);
              });
          }else{
            var initdata = {
                  tokenOwner: UserAccountAddr, // old owner
                  UserAccountAddr: address, // new owner
                  tokenCounts: Transferdata.tokenCounts,
                  tokenType: Transferdata.type,
                  NoOfToken: noofitems,
                  contractAddress:Transferdata.contractAddress
                };
            var Resp = await initialtransfer(initdata);
            var CoursetroContract = new web3.eth.Contract(
              multiple,
              Transferdata.contractAddress
            );
            CoursetroContract.methods.safeTransferFrom(
              UserAccountAddr,
              address,
              Transferdata.tokenCounts,
              noofitems,
              "0x"
              ).send({ from: props.Accounts })
              .then(async (result) => {
                Set_FormSubmitLoading("done");
                var postData = {
                  tokenOwner: UserAccountAddr, // old owner
                  UserAccountAddr: address, // new owner
                  tokenCounts: Transferdata.tokenCounts,
                  tokenType: Transferdata.type,
                  NoOfToken: noofitems,
                  transactionHash: result.transactionHash,
                  mintHash: result.transactionHash,
                  contractAddress:Transferdata.contractAddress
                };
                var Resp = await TransferNow_Complete_Action(postData);

                if (
                  Resp.data &&
                  Resp.data.toast &&
                  Resp.data.toast.type == "success"
                ) {
                  settransferLoading('done');
                  toast.success(
                    "Collectible Transferred successfully",
                    toasterOption
                  );
                   window.location.reload()
                }
              })
              .catch(async(error) => {
                var initdata = {
                  tokenOwner: UserAccountAddr, // old owner
                  UserAccountAddr: address, // new owner
                  tokenCounts: Transferdata.tokenCounts,
                  tokenType: Transferdata.type,
                  NoOfToken: noofitems,
                  contractAddress:Transferdata.contractAddress
                };
                var Resp = await deletetransfer(initdata);
                settransferLoading('try');
                Set_FormSubmitLoading("error");
                toast.error("Order not placed", toasterOption);
              });
          }
          }else{
            settransferLoading('try');
            toast.error("Please Check , Owner address and Transfer Address are same",toasterOption)
          }
          }else{
           settransferLoading('try');
           toast.error("Enter Valid Address",toasterOption)
        }
      }else{
        settransferLoading('try');
      toast.error("Enter Valid Address",toasterOption)
     }
     }else{
      settransferLoading('try');
      toast.error("Insufficient Quantity",toasterOption)
     }

    }

    const TokenCounts_Get_Detail_Call = async (payload) => {
      var curAddr = await getCurAddr();
      setshowingLoader(true)
      var Resp = await TokenCounts_Get_Detail_Action(payload);
      setTimeout(() => {
        // setshowingLoader(true)
        // change
        setshowingLoader(false)
      }, 2000);
  
      if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {
  
        var TokenResp = Resp.data.Detail.Resp;
        if (
          TokenResp
          && TokenResp.Token
          && TokenResp.Token[0]
          && TokenResp.Token[0].tokenowners_current
        ) {
          for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
            const element = TokenResp.Token[0].tokenowners_current[i];
            set_owner_price(element)
            if (element.tokenPrice > 0 && element.tokenOwner != curAddr) {
              Set_BuyOwnerDetailFirst(element);
              break;
            }
            if (element.tokenPrice > 0 && element.tokenOwner == curAddr) {
              Set_OwnersDetailFirst(element);
              break;
            }
          }
        }
        Set_tokenCounts_Detail(TokenResp);
        if (TokenResp.Bids) {
          Set_Bids(TokenResp.Bids);
        }
  
        var IndexVal = -1;
  
        if (TokenResp.Token[0].tokenowners_all && curAddr) {
          var tokenowners_all = TokenResp.Token[0].tokenowners_all;
          IndexVal = tokenowners_all.findIndex(val => (val.tokenOwner.toString() == curAddr.toString() && val.balance > 0));
        }
      
        if (IndexVal > -1) {
          Set_MyTokenBalance(tokenowners_all[IndexVal].balance);
          Set_MyTokenDetail(tokenowners_all[IndexVal])
        }
        else {
          Set_MyTokenDetail({});
          Set_MyTokenBalance(0);
        }
  
        if (TokenResp.Token && TokenResp.Token[0]) {
          //(TokenResp.Token[0]);
          Set_item1(TokenResp.Token[0]);
        }
      
    }
    }

    const handleaddressChange = (e) => {
      setaddress(e.target.value)
    }

    const handleChange = (e) => {
            setnoofitem(e.target.value)
    }

    async function cleardata(){
      setnoofitem(1)
      setaddress("")
    }

    useImperativeHandle(
        ref,
        () => ({
            async Transfer_Click(item,transferdata) {
                setTransferdata(transferdata);
                var curAddr = await getCurAddr();
                // alert(curAddr)
                  // if(!(window.ethereum)){
                    var payload = {
                      curAddr: curAddr,
                      tokenCounts: transferdata.tokenCounts
                    };
                    TokenCounts_Get_Detail_Call(payload);
                window.$('#Transfer_token_modal').modal('show');
               
            },
        }),
    )

    return (
        <div class="modal fade primary_modal" id="Transfer_token_modal" tabindex="-1" role="dialog" aria-labelledby="burn_token_modalCenteredLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
    <div class="modal-content">
      <div class="modal-header text-center">
      <h5 class="modal-title" id="burn_token_modalLabel">Transfer token</h5>
      <button type="button" id="closeburn" class="close" data-dismiss="modal" aria-label="Close" >
        <span aria-hidden="true">&times;</span>
      </button>
      </div>
      <div class="modal-body px-0">
        <form className="bid_form">
        <div className="px-4">
            <p className="checkout_text_light_white text-center" style={{color:'black',fontWeight:'bold'}}>{MyTokenDetail.balance} Tokens Available</p>
          </div> 
          <div className="px-4">
            <p className="checkout_text_light_white">Are you sure to Transfer this token? This action cannot be undone. Token will be transfered to Entered address</p>
          </div>  
          <div className="px-4 mt-4 pt-2">   
         {Transferdata.type==721?    
         <div> 
           <label>Address</label> 
            <input 
              id = "address" 
              name="address" 
              class="form-control"
              value={address}
              onChange = {(e) => { handleaddressChange(e)}}
              placeholder = "Address"
            />   
            <label>Quantity</label> 
            <input 
              id = "burn" 
              name="burn" 
              class="form-control"
               value={noofitems}
               onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
            />
             <div className="text-center mt-3">
              <Button 
                className="burn_btn_red primary_btn btn-block" 
                onClick={(() => FormSubmit(item1,MyTokenDetail))}
                disabled = { (transferLoading == 'done' || transferLoading=='processing' ) }
                > Transfer
                  {transferLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                  {transferLoading == 'init' && 'Continue'}
                  {transferLoading == 'processing' && 'In-progress...'}
                  {transferLoading == 'done' && 'Done'}
                  {transferLoading == 'try' && 'Try Again'}
                 
                </Button>
              <Button className="primary_btn btn-block" onClick={()=>cleardata()} data-dismiss="modal" aria-label="Close">Cancel</Button>
            </div>
            </div>
            :
            <div>
            <input 
              id = "address" 
              name="address" 
              class="form-control"
              value={address}
              onChange = {(e) => { handleaddressChange(e)}}
              placeholder = "Address"
            />
              <input 
              id = "burn" 
              name="burn" 
              class="form-control"
              value={noofitems}
              onKeyDown={(evt) => ["e", "E", "+", "-",".",","].includes(evt.key) && evt.preventDefault()}
              onChange = {(e) => { handleChange(e)}}
            />
              <div className="text-center mt-3">
             { noofitems && parseFloat(noofitems)>0?
              <Button 
                type = "button"
                className="burn_btn_red primary_btn btn-block" 
                onClick={(() => FormSubmit())}
                disabled = { (transferLoading == 'done' || transferLoading=='processing' || transferLoading == 'zero'||transferLoading == 'errors'||transferLoading == 'empty') }
                >{transferLoading ==1 && "Transfer"}
                  {transferLoading == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                  {transferLoading == 'init' && 'Continue'}
                  {transferLoading == 'processing' && 'In-progress...'}
                  {transferLoading == 'done' && 'Done'}
                  {transferLoading == 'try' && 'Try Again'}
                  {transferLoading == 'errors' && 'Check Balance'}
                  {transferLoading == 'zero' && "Qty can't be Zero"}
                  {transferLoading == 'empty' && "Qty can't be Emptty"}
                </Button>
                :
                <Button 
                className="burn_btn_red primary_btn btn-block" 
                >Please Choose Valid Qty
                </Button>
              }
              <Button className="primary_btn btn-block" onClick={()=>cleardata()} data-dismiss="modal" aria-label="Close">Cancel</Button>
            </div>
          </div>
        
         }
          
          </div>
        </form>
      </div>
    </div>
    </div>
  </div>
    )
})

