import React, {
    forwardRef,
    useImperativeHandle,
    useEffect,
    useState
} from 'react';

import Web3 from 'web3';
import $ from 'jquery';
import config from '../../lib/config';
import TAT2ABI from '../../ABI/TAT2.json';
import multiple from '../../ABI/multiple.json';
import single from '../../ABI/single.json';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import isEmpty from "../../lib/isEmpty";
import CryptoJS from 'crypto-js';
import { Button, TextField } from '@material-ui/core';
import {
    getCurAddr
} from '../../actions/users';
import { getmylog } from '../../helper/walletconnect';

import {
    TokenCounts_Get_Detail_Action,
    BidApply_ApproveAction,
    acceptBId_Action,
    CancelBid_Action,
    getfees
} from '../../actions/token';

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const PlaceAndAcceptBidRef = forwardRef((props, ref) => {

    const [BidformSubmit, Set_BidformSubmit] = React.useState(false);
    const [NoOfToken_NeedToSend, Set_NoOfToken_NeedToSend] = React.useState(false);
    const [MetaMaskAmt, setMetaMaskAmt] = React.useState(false);
    const [FormSubmitLoading, Set_FormSubmitLoading] = React.useState('');
    const [AcceptCallStatus, setAccaptCallStatus] = React.useState('init'); 
    const [singlefee, setsinglefee] = useState(0);
    const [multiplefee, setmultiplefee] = useState(0);
    const [royalitypercent, setroyalitypercent] = useState(0);
    const [royalityfee, setroyalityfee] = useState(0);
    const [royaltyamt,setroyaltyamt] = useState(0);

    var {
        Set_WalletConnected,
        Set_UserAccountAddr,
        Set_UserAccountBal,
        Set_AddressUserDetails,
        Set_Accounts,
        Set_MyItemAccountAddr,
        Set_tokenCounts,
        Set_tokenCounts_Detail,
        Set_MyTokenBalance,
        Set_Bids,
        Set_AccepBidSelect,
        Set_tokenBidAmt,
        Set_NoOfToken,
        Set_ValidateError,
        Set_TokenBalance,
        Set_YouWillPay,
        Set_YouWillPayFee,
        Set_YouWillGet,
        Set_item,
        Set_BidApply_ApproveCallStatus,
        Set_BidApply_SignCallStatus,
        setTotaluserbidAmt,
        BidApply_ApproveCallStatus,
        totaluserbidAmt,
        WalletConnected,
        UserAccountAddr,
        UserAccountBal,
        AddressUserDetails,
        Accounts,
        tokenCounts,
        item,
        tokenCounts_Detail,
        MyTokenBalance,
        Bids,
        AccepBidSelect,
        tokenBidAmt,
        NoOfToken,
        ValidateError,
        TokenBalance,
        YouWillPay,
        YouWillPayFee,
        YouWillGet,
        BidApply_ApproveCallStatus,
        BidApply_SignCallStatus,
        AllowedQuantity
    } = props;


    async function PriceCalculate_this(data={}) {
         var mydata = await getmylog();
         const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
         var sfee = await web3.utils.toWei((singlefee).toString())
         var mfee = await web3.utils.toWei((multiplefee).toString())
        var price = (typeof data.tokenBidAmt != 'undefined') ? data.tokenBidAmt : tokenBidAmt;
        var quantity = (typeof data.NoOfToken != 'undefined') ? data.NoOfToken : NoOfToken;
        if(price == '') { price = 0; }
        if(quantity == '') { quantity = 0; }
        if(isNaN(price) != true && isNaN(quantity) != true) {
          if(item.type == 721) {
            var totalPrice = price;
            var decprice = await web3.utils.toWei((price).toString())
            var per = (decprice * (sfee)) / 1e20;
          }
          else {
            var totalPrice = price * quantity;
            var decprice = await web3.utils.toWei((totalPrice).toString())
            var per = (decprice * (mfee)) / 1e20;
          }

          totalPrice = parseFloat(totalPrice);
          var finamt = await web3.utils.toWei((totalPrice).toString())
          var sendMMAmt=parseFloat(finamt) + parseFloat(per);
          setMetaMaskAmt(sendMMAmt)
          // var finalPrice = totalPrice + per;
          // var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
          // Set_YouWillPay(totalPriceWithFee);
          var finalPrice = sendMMAmt/config.decimalvalues;
          var totalPriceWithFee = parseFloat(finalPrice).toFixed(config.toFixed);
          Set_YouWillPay(totalPriceWithFee);
        }
        else {
          Set_YouWillPay(0);
        }
    }

    const Validation_PlaceABid = (chk) => {
        if(chk) {
          var ValidateError = {};
      
          if(NoOfToken == '') {
            ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
          }
          else if(isNaN(NoOfToken) == true) {
            ValidateError.NoOfToken = '"Quantity" must be a number';
          }
          else if(NoOfToken == 0) {
            ValidateError.NoOfToken = '"Quantity" must be greater than zero or equal to one';
          }
          else if(NoOfToken > AllowedQuantity) {
            ValidateError.NoOfToken = '"Quantity" must be less than or equal to '+AllowedQuantity;
          }
      
          if(tokenBidAmt == '') {
            ValidateError.tokenBidAmt = '"Bid amount" is not allowed to be empty';
          }
          else if(isNaN(tokenBidAmt) == true) {
            ValidateError.tokenBidAmt = '"Bid amount" must be a number';
          }
          else if(tokenBidAmt == 0) {
            ValidateError.tokenBidAmt = '"Bid amount" must be greater than 0';
          }
          // else if(tokenBidAmt > tokenCounts_Detail.TotalQuantity) {
          //   ValidateError.tokenBidAmt = '"Bid amount" must be less than or equal to '+tokenCounts_Detail.TotalQuantity;
          // }
          else if(item.minimumBid > tokenBidAmt) {
            ValidateError.tokenBidAmt = '"Bid amount" must be higher than or equal to '+item.minimumBid;
          }

          // else if(YouWillPay > TokenBalance) {
          //   ValidateError.tokenBidAmt = 'Insufficient balance, Check your wallet balance';
          // }
          Set_ValidateError(ValidateError);
          return ValidateError;
        }
    }
    const onKeyUp = (e) => {
      var charCode = e.keyCode;
      if((charCode>47 && charCode <58) || (charCode>95 && charCode <106)){
        var ValidateError = {};
        Set_ValidateError(ValidateError);
      }else{
        var ValidateError = {};
        ValidateError.NoOfToken = '"Quantity" must be a number';
        Set_NoOfToken("");
        Set_ValidateError(ValidateError);
      }
    }
    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined' && e.target.name) {
          var value = e.target.value;
          switch(e.target.name) {
            case 'tokenBidAmt':
              if(value != '' && isNaN(value) == false && value > 0) {
                Set_tokenBidAmt(value);
                PriceCalculate_this({tokenBidAmt:value});
                ValidateError.tokenBidAmt = '';
                Set_ValidateError(ValidateError);
              } 
              else {
                if(value==0){
                  ValidateError.tokenBidAmt = 'price must be greater than 0';
                  Set_ValidateError(ValidateError);
                  Set_tokenBidAmt('0');
                  PriceCalculate_this({tokenBidAmt:value});
                }
                else{
                  ValidateError.tokenBidAmt = 'Enter Valid price';
                  Set_ValidateError(ValidateError);
                  Set_tokenBidAmt('0');
                  PriceCalculate_this({tokenBidAmt:value});

                }
               
              }
              break;
              case 'NoOfToken':
                Set_NoOfToken(value);
                PriceCalculate_this({NoOfToken:value});
                break;
              // code block
          }
          // window.$('#Validation_PlaceABid').click();
        }
    }

    async function FormSubmit_PlaceABid (e) {
        Set_BidformSubmit(true);
        var connectwallet=localStorage.getItem("tksmudisgjeush");
        if(!connectwallet){
          window.location.href="/connect";
          return false;
        }

        if (YouWillPay>UserAccountBal) {
          toast.error("Insufficient token balance", toasterOption);
          return false;
        }
        var errors = await Validation_PlaceABid(true);
        var errorsSize = Object.keys(errors).length;
        if(errorsSize != 0) {
          toast.error("Form validation error. Fix all mistakes and submit again", toasterOption);
          return false;
        }
        window.$('#place_bid_modal').modal('hide');
        window.$('#proceed_bid_modal').modal('show');
    }

    async function BidApply_ApproveCall() {
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
         var currAddr = await getCurAddr();
        if (!currAddr) {
          toast.warning("OOPS!..connect Your Wallet", toasterOption);
        }
      
        Set_BidApply_ApproveCallStatus('processing');
        var CoursetroContract = new web3.eth.Contract(TAT2ABI, config.tat2address);
         var currAddr = await getCurAddr();
        if(item.type==721){
          var getAllowance = await CoursetroContract.methods.allowance(currAddr,item.contractAddress).call();
        }else{
          var getAllowance = await CoursetroContract.methods.allowance(currAddr,item.contractAddress).call();
        }

     
        var sendVal=parseInt(MetaMaskAmt)+parseInt(getAllowance);
        sendVal =await convert(sendVal)
        CoursetroContract
        .methods
        .increaseAllowance(
          (item.type==721)?item.contractAddress:item.contractAddress,
          MetaMaskAmt.toString()
        )
        .send({from: Accounts})
        .then(async (result) => {
          toast.success("Approve Successfully", toasterOption);
          var BidData = {
            tokenCounts: item.tokenCounts,
            tokenBidAddress: UserAccountAddr,
            tokenBidAmt: tokenBidAmt.toString(),
            NoOfToken: item.type == 721 ? 1 : NoOfToken,
            servicepercent: item.type == 721 ? singlefee : multiplefee,
          }
          var Resp = await BidApply_ApproveAction(BidData);
        
          if(Resp.data && Resp.data.type && Resp.data.type == 'success') {
          
            Set_BidApply_ApproveCallStatus('done');
          }
          else {
            toast.error("Approve failed", toasterOption);
            Set_BidApply_ApproveCallStatus('tryagain');
          }
        })
        .catch((error) => {
          toast.error("Approve failed", toasterOption);
          Set_BidApply_ApproveCallStatus('tryagain');
        })
    }
    async function BidApply_SignCall() {
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
         var currAddr = await getCurAddr();
        if (!currAddr) {
          toast.warning("OOPS!..connect Your Wallet", toasterOption);
          return;
        }
    
        Set_BidApply_SignCallStatus('processing');
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify("Bidding a Art "+item.tokenCounts), 'my-secret-key@123').toString();
        var datass = "Welcome to TattooMoney NFT!"+"\n\n"+"Click to sign in and accept the TattooMoney Terms of Service: "+ config.baseurl+"/Terms/ToS.pdf"+"\n\n"+"This request will not trigger a blockchain transaction or cost any gas fees"+"\n\n"+"Your authentication status will reset after 24 hours."+"\n\n"+"Wallet address: "+currAddr+"\n\n"+"Nonce: "+ciphertext+"\n"
        web3.eth.personal.sign(datass, currAddr, "Bid Placed")
        .then(async (result) => {
          toast.success("Bid sign successfully", toasterOption);
          Set_BidApply_SignCallStatus('done');
          setTimeout(() => window.$('#proceed_bid_modal').modal('hide'), 600);
          setTimeout(() => window.location.reload(), 1200);
        })
        .catch(() => {
          toast.error("Sign failed", toasterOption);
          Set_BidApply_SignCallStatus('tryagain');
        })
    }

    async function CancelBid_Proceed(curBid_val) {
      var payload = {
        tokenCounts: curBid_val.tokenCounts,
        tokenBidAddress: curBid_val.tokenBidAddress
      }
      var Resp = await CancelBid_Action(payload);
      if(Resp && Resp.data && Resp.data.toast && Resp.data.toast.type && Resp.data.toast.message) {
        if(Resp.data.toast.type == 'error') {
          toast.error(Resp.data.toast.message, toasterOption);
        }
        else if(Resp.data.toast.type == 'success') {
          toast.success(Resp.data.toast.message, toasterOption);
        }
      }
      setTimeout(() => window.$('.modal').modal('hide'), 600);
      window.location.reload();
    }

    async function AcceptBid_Proceed() {
        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
        var curAddr = await getCurAddr();
          var WMATICcontract = new web3.eth.Contract(TAT2ABI,config.tat2address);
          var bidderWMATICBln = await WMATICcontract.methods.balanceOf(AccepBidSelect.tokenBidAddress).call();
          var allowance = await WMATICcontract.methods.allowance(AccepBidSelect.tokenBidAddress,item.contractAddress).call()
          allowance = parseFloat(allowance) / 1000000000000000000;
          bidderWMATICBln = bidderWMATICBln / 1000000000000000000;
          //var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet) - ((parseFloat((parseFloat(AccepBidSelect.tokenBidAmt)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(AccepBidSelect.NoOfToken));
          var passAmt = parseFloat(YouWillPayFee) + parseFloat(YouWillGet);

          passAmt = passAmt.toFixed(config.toFixed)
          passAmt = await web3.utils.toWei((passAmt).toString());
          passAmt = await convert(passAmt)
          var valueesss = (parseFloat(YouWillPayFee))+
                          (parseFloat(AccepBidSelect.tokenBidAmt)*
                          parseFloat(AccepBidSelect.NoOfToken))
          // if(valueesss.toFixed(((allowance).toString()).length)>0 && bidderWMATICBln>=valueesss.toFixed((((allowance).toString()).length))){
          // if(valueesss.toFixed(((allowance).toString()).length) <= parseFloat(allowance)){
          if(parseFloat(valueesss)>0 && parseFloat(bidderWMATICBln)>=parseFloat(valueesss)){
          if(parseFloat(valueesss) <= parseFloat(allowance)){
          if(NoOfToken_NeedToSend) {
            if(item.type==721){
              var CoursetroContract = new web3.eth.Contract(single, item.contractAddress);
              setAccaptCallStatus('processing');
              
              CoursetroContract
              .methods
              .acceptBId(
                config.symbol,
                AccepBidSelect.tokenBidAddress,
                passAmt,
                item.tokenCounts,
              )
              .send({from:Accounts})
              .then(async (result) => {
                
                var acceptBId_Payload = {
                  tokenCounts: item.tokenCounts,
                  NoOfToken : NoOfToken_NeedToSend, //AccepBidSelect.NoOfToken,
                  tokenBidAddress: AccepBidSelect.tokenBidAddress,
                  UserAccountAddr_byaccepter: curAddr,
                  transactionHash: result.transactionHash
                }
                var Resp = await acceptBId_Action(acceptBId_Payload);
                setAccaptCallStatus('done');
                setTimeout(() => window.$('.modal').modal('hide'), 600);
                window.location.reload();
              })
              .catch((err) => {
                toast.error("Accept failed", toasterOption);
                setAccaptCallStatus('tryagain');
              })

            }else{
              var CoursetroContract = new web3.eth.Contract(multiple, item.contractAddress);
              setAccaptCallStatus('processing');
              CoursetroContract
              .methods
              .acceptBId(
                config.symbol,
                AccepBidSelect.tokenBidAddress,
                passAmt,
                item.tokenCounts,
                NoOfToken_NeedToSend
              )
              .send({from:Accounts})
              .then(async (result) => {
                
                var acceptBId_Payload = {
                  tokenCounts: item.tokenCounts,
                  NoOfToken : NoOfToken_NeedToSend, //AccepBidSelect.NoOfToken,
                  tokenBidAddress: AccepBidSelect.tokenBidAddress,
                  UserAccountAddr_byaccepter: curAddr,
                  transactionHash: result.transactionHash
                }
                var Resp = await acceptBId_Action(acceptBId_Payload);
                setAccaptCallStatus('done');
                setTimeout(() => window.$('.modal').modal('hide'), 600);
                window.location.reload();
              })
              .catch((err) => {
                toast.error("Accept failed", toasterOption);
                setAccaptCallStatus('tryagain');
              })
            }
           }
           }else{
            toast.error("Bidder doesnt Approve Enough Balance ", toasterOption);
           }
          }else{
            toast.error("Bidder doesnt have Enough Balance ", toasterOption);
          }
        
    }
    async function proceedclose(){
     setTimeout(() => window.$('#proceed_bid_modal').modal('hide'), 600);
    }
    async function Placebidmodal(){ 
      setTimeout(() => window.$('#place_bid_modal').modal('hide'), 600);
      //setTimeout(() => window.location.reload(false), 900);
    }
    useImperativeHandle(
      ref,
      () => ({
        async PlaceABid_Click(item) {
          Set_item(item)
          Set_BidformSubmit(false);
          var connectwallet=localStorage.getItem("tksmudisgjeush");
          if(!connectwallet){
            window.location.href="/connect";
            return false;
          }
          if(Bids && Bids.myBid && Bids.myBid.tokenBidAmt) {
            Set_NoOfToken(Bids.myBid.NoOfToken);
          }
          window.$('#place_bid_modal').modal('show');
        },
        async PriceCalculate(data={}) {
          PriceCalculate_this(data);
        },
        async AcceptBid_Select(curBid_val) {
            Set_AccepBidSelect(curBid_val);
            if(curBid_val && parseFloat(curBid_val.tokenBidAmt)>0) {
              window.$('#accept_modal').modal('show');
              Set_AccepBidSelect(curBid_val);
              if(parseInt(TokenBalance) < parseInt(curBid_val.pending) && parseInt(TokenBalance)!=0) {
                Set_NoOfToken_NeedToSend(TokenBalance);
                var totalAmt = TokenBalance * parseFloat(curBid_val.tokenBidAmt);
              }
              else {
                Set_NoOfToken_NeedToSend(curBid_val.pending);
                var totalAmt = curBid_val.pending * parseFloat(curBid_val.tokenBidAmt);
              }
               var mydata = await getmylog();
               const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
               var sfee = await web3.utils.toWei((curBid_val.servicepercent).toString())
               var mfee = await web3.utils.toWei((curBid_val.servicepercent).toString())
               if(item.type == 721 ){
                 var ServiceFee_val = (totalAmt * (sfee)) / 1e20;
               }else{
                  var ServiceFee_val = (totalAmt * (mfee)) / 1e20;
               }
               var royamt = totalAmt * (parseFloat(item.tokenRoyality)/100) 
               setroyaltyamt(royamt)
              //var YouWillGet_Val = totalAmt - (parseFloat(ServiceFee_val)+ parseFloat(royamt));
              var YouWillGet_Val = (totalAmt - (parseFloat(ServiceFee_val)));
              Set_YouWillPayFee(ServiceFee_val);
              Set_YouWillGet(YouWillGet_Val);
            }
          },
        async CancelBid_Select(curBid_val) {
          if(
            curBid_val
            && curBid_val.pending > 0
            &&
            (
              curBid_val.status == 'pending'
              || curBid_val.status == 'partiallyCompleted'
            )
          ) {
            Set_AccepBidSelect(curBid_val);
            window.$('#cancel_modal').modal('show');
          }
          else {
            window.$('.modal').modal('hide')
          }
        }
      }),
    )

    useEffect(() => {
      Validation_PlaceABid(BidformSubmit);
      getfee();
    }, [
      tokenBidAmt,
      NoOfToken
    ])

    async function getfee(){
     var Resp = await getfees();
     if(Resp && Resp.result && Resp.result.data){
        setsinglefee(Resp.result.data.singleservicefee)
        setmultiplefee(Resp.result.data.multipleservicefee)
     }
    }

    function convert(n){
      var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead,decimal,pow] = n.toString()
       .replace(/^-/,"")
       .replace(/^([0-9]+)(e.*)/,"$1.$2")
       .split(/e|\./);
       return +pow < 0
      ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
      : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
    }


    return (
      <div>
        <div id="Validation_PlaceABid" onClick={() => Validation_PlaceABid(BidformSubmit)}></div>
        {/* place_bid Modal */}
        <div class="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="place_bid_modalLabel">Place a bid</h5>
                <p className="text-center place_bit_desc">You are about to place a bid for</p>
                <p className="place_bit_desc_2"><span className="text_red mr-2">{item.tokenName}</span></p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={(Placebidmodal)}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body px-0">
                  <form className="px-4 bid_form">
                    <label for="bid">Your bid</label>
                    <div class="input-group mb-3 input_grp_style_1">
                      <input
                          type="text"
                          name="tokenBidAmt"
                          id="tokenBidAmt"
                          class="form-control"
                          placeholder="Enter your bid amount"
                          aria-label="bid"
                          aria-describedby="basic-addon2"
                          onChange={inputChange}
                          autoComplete="off"
                          // value={tokenBidAmt}
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2">TAT2</span>
                      </div>
                    </div>
                    {ValidateError.tokenBidAmt && <span className="text-danger"><br/>{ValidateError.tokenBidAmt}</span>}
                    {item.type == config.multipleType && <label for="qty">Enter quantity <span className="label_muted pl-2">({AllowedQuantity} available)</span></label> }
                    {item.type == config.multipleType && <div class="mb-3 input_grp_style_1">
                    <input
                        type="text"
                        name="NoOfToken"
                        id="NoOfToken"
                        class="form-control"
                        placeholder="Enter your bid quantity"
                        onChange={inputChange}
                        autoComplete="off"
                        value={NoOfToken}
                        onKeyUp={onKeyUp}
                    />
                    </div>}
                    {ValidateError.NoOfToken && <span className="text-danger"><br/>{ValidateError.NoOfToken}</span>}
                    <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Your balance</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{UserAccountBal} {config.symbol}</p>
                    </div>
                    </div>
                    <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Your bidding balance</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{UserAccountBal} {config.symbol}</p>
                    </div>
                    </div>
                    <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Minimum Bid Amount</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{item.minimumBid} {config.symbol}</p>
                    </div>
                    </div>
                    <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">Service fee</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{item.type==721?singlefee:multiplefee}% <span>{config.symbol}</span></p>
                    </div>
                    </div>
                    <div className="row pb-3">
                    <div className="col-12 col-sm-6">
                        <p className="buy_desc_sm">You will pay</p>
                    </div>
                    <div className="col-12 col-sm-6 text-sm-right">
                        <p className="buy_desc_sm_bold">{YouWillPay}  {config.symbol}</p>
                    </div>
                    </div>

                    <div className="text-center">
                    {/* data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal" */}
                    <Button className="create_btn btn-block" onClick={() => FormSubmit_PlaceABid()} >Place a bid</Button>
                    </div>

                </form>
                </div>
            </div>
            </div>
        </div>
        {/* end place_bid modal */}

        {/* proceed_bid Modal */}
        <div class="modal fade primary_modal" id="proceed_bid_modal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={proceedclose}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <form>
                    <div className="media approve_media">
                    <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                    {/* <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                    <div className="media-body">
                        <p className="mt-0 approve_text">Approve</p>
                        <p className="mt-0 approve_desc">Checking balance and approving</p>
                    </div>
                    </div>
                    <div className="text-center my-3">
                    <Button
                        className={"btn-block " + ( (BidApply_ApproveCallStatus=='processing' || BidApply_ApproveCallStatus=='done') ? 'btn_outline_red' : 'create_btn')}
                        disabled={(BidApply_ApproveCallStatus=='processing' || BidApply_ApproveCallStatus=='done')}
                        onClick={BidApply_ApproveCall}
                        >
                        {BidApply_ApproveCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                        {BidApply_ApproveCallStatus == 'init' && 'Approve'}
                        {BidApply_ApproveCallStatus == 'processing' && 'In-progress...'}
                        {BidApply_ApproveCallStatus == 'done' && 'Done'}
                        {BidApply_ApproveCallStatus == 'tryagain' && 'Try Again'}
                        </Button>
                    </div>
                    <div className="media approve_media">
                    <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                    <div className="media-body">
                        <p className="mt-0 approve_text">Signature</p>
                        <p className="mt-0 approve_desc">Create a signature to place a bid</p>
                    </div>
                    </div>
                    <div className="text-center mt-3">
                    <Button
                        className={"btn-block " + ( (BidApply_ApproveCallStatus!='done' || BidApply_SignCallStatus=='processing' || BidApply_SignCallStatus=='done') ? 'btn_outline_red' : 'create_btn')}
                        disabled={(BidApply_ApproveCallStatus!='done' || BidApply_SignCallStatus=='processing' || BidApply_SignCallStatus=='done')}
                        onClick={BidApply_SignCall}
                    >
                        {BidApply_SignCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                        {BidApply_SignCallStatus == 'init' && 'Start'}
                        {BidApply_SignCallStatus == 'processing' && 'In-progress...'}
                        {BidApply_SignCallStatus == 'done' && 'Done'}
                        {BidApply_SignCallStatus == 'tryagain' && 'Try Again'}
                    </Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        {/* end proceed_bid modal */}

        {/* accept bid Modal */}
        <div class="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="accept_modalLabel">Accept bid</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body px-0">
                <div className="img_accept text-center">
                    {
                       item && item.image && item.image.split('.').pop() == "mp4" ?
                        <video src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" controls autoPlay controls playsInline loop />
                        :
                        item && item.image && item.image.split('.').pop() == "mp3" ?
                        <img src={require("../../assets/images/music.png")} style={{backgroundColor:"white"}} alt="Collections" className="img-fluid" />
                        :
                        <img src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
                    }
                </div>
                <p className="text-center accept_desc">
                    <span className="buy_desc_sm">You are about to accept bid for</span>
                    <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                    <span className="buy_desc_sm pl-2">from</span>
                    <span className="buy_desc_sm_bold pl-2 buy_desc_address">{AccepBidSelect.tokenBidAddress} </span>
                </p>
                <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {config.symbol} for {NoOfToken_NeedToSend} {item.tokenName}</p>
                <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Service fee in %</p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{AccepBidSelect.servicepercent}% <span>{config.symbol}</span></p>
                    </div>
                </div>
                <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Service fee </p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{(YouWillPayFee).toFixed(config.toFixed)} <span>{config.symbol}</span></p>
                    </div>
                </div>
                <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Royalty fee in %</p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{item && item.tokenRoyality?item.tokenRoyality:0} % <span>{config.symbol}</span></p>
                    </div>
                </div>
                <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">Royalty fee </p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                    <p className="buy_desc_sm_bold">{(((parseFloat((parseFloat(AccepBidSelect.tokenBidAmt)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(AccepBidSelect.NoOfToken))).toFixed(5)} <span>{config.symbol}</span></p>
                    </div>
                </div>
                <div className="row mx-0 pb-3">
                    <div className="col-12 col-sm-6 px-4">
                    <p className="buy_desc_sm">You will get</p>
                    </div>
                    <div className="col-12 col-sm-6 px-4 text-sm-right">
                    {item && item.tokenCreator == Accounts ? 
                    <p className="buy_desc_sm_bold">{(parseFloat(YouWillGet)+parseFloat((((parseFloat((parseFloat(AccepBidSelect.tokenBidAmt)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(AccepBidSelect.NoOfToken))))).toFixed(5)} {config.symbol}</p>
                    :
                    <p className="buy_desc_sm_bold">{(parseFloat(YouWillGet)-parseFloat((((parseFloat((parseFloat(AccepBidSelect.tokenBidAmt)*(parseFloat(item.tokenRoyality)/100))))*parseFloat(AccepBidSelect.NoOfToken))))).toFixed(5)} {config.symbol}</p>
                    }
                    </div>
                </div>
                <form className="px-4">
                    <div className="text-center">
                    <Button 
                    className="create_btn btn-block" 
                    onClick={() => AcceptBid_Proceed()}
                    disabled={(AcceptCallStatus=='processing')}
                    >
                      {AcceptCallStatus == 'processing' && <i class="fa fa-spinner mr-3 spinner_icon" aria-hidden="true" id="circle1"></i >}
                    Accept bid</Button>
                    <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        {/* end accept bid modal */}

        {/* accept bid Modal */}
        <div class="modal fade primary_modal" id="cancel_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="accept_modalLabel">Cancel bid</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body px-0">
                <div className="img_accept text-center">
                   {
                       item && item.image && item.image.split('.').pop() == "mp4" ?
                        <video src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" controls autoPlay controls playsInline loop />
                        :
                        item && item.image && item.image.split('.').pop() == "mp3" ?
                        <img src={require("../../assets/images/music.png")} style={{backgroundColor:"white"}} alt="Collections" className="img-fluid" />
                       :
                       <img src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
                    }
                </div>
                <p className="text-center accept_desc">
                    <span className="buy_desc_sm">You are about to cancel bid for</span>
                    <span className="buy_desc_sm_bold pl-2">{item.tokenName}</span>
                </p>
                <p className="info_title text-center">{AccepBidSelect.tokenBidAmt} {config.symbol} for {AccepBidSelect.NoOfToken} </p>
                <form className="px-4">
                    <div className="text-center">
                    <Button className="create_btn btn-block" onClick={() => CancelBid_Proceed(AccepBidSelect)}>Cancel bid</Button>
                    <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        {/* end accept bid modal */}
      </div>
    )
})

