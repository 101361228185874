import React, {
    forwardRef,
    useImperativeHandle
} from 'react';
import { Button } from '@material-ui/core';
import '@metamask/legacy-web3'

import config from '../../lib/config';
import {
    TattooRequest,
    requestcheck,
    accepttattoorequest
} from '../../actions/token';
import { getmylog } from '../../helper/walletconnect';

import multiple from '../../ABI/multiple.json'
import single from '../../ABI/single.json';
import Web3 from 'web3';
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';
toast.configure();
let toasterOption = config.toasterOption;

export const TattooNowRef = forwardRef((props, ref) => {

    const [description, setdescription] = React.useState("");
    const [ValidateError, Set_ValidateError] = React.useState({});
    const [itemId, Set_itemId] = React.useState("");
    const [tokenCounts, Set_tokenCounts] = React.useState("");
    const [Set_tattooBtn, tattooBtn]= React.useState(false);
    const [creator,setcreator]= React.useState("");
    const [acceptdata,setacceptdata]= React.useState("");
    const [contract,set_contract]= React.useState("");
    const [type,set_type] =  React.useState("");
    const [process,setprocess] = React.useState(false);

    var {
        UserAccountAddr
    } = props;

    useImperativeHandle(
        ref,
        () => ({
            async Tattoorequest_Click(item) {
                var data = {
                    address: UserAccountAddr,
                    tokenCounts: item.tokenCounts
                }
                var issubmited = await requestcheck(data)
                if(issubmited && issubmited.data && issubmited.data.data && issubmited.data.data.useraddress){
                      toast.error("Already Request Submitted", toasterOption);
                }else{
                if(item && item._id){
                    setcreator(item.tokenCreator)
                    Set_itemId(item._id);
                    Set_tokenCounts(item.tokenCounts);
                    set_contract(item.contractAddress)
                    set_type(item.type)
                    window.$('#tattoo_modal').modal('show');
                }
               }
            },
            async Tattooaccept_Click(item) {
                if(item && item._id){
                   setacceptdata(item)
                   window.$('#tattooaccept_modal').modal('show');
                }
            }
        }),
    )

    const inputChange = (e) => {
        if(e && e.target && typeof e.target.value != 'undefined') {
            var value = e.target.value;
            setdescription(value);
            if(e.target.value !=""){
                Set_ValidateError({});
            }
        }
    }

   
    async function submitTattoo(){
        if(description && description!=null && description!=undefined && description!=""){
        var reqData ={
            useraddress:UserAccountAddr,
            description:description,
            tokenCounts:tokenCounts,
            creatoraddress: creator,
            "type" : type,
            "contract" : contract
        }
        var response = await TattooRequest(reqData);
            toast.success("Successfully submitted", toasterOption);
            setdescription("");
            setTimeout(function(){
                window.$('#tattoo_modal').modal('hide');
            },100)
        }else{
           toast.error("Enter detail to move further", toasterOption); 
        }
    }

    async function cancelTattoo(){
        setdescription("");
        window.$('#tattoo_modal').modal('hide');
    }

    async function acceptTattoo(){
        try{
        setprocess(true);
        var connectwallet=localStorage.getItem("tksmudisgjeush");
        if(!connectwallet){
          setprocess(false);
          window.location.href="/connect";
          return false;
        }

        var mydata = await getmylog();
        const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
 
            if(acceptdata.type == 721){
                var Tatcontract =  new web3.eth.Contract(single,acceptdata.contract);
                 Tatcontract.methods
                .enableTokenStatus(
                    true,
                    acceptdata.tokenCounts
                )
                .send({ from: props.Accounts })
                .then(async (result) => {
                var reqData ={
                    id: acceptdata._id,
                    tokenCounts: acceptdata.tokenCounts,
                }
                var response = await accepttattoorequest(reqData);
                    toast.success("Successfully Done", toasterOption);
                    setTimeout(function(){
                        window.$('#tattooaccept_modal').modal('hide');
                        window.location.reload()
                    },100)
                })
                .catch((error) => {
                    setprocess(false);
                    toast.error('Failed Try Again Later', toasterOption)
                })
             }else if(acceptdata.type == 1155){
                var Tatcontract =  new web3.eth.Contract(multiple,acceptdata.contract);
                 Tatcontract.methods.enableTokenStatus(
                    true,
                    acceptdata.tokenCounts
                )
                .send({ from: props.Accounts })
                .then(async (result) => {
                var reqData ={
                    id: acceptdata._id,
                    tokenCounts: acceptdata.tokenCounts,
                }
                var response = await accepttattoorequest(reqData);
                    toast.success("Successfully Done", toasterOption);
                    setTimeout(function(){
                        window.$('#tattooaccept_modal').modal('hide');
                        window.location.reload()
                    },100)
                })
                .catch((error) => {
                    setprocess(false);
                    toast.error('Failed Try Again Later', toasterOption)
                })
             }

      }catch(errr){
      }
    }

    async function cancelaccept(){
        setprocess(false);
        window.$('#tattooaccept_modal').modal('hide');
    }

    return (
        <div>
        <div class="modal fade primary_modal" id="tattoo_modal" tabindex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="report_modalLabel">Why are you Request this?</h5>
                <button type="button" class="close" onClick={() => cancelTattoo()} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <p>Tell us your details and reason</p>
                <form>
                    <label className="primary_label" htmlFor="name">Message</label>
                    <textarea onChange={inputChange} value={description} className="form-control primary_inp" rows="3" name="description" placeholder="Tell us some details"></textarea>
                    {ValidateError.description && <span className="text-danger">{ValidateError.description}</span>}
                    <div className="report_btn_grp mt-3">
                    <Button  type="button" className="create_btn" onClick={() => submitTattoo()}>Submit</Button>
                    <Button className="cancel_btn ml-2" onClick={() => cancelTattoo()}>Cancel</Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
      </div>

      <div class="modal fade primary_modal" id="tattooaccept_modal" tabindex="-1" role="dialog" aria-labelledby="report_modalCenteredLabel" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                <h5 class="modal-title" id="report_modalLabel">Are you sure , want to accept this request?</h5>
                <button type="button" class="close" onClick={() => cancelaccept()} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                <form>
                    <div className="report_btn_grp mt-3 justify-content-center d-flex">
                    {process ?
                        <Button  type="button" className="create_btn" >In Progress...</Button>
                        :
                        <Button  type="button" className="create_btn" onClick={() => acceptTattoo()}>Accept</Button>
                    }
                    <Button className="cancel_btn ml-2" onClick={() => cancelaccept()}>Cancel</Button>
                    </div>
                </form>
                </div>
            </div>
            </div>
      </div>

        </div>
    )
})

