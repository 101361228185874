import React, { useEffect  , useRef ,  useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from '@material-ui/core';
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link , useParams ,   useHistory  } from "react-router-dom";
import { idText } from "typescript";
import ArtistBackground from '../assets/images/artist_banner.png';
import { PlaceAndAcceptBidRef } from './separate/PlaceAndAcceptBidRef';
import { LikeRef } from './separate/LikeRef';
import { PlaceABidRef } from './separate/PlaceABidRef';
import { BurnRef } from './separate/BurnRef'
import { PutOnSaleRef } from './separate/PutOnSaleRef';
import { PurchaseNowRef } from './separate/PurchaseNowRef';
import { WalletRef } from './separate/WalletRef';
import { CancelOrderRef } from './separate/CancelOrderRef';
import { ReportNowRef } from './separate/ReportNowRef';
import { ShareNowRef } from './separate/ShareNowRef';
import { TransferRef } from './separate/TransferRef'
import { TattooNowRef } from './separate/TattooNowRef';
import TokenItem from './separate/Token-Item';
import ConnectWallet from './separate/Connect-Wallet';
import Searchref from "./separate/Search";
import tat2abi from '../ABI/TAT2.json';
import CountUp from 'react-countup';
import config from '../lib/config';
import moment from "moment"
import { toastAlert }  from "../actions/toastAlert";
import Web3 from 'web3';
import '@metamask/legacy-web3';
import Countdown, { zeroPad } from "react-countdown";
import {Helmet} from "react-helmet";
import { getmylog } from '../helper/walletconnect';

import {
  getCurAddr,
  halfAddrShow,
  getprofilerawdetail
} from '../actions/users';
import {
  TokenCounts_Get_Detail_Action,
  CollectiblesList_Home,
  viewupdate,
  Bidding_Detail_Action,
  HistoryList,
  emailsubmit,
  getcmsdetails,
  TokenCountsDetail
} from '../actions/token';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

let localstore      = localStorage.getItem("tksmudisgjeush")  


// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Art(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [logo,setlogo] = React.useState(localStorage.getItem("logo"));

  const renderer = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s Left</span>;
        }
    };

  const renderer1 = ({ days, Month, Year, hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span></span>
        } else {
            return <span>{days}d  {hours}h {minutes}m {seconds}s to Start</span>;
        }
    };

 useEffect(async() => {
  getinitital()
  }, [])

 async function getinitital(){
    getcms();
    if(localstore){

    }else{
      TokenListCall();
      tokendetailcall()
    }
    if(localStorage.getItem("tksmudisgjeush")){
      var datas = {
        address : localStorage.getItem("address")
      }
      var userblock = await getprofilerawdetail(datas)
      if(userblock && userblock.data && userblock.data.data && userblock.data.data.data){
        setblock(userblock.data.data.data.deleted)
      }
    }
 }

  var LikeForwardRef = useRef();
  var PutOnSaleForwardRef = useRef();
  var PurchaseNowForwardRef = useRef();
  var BurnForwardRef = useRef();
  var CancelOrderForwardRef = useRef();
  var WalletForwardRef = useRef();
  var ReportForwardRef = useRef();
  var ShareForwardRef = useRef();
  var PlaceABidForwardRef = useRef();
  var TattooForwardRef = useRef();
  var TransferForwardRef = useRef();

  const [UserAccountAddr, Set_UserAccountAddr] = React.useState('');
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState('');
  const [WalletConnected, Set_WalletConnected] = React.useState('false');
  const [fullLoader, Set_fullLoader] = useState(false);
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [item, Set_item] = useState({});
  const [tokenCounts_Detail, Set_tokenCounts_Detail] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState({});
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const [Bids, Set_Bids] = useState([]);
  const [AccepBidSelect, Set_AccepBidSelect] = useState(0);
  const [tokenBidAmt, Set_tokenBidAmt] = useState(0);
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [ValidateError, Set_ValidateError] = useState({});
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [YouWillPayFee, Set_YouWillPayFee] = useState(0);
  const [YouWillGet, Set_YouWillGet] = useState(0);
  const [singlefee, setsinglefee] = useState(0);
  const [multiplefee, setmultiplefee] = useState(0);
  const [LikedTokenList, setLikedTokenList] = React.useState([]);
  const [tokenHistory, set_tokenHistory] = React.useState([]);
  const [BidApply_ApproveCallStatus, Set_BidApply_ApproveCallStatus] = React.useState('init');
  const [BidApply_SignCallStatus, Set_BidApply_SignCallStatus] = React.useState('init');
  const [HitItem, Set_HitItem] = useState({});
  const [BuyOwnerDetail, Set_BuyOwnerDetail] = useState({});
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [totaluserbidAmt,setTotaluserbidAmt] = React.useState(0);
  const [onwer_price,set_owner_price]=useState({})
  const [MyItemAccountAddr, Set_MyItemAccountAddr] = React.useState('');
  const [sort, setsort] = React.useState('recent');
  const [page, setpage] = React.useState(0);
  const [tokenlist, settokenlist] = useState([]);
  const [email,setemail] = React.useState("");
  const [block,setblock] = useState(1)

  var { tokenname,tokenidval } = useParams();

  var display_item = 0
  var currDate = new Date();
  var startdate = new Date(item.clocktime);
  var enddate = new Date(item.endclocktime);
  var auction = "false";
  var finish = "";
  var enddate1 = "";
  var showlist = "true";
  var mytr = ""
  if(item && item.Tattoorequest && item.Tattoorequest.length>0 && UserAccountAddr && UserAccountAddr!=null && UserAccountAddr!=undefined && UserAccountAddr!=""){
       mytr = (item.Tattoorequest).filter(x=>x.useraddress==UserAccountAddr)
    }
  if (item && item.type==721&&item.PutOnSaleType=="TimedAuction") {
      auction = "true";
      var a = moment(item.clocktime);
      var b = moment(item.endclocktime);
      var c = moment();
      a.diff(b); // 86400000
      var diffInMs = a.diff(c);
      finish = b.diff(c);
      enddate1 = parseFloat(diffInMs);
      display_item = a.diff(c)
    // if(currDate<startdate){
    //   var auctionTxt = "Start";
    // }else
     if (finish > 0) {
      showlist = "true";
      } else {
      var auctionTxt = "Ended";
      showlist = "false";
      }
  }

  const AfterWalletConnected = async () => {
    var curAddr = await getCurAddr();
    var payload = {
      curAddr:curAddr,
      tokenCounts:tokenidval
    };
    getviewupdate(payload)
    TokenCounts_Get_Detail_Call(payload);
    BiddingAmout();
    TokenListCall()
    var mydata = await getmylog();
    const web3 =  new Web3(mydata && mydata.provider && mydata.provider!=null && mydata.provider!=undefined && mydata.provider!=""?mydata.provider:window.ethereum);
    var CoursetroContract = new web3.eth.Contract(tat2abi, config.tat2address);
    var tokenBal = await CoursetroContract.methods.balanceOf(curAddr).call();
    var tokenBalance = tokenBal / config.decimalvalues;
    Set_MyTokenBalance(tokenBalance.toFixed(5));
    try{
      LikeForwardRef && LikeForwardRef.current && LikeForwardRef.current.getLikesData();
    }catch(err){

    }
    getBiddingHistory();
  }


  async function getcms(){
    var result = await getcmsdetails()
    if(result.result){
      setlogo(config.baseurl+"/cmsimage/"+result.result.headerlogo)
      localStorage.setItem("logo",config.baseurl+"/cmsimage/"+result.result.headerlogo)
    }
  }

  async function getBiddingHistory() {
    var payload = {
      tokenCounts: tokenidval
    };
    var Resp = await HistoryList(payload);
    if (Resp && Resp.data && Resp.data.list) {
      set_tokenHistory(Resp.data.list);
    }
  }

  async function tokendetailcall(){
    var payload = {
      tokenCounts: tokenidval
    };
    var Resp = await TokenCountsDetail(payload);
    if (Resp && Resp.result && Resp.result.data && Resp.result.data.Detail && Resp.result.data.Detail.Resp) {

      var TokenResp = Resp.result.data.Detail.Resp;
 
      Set_tokenCounts_Detail(TokenResp);
       if(TokenResp.Token && TokenResp.Token[0]) {
        if(TokenResp.Token[0].balance>0){

        }else{
          window.location.href="/"
        }
      } 
      if(TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }
      var IndexVal = -1;
      var newMyTokenBalance = 0;
        Set_TokenBalance(0);
        Set_MyTokenDetail({});
      if(TokenResp.TotalQuantity) {
        Set_AllowedQuantity(TokenResp.balance - newMyTokenBalance);
      }
      else {
        Set_AllowedQuantity(0);
      }

      if(TokenResp.Token && TokenResp.Token[0]) {
        if(TokenResp.Token && TokenResp.Token[0]) {
        if(TokenResp.Token[0].balance>0){
          Set_item(TokenResp.Token[0]);
        }else{
          window.location.href="/"
        }
      } 
      }
    }
  }

  async function BiddingAmout(){
    try{
      var currAddr = await getCurAddr();
      var payload={currAddr}
      var Resp = await Bidding_Detail_Action(payload);
      if(Resp&&Resp.data){
        setTotaluserbidAmt(Resp.data.res1)
      }
    }catch(err){
    }
  }

  const getviewupdate = async (payload) => {
    var Resp = await viewupdate(payload);
  }

  const TokenCounts_Get_Detail_Call = async (payload) => {
    var curAddr = await getCurAddr();
    var Resp = await TokenCounts_Get_Detail_Action(payload);
    if (Resp && Resp && Resp.data && Resp.data.Detail && Resp.data.Detail.Resp) {

      var TokenResp = Resp.data.Detail.Resp;
      if(
        TokenResp
        && TokenResp.Token
        && TokenResp.Token[0]
        && TokenResp.Token[0].tokenowners_current
      ) {

        for (let i = 0; i < TokenResp.Token[0].tokenowners_current.length; i++) {
          const element = TokenResp.Token[0].tokenowners_current[i];
          if(element.balance > 0 && element.tokenPrice > 0 && element.tokenOwner != curAddr) {
            Set_BuyOwnerDetailFirst(element);
            set_owner_price(element)
            break;
          }
        }
      }
      Set_tokenCounts_Detail(TokenResp);

      if(TokenResp.Bids) {
        Set_Bids(TokenResp.Bids);
      }

      var IndexVal = -1;

      if(TokenResp.Token[0].tokenowners_all && curAddr) {
        var tokenowners_all = TokenResp.Token[0].tokenowners_all;
        IndexVal = tokenowners_all.findIndex(val => val.tokenOwner.toString() == curAddr.toString());
      }

      var newMyTokenBalance = 0;

      if(IndexVal > -1) {
        newMyTokenBalance = tokenowners_all[IndexVal].balance
        Set_TokenBalance(newMyTokenBalance);
        Set_MyTokenDetail(tokenowners_all[IndexVal]);
      }
      else {
        newMyTokenBalance = 0;
        Set_TokenBalance(0);
        Set_MyTokenDetail({});
      }

      if(TokenResp.TotalQuantity) {
        Set_AllowedQuantity(TokenResp.TotalQuantity - newMyTokenBalance);
      }
      else {
        Set_AllowedQuantity(0);
      }
      if(TokenResp.Token && TokenResp.Token[0]) {
         if(TokenResp.Token[0].balance>0){
          Set_item(TokenResp.Token[0]);
        }else{
          window.location.href="/"
        }
      } 
    }
  }

  async function GetUserBal() {
    await WalletForwardRef.current.GetUserBal();
  }

  async function TokenListCall(data={}) {
    var currAddr = await getCurAddr();
    var name = "All";
    var filter = "recent"
    if (data.CatName) {
      name = data.CatName
    }
    if (data.lowtohigh) {
      filter = "low"
    }else if (data.hightolow) {
      filter = "high"
    }else if (data.recent) {
      filter = "recent"
    }else{
      filter = sort
    }
    var payload = {
      limit: config.limit,
      page: parseInt(page)>0?page:1,
      currAddr: currAddr,
      CatName: name,
      "filter" : filter,
      from:'Home'
    }
     var resp = await CollectiblesList_Home(payload);
    if (resp && resp.data && resp.data.from == 'token-collectibles-list-home' && resp.data.list && resp.data.list.length > 0) {
      settokenlist(resp.data.list);
    }
    else {
      settokenlist("")
    }
  }


  async function submitemail(){
    var re = /\S+@\S+\.\S+/;
    var test = re.test(email);
    if(test == true){
      var data ={"email":email}
       var result = await emailsubmit(data);
       if(result.data.err){
        toastAlert("error","Newsletter Already Subscribed", "error")
        setemail("")
       }else{
        toastAlert("success","subscribed Successfully", "success")
        setemail("")
       }
    }else{
      toastAlert("error","Enter your valid email Id", "error")
    }
  }

  const inputChangeemail = (e) => {
    setemail(e.target.value)
  }


  return (
    <div className="inner_header">
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
        Set_fullLoader={Set_fullLoader}
        fullLoader={fullLoader}
      />
      <PutOnSaleRef
        ref={PutOnSaleForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
      <PlaceAndAcceptBidRef
        ref={PlaceABidForwardRef}
        Set_WalletConnected = {Set_WalletConnected}
        Set_UserAccountAddr = {Set_UserAccountAddr}
        Set_UserAccountBal = {Set_UserAccountBal}
        Set_AddressUserDetails = {Set_AddressUserDetails}
        Set_Accounts = {Set_Accounts}
        Set_MyItemAccountAddr = {Set_MyItemAccountAddr}
        Set_tokenCounts = {Set_tokenCounts}
        Set_item = {Set_item}
        Set_tokenCounts_Detail = {Set_tokenCounts_Detail}
        Set_MyTokenBalance = {Set_MyTokenBalance}
        Set_TokenBalance = {Set_TokenBalance}
        Set_Bids = {Set_Bids}
        Set_AccepBidSelect = {Set_AccepBidSelect}
        Set_tokenBidAmt = {Set_tokenBidAmt}
        Set_NoOfToken = {Set_NoOfToken}
        Set_ValidateError = {Set_ValidateError}
        Set_YouWillPay = {Set_YouWillPay}
        Set_YouWillPayFee = {Set_YouWillPayFee}
        Set_YouWillGet = {Set_YouWillGet}
        Set_BidApply_ApproveCallStatus = {Set_BidApply_ApproveCallStatus}
        Set_BidApply_SignCallStatus = {Set_BidApply_SignCallStatus}
        WalletConnected = {WalletConnected}
        UserAccountAddr = {UserAccountAddr}
        UserAccountBal = {UserAccountBal}
        AddressUserDetails = {AddressUserDetails}
        Accounts = {Accounts}
        MyItemAccountAddr = {MyItemAccountAddr}
        tokenCounts = {tokenCounts}
        item = {item}
        tokenCounts_Detail = {tokenCounts_Detail}
        MyTokenBalance = {MyTokenBalance}
        TokenBalance = {TokenBalance}
        Bids = {Bids}
        AccepBidSelect = {AccepBidSelect}
        tokenBidAmt = {tokenBidAmt}
        NoOfToken = {NoOfToken}
        ValidateError = {ValidateError}
        YouWillPay = {YouWillPay}
        YouWillPayFee = {YouWillPayFee}
        YouWillGet = {YouWillGet}
        BidApply_ApproveCallStatus = {BidApply_ApproveCallStatus}
        BidApply_SignCallStatus = {BidApply_SignCallStatus}
        AllowedQuantity = {AllowedQuantity}
        totaluserbidAmt={totaluserbidAmt}
        setTotaluserbidAmt={setTotaluserbidAmt}
      />
      <PurchaseNowRef
        ref={PurchaseNowForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
        MyItemAccountAddr={UserAccountAddr}
      />
      <WalletRef
        ref={WalletForwardRef}
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_WalletConnected={Set_WalletConnected}
        Set_UserAccountBal={Set_UserAccountBal}
      />
      <TransferRef
        ref={TransferForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <LikeRef
        ref={LikeForwardRef}
        setLikedTokenList={setLikedTokenList}
        MyItemAccountAddr={MyItemAccountAddr}
        AfterWalletConnected={AfterWalletConnected}
      />
      <BurnRef
        ref={BurnForwardRef}
        GetUserBal={GetUserBal}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
      />
      <TattooNowRef
        UserAccountAddr={UserAccountAddr}
        item={item}
        Set_item={Set_item}
        ref={TattooForwardRef}
        Accounts={Accounts}
      />
      <CancelOrderRef
        ref={CancelOrderForwardRef}
        Set_HitItem={Set_HitItem}
        item={HitItem}
        UserAccountAddr={UserAccountAddr}
        UserAccountBal={UserAccountBal}
        MyTokenBalance={MyTokenBalance}
        Accounts={Accounts}
        GetUserBal={GetUserBal}
      />
       <ReportNowRef
        UserAccountAddr={UserAccountAddr}
        ref={ReportForwardRef}
      />
      <ShareNowRef
        ref={ShareForwardRef}
      />
      <head>
      <Helmet>
      <meta charSet="utf-8" />
      <meta property="og:title" content={item && item.tokenName && "NFT TATTOOMONEY -"+item.tokenName} />
      <meta property="og:description" content={item && item.tokenDesc && item.tokenDesc} />
      <meta property="og:image" content=  {
                     item && item.image && item.image.split('.').pop() == "mp4" ?
                      `${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`
                      :
                      item && item.image && item.image.split('.').pop() == "mp3" ?
                      require("../assets/images/music.png")
                      :
                     `${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`
                    }/>
      </Helmet> 
      </head>
      <Header
        fixed
        color="transparent"
        routes={dashboardRoutes}
        brand={<img src={logo} alt="logo" className="img-fluid" />}
        rightLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 50,
          color: "dark"
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        <div>
          <GridContainer className="mx-0">
            <GridItem xs={12} sm={12} md={12} className="px-0">
              <div className="container mb-3">
                <div className="title_flex">
                  <a href={config.fronturl} className="back_btn"><i className="bi bi-chevron-left mr-2"></i>go back</a>
                  <Searchref />
                </div>
              </div>
              <div className="items_bg single_art_bg">
                <div className="container">
                  <div className="bg_card_details_panel">
                    <div className="bg_card_details_left">
                      <h3>{item && item.tokenName}</h3>
                      <p>{item && item.tokenDesc}</p>
                    </div>
                    <div className="bg_card_details_center label_position">
                    {
                     item && item.image && item.image.split('.').pop() == "mp4" ?
                      <video src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} type="video/mp4" alt="Collections" className="img-fluid" controls autoPlay controls playsInline loop />
                      :
                      item && item.image && item.image.split('.').pop() == "mp3" ?
                      <img src={require("../assets/images/music.png")} style={{backgroundColor:"white"}} alt="Collections" className="img-fluid" />
                      :
                      <img src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`} alt="Collections" className="img-fluid " />
                    }
                     
                     {(item && item.PutOnSaleType == "TimedAuction"&& showlist == "true") && display_item <=0 &&
                      (<badge className="badge badge-secondary timer_panel">
                        <Countdown
                        date={enddate}
                        autoStart={true}
                        onStart={() => startdate}
                        renderer={renderer}
                        ></Countdown>
                      <i class="fas fa-fire ml-2"></i>
                      </badge>)}
                       {(item && item.PutOnSaleType == "TimedAuction"&& showlist == "true") && display_item >0 &&
                      (<badge className="badge badge-secondary timer_panel">
                        <Countdown
                        date={startdate}
                        autoStart={true}
                        onStart={() => Date.now()}
                        renderer={renderer1}
                        ></Countdown>
                      <i class="fas fa-fire ml-2"></i>
                      </badge>)}


                       {(item && item.image && item.image.split('.').pop() == 'mp3') ?
                     <audio src={`${config.baseurl+"/nftImg"}/${item.tokenCreator}/${item.image}`}  type="audio/mp3"controls className="audio">
                     </audio>:""
                    }
                    {item.istattooed==true && item.isskintattoo == true ?
                        <span className="label_new">Tattooed</span>
                        :
                        item.isskintattoo == true  && mytr && mytr.length>0 && 
                        <span className="label_new">Tattoo Had Been Requested</span>
                      }
                    </div>
                     
                   


                    <div className="bg_card_details_right flex-column">
                      <div className="bg_card_details_right_nav_outer">
                        <ul class="nav nav-tabs">
                          <li class="active"><a data-toggle="tab" href="#Info" class="active">Info</a></li>
                          <li><a data-toggle="tab" href="#Owner">Owner</a></li>
                          <li><a data-toggle="tab" href="#Bid">Bid</a></li>
                          <li><a data-toggle="tab" href="#History">History</a></li>
                          <li><a data-toggle="tab" href="#Details">Details</a></li>                          
                          { TokenBalance!=undefined && TokenBalance!=null && TokenBalance!="" && parseInt(TokenBalance)>0 && item && item.unlockcontent &&  item.unlockcontent!="false" && 
                            <li><a data-toggle="tab" href="#unlock">Unlock Content</a></li>
                          }
                        </ul>
                      </div>

                  
                  <div class="tab-content">
                    <div id="Info" class="tab-pane fade in active show">
                    <div>
                    <p className="royeltire">Creator {item && item.tokenRoyality} % Royalties</p>
                      <div className="creators_details">


                        {(item && item.tokenCreatorInfo && item.tokenCreatorInfo.curraddress && item.tokenCreatorInfo.image && item.tokenCreatorInfo.image!=null && item.tokenCreatorInfo.image!=undefined && item.tokenCreatorInfo.image!="") ? 
                        <a href={item && item.tokenCreatorInfo && item.tokenCreatorInfo.name && item.tokenCreatorInfo.name[0] && 
                          item.tokenCreatorInfo.name[0]!=undefined && item.tokenCreatorInfo.name[0]!=null && item.tokenCreatorInfo.name[0]!=""?`${config.fronturl}/user/${item.tokenCreatorInfo.name[0]}`:
                         `${config.fronturl}/user/${item.tokenCreatorInfo.curraddress}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${ item.tokenCreatorInfo.name}`}>
                          <img src={`${config.baseurl}/profile/${item.tokenCreatorInfo.image}`} alt="Creator" className="img-fluid" />
                        </a>
                        :
                        <a href={item && item.tokenCreatorInfo && item.tokenCreatorInfo.name && item.tokenCreatorInfo.name[0] && 
                          item.tokenCreatorInfo.name[0]!=undefined && item.tokenCreatorInfo.name[0]!=null && item.tokenCreatorInfo.name[0]!=""?`${config.fronturl}/user/${item.tokenCreatorInfo.name[0]}`:
                         `${config.fronturl}/user/${item.tokenCreator}`} data-toggle="tooltip" data-placement="top" title={`Creator : ${ item.tokenCreator}`}>
                          <img src={`${config.baseurl}/images/noimage.png`} alt="Creator" className="img-fluid" />
                        </a>
                      }
                        <span>{item && item.tokenCreator && (item.tokenCreator).substring(0, 6)+"......."+(item.tokenCreator).substring(37,42)}</span>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="followers_txt mr-3">
                          <h3>{item.likecount}</h3>
                          <p>Favorites</p>
                        </div>
                        <div className="followers_txt">
                          <h3>{item.viewcount}</h3>
                          <p>Views</p>
                        </div>
                      </div>
                      <div className="mt-3">
                        {WalletConnected==true &&
                         (LikedTokenList.findIndex(tokenCounts => (tokenCounts.tokenCounts === item.tokenCounts)) > -1)
                         ?(<Button className="follow_btn" onClick={() => LikeForwardRef.current.hitLike(item)}><i className="bi bi-heart mr-2 liked" style={{cursor:'pointer'}}></i>Remove from favorites</Button>)
                         :WalletConnected==true && (<Button className="follow_btn" onClick={() => LikeForwardRef.current.hitLike(item)}><i className="bi bi-heart mr-2" style={{cursor:'pointer'}}></i>Add to favorites</Button>)
                        }
                        <Button className="follow_btn ml-2" onClick={() => ShareForwardRef.current.ShareSocial_Click(item)}>Share</Button>
                      </div>
                       {
                      (tokenCounts_Detail.TotalQuantity > TokenBalance
                      ) && item.tokenowners_current && item.tokenowners_current.length > 0 ?(
                      <div className="mt-3">

                        { block==1 &&
                          BuyOwnerDetailFirst && BuyOwnerDetailFirst.tokenOwner && localstore
                          &&
                            <Button className="primary_btn" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item, BuyOwnerDetailFirst)} >Buy Now</Button> 
                          }                          
                         {block==1 && ((item && item.PutOnSaleType!='TimedAuction')  || (item && item.PutOnSaleType=='TimedAuction' && showlist == "true") )&&
                          Bids
                          && Bids.myBid
                          && !Bids.myBid.status && localstore && display_item<=0 
                          ?
                            <Button className="primary_btn ml-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item)}>
                              Bid now 
                            </Button>
                          :block==1 &&
                            Bids
                            && Bids.myBid
                            && Bids.myBid.status
                            && Bids.myBid.status == 'pending'  && localstore  && display_item<=0 ?
                              <Button className="primary_btn ml-2" onClick={() => PlaceABidForwardRef.current.PlaceABid_Click(item)}>
                                Edit a bid 
                              </Button>
                            :block==1 &&
                              Bids
                              && Bids.myBid
                              && Bids.myBid.status
                              && Bids.myBid.status == 'partiallyCompleted' && localstore &&
                              <Button className="primary_btn ml-2" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(Bids.myBid)}>
                                Cancel a bid
                              </Button>
                          }
                      </div>
                      ):("")}
                    </div>
                    </div>
                    <div id="Owner" class="tab-pane fade">
                    {
                      item.tokenowners_current && item.tokenowners_current.length > 0 && item.tokenowners_current.map((itemCur, i) => {
                      return (
                     <div className="creators_details flex_col_c mb-3">
                      <div className="d-flex mb-2">
                      <img src={
                        (item.tokenOwnerInfo && item.tokenOwnerInfo.image && item.tokenOwnerInfo.image.length>0 && item.tokenOwnerInfo.image[i] != '' && item.tokenOwnerInfo.image[i] != undefined && item.tokenOwnerInfo.image[i] != null
                        ? `${config.baseurl}/profile/${item.tokenOwnerInfo.image[i]}`
                        : `${config.baseurl}/images/noimage.png`)
                        } alt="Owner" />

                         <div className="">
                            <div className="mr-2 d-flex align-items-center flex-wrap">
                              <p>
                                <div className="mr-1 d-inline-block">
                                  {
                                  (item.tokenOwnerInfo && item.tokenOwnerInfo.name && item.tokenOwnerInfo.name.length>0 && item.tokenOwnerInfo.name[i] != '' && item.tokenOwnerInfo.name[i] != undefined && item.tokenOwnerInfo.name[i] != null
                                  ? item.tokenOwnerInfo.name[i]
                                  : (itemCur.tokenOwner).substring(0, 6)+"......."+(itemCur.tokenOwner).substring(37,42))
                                  }
                                </div>

                                { itemCur.tokenPrice > 0 && 
                              <>
                                {itemCur.balance}/{itemCur.quantity} on sale for 
                                {itemCur.tokenPrice} {config.symbol} {itemCur.quantity > 0 && 'each'}</> }

                                { itemCur.tokenPrice == 0 && 
                              <>{itemCur.balance}/{itemCur.quantity} Not for sale</>}
                              
                              </p>
                              
                            </div>
                                
                              <div className="mar_clas_ssss p-0">
                                {block==1 &&
                                itemCur.tokenPrice > 0 && itemCur.balance > 0 && itemCur.tokenOwner != UserAccountAddr && localstore &&
                                <Button className="primary_btn mt-1" onClick= { () =>PurchaseNowForwardRef.current.PurchaseNow_Click(item,itemCur)} >Buy Now</Button>
                                }
                                { itemCur.tokenPrice == 0 && itemCur.balance > 0 
                                && localstore && (itemCur.tokenOwner == UserAccountAddr ) &&
                                  <Button className="primary_btn mt-1" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Put On Sale</Button>
                                }

                                {
                                itemCur.tokenPrice > 0 && itemCur.balance > 0 
                                && localstore && (itemCur.tokenOwner == UserAccountAddr ) &&
                                  <Button className="primary_btn mt-1" onClick={() => PutOnSaleForwardRef.current.PutOnSale_Click(item, MyTokenDetail)}>Change Price</Button>
                                      }
                                      
                              </div>
                         </div>                         
                      </div>
                      
                            
                     </div>
                   )})}
                    {/* <div className="mar_clas_ssss">
                       <Button className="primary_btn">Buy Now</Button>
                       <Button className="primary_btn">Bid Now</Button>
                     </div> */}
                    </div>

                    <div id="History" class="tab-pane fade">
                       <div className="creators_details mb-3 flex_col_c">
                          <div className="proposal_panel_overall">
                    <div className="inner_div_info">
                      {tokenHistory && tokenHistory.length > 0 && tokenHistory.map((list) => {
                        var txt = "";
                        if (list.actiontype == "bidrequest") {
                          txt = "Bid ";
                        } else if (list.actiontype == "sales") {
                          txt = "Minted  ";
                        } else if (list.actiontype == "purchase") {
                          txt = "Purchase ";
                        }

                        return (
                          <div class="asset__action">
                            {list && list.userData && list.userData.image && list.userData.image != "" ?
                              <img src={config.baseurl + "/profile/" + list.userData.image} alt="User" className="img-fluid mr-3"/>
                              :
                              <img src={require("../assets/images/noimage.png")} alt="User" className="img-fluid mr-3"/>
                            }
                            <p>{txt}
                              {
                                list.actiontype == "bidrequest" &&
                                <b>{list.price} TAT2 </b>
                              }
                              {
                                list.actiontype == "purchase" &&
                                <b>{list.price} TAT2 </b>
                              }
                              by  <a href="#">{halfAddrShow(list.useraddress)}</a>
                              <br />
                              at  {" "}
                              {moment(list.timestamp).fromNow()}
                              <br /> </p>
                          </div>
                        )
                      })
                      }
                      {tokenHistory && tokenHistory.length == 0 &&
                        <div class="asset__action">
                          <p>No History</p>
                        </div>
                      }
                    </div>
                  </div>
                       </div>
                    </div>

                    <div id="Bid" class="tab-pane fade">
                    {Bids && Bids.pending && Bids.pending.length > 0 && Bids.pending.map((curBid) => {
                      return (
                    <div className="creators_details mb-3 flex_col_c">
                    <div className="d-flex">
                    {curBid.userinfo && curBid.userinfo[0] && curBid.userinfo[0].image ?(
                         <a href={curBid && curBid.userinfo[0] && curBid.userinfo[0].name && curBid.userinfo[0].name!=undefined && curBid.userinfo[0].name!=null && curBid.userinfo[0].name!="" ?`${config.fronturl}/user/${curBid.userinfo[0].name}`: `${config.fronturl}/user/${curBid.userinfo[0].curraddress}`} title={`Highest Bidder : ${curBid.userinfo[0].name}`}>
                          <img src={`${config.baseurl}/profile/${curBid.userinfo[0].image}`} alt="User"  />
                         </a>
                          ):(
                          <a href={curBid && curBid.userinfo && curBid.userinfo[0] && curBid.userinfo[0].name && curBid.userinfo[0].name!=undefined && curBid.userinfo[0].name!=null && curBid.userinfo[0].name!="" ?`${config.fronturl}/user/${curBid.userinfo[0].name}`:`${config.fronturl}/user/${curBid.tokenBidAddress}`} title={`Highest : ${curBid.tokenBidAddress}`}>
                            <img src={`${config.baseurl}/images/noimage.png`} alt="User" />
                          </a>
                          )}
                          <div className="d-flex flex-row">
                            <p>{curBid.tokenBidAmt} {config.symbol} By {halfAddrShow(curBid.tokenBidAddress)} {tokenCounts_Detail.TotalQuantity > 0 && (<>for {curBid.pending}/{curBid.NoOfToken} {item.tokenName}</>)} at {moment(curBid.timestamp).format('MMMM Do YYYY, h:mm a')}</p>
                          </div>
                    </div>
                          
                          {UserAccountAddr && UserAccountAddr != curBid.tokenBidAddress && item && item.tokenowners_current && item.tokenowners_current.length>0 && item.tokenowners_current.findIndex(e => e.tokenOwner == UserAccountAddr) > -1 && localstore &&
                            <Button className="primary_btn" onClick={() => PlaceABidForwardRef.current.AcceptBid_Select(curBid)}>Accept</Button>
                          }
                          {UserAccountAddr && UserAccountAddr == curBid.tokenBidAddress && localstore &&
                            <Button className="primary_btn" onClick={() => PlaceABidForwardRef.current.CancelBid_Select(curBid)}>Cancel</Button>
                          }
                     </div>
                     ) }) }
                    </div>


                    <div id="unlock" class="tab-pane fade">
                    <div className="creators_details mb-3 flex_col_c">
                    <div className="d-flex">
                    <span>{item && item.unlockcontent && item.unlockcontent!="false" && item.unlockcontent}</span>
                    </div>
                    </div>
                    </div>
                     <div id="Details" class="tab-pane fade">
                     <div className="properticeee">
                     <p>Properties</p>
                     <span>{item && item.tokenProperty}</span>
                     </div>
                     </div> 
                   </div>
                  </div>
                    
                </div>
                </div>
              </div>
            </GridItem>
          </GridContainer>
        </div>

        <div className="container">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <h2 className="main_title mt-5">Other artworks</h2>
              <div className="masonry">
                {(tokenlist  && tokenlist.length > 0)?(tokenlist.map((item) => {
                      return (
                         <TokenItem
                          id = {item._id}
                          item={item}
                          Set_item={Set_item}
                          LikedTokenList={LikedTokenList}
                          hitLike={LikeForwardRef.current.hitLike}
                          UserAccountAddr={UserAccountAddr}
                          UserAccountBal={UserAccountBal}
                          PutOnSale_Click={PutOnSaleForwardRef.current.PutOnSale_Click}
                          PurchaseNow_Click={PurchaseNowForwardRef.current.PurchaseNow_Click}
                          PlaceABid_Click={PlaceABidForwardRef.current.PlaceABid_Click}
                          Set_Bids={Set_Bids}
                          Bids = {Bids}
                          Set_BuyOwnerDetailFirst={Set_BuyOwnerDetailFirst}
                          Set_tokenCounts_Detail={Set_tokenCounts_Detail}
                          Set_MyTokenBalance = {Set_MyTokenBalance}
                          Set_MyTokenDetail={Set_MyTokenDetail}
                          Set_TokenBalance={Set_TokenBalance}
                          Set_AllowedQuantity={Set_AllowedQuantity}
                          Set_YouWillPay = {Set_YouWillPay}
                          Set_YouWillPayFee = {Set_YouWillPayFee}
                          Set_YouWillGet = {Set_YouWillGet}
                          // Bid_Click={PlaceABidForwardRef.current.Bid_Click}
                          Burn_Click={BurnForwardRef.current.Burn_Click}
                          Transfer_Click = {TransferForwardRef.current.Transfer_Click}
                          CancelOrder_Click={CancelOrderForwardRef.current.CancelOrder_Click}
                          WalletConnected={WalletConnected}
                          Tattoorequest_Click={TattooForwardRef.current.Tattoorequest_Click}
                          SubmitReport_Click={ReportForwardRef.current.SubmitReport_Click}
                          ShareSocial_Click={ShareForwardRef.current.ShareSocial_Click}
                          from = {tokenidval}
                        />
                      )})):('No record Found')
                  }                               
              </div>
              {/*<div className="text-center mt-5">
                <Button className="primary_btn">Load More</Button>
              </div>*/}
            </GridItem>
          </GridContainer>
        </div>

        {/* Newsletter Section */}
         <div className="newsletter_section">
          <div className="container">
            <h2>Newsletter</h2>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">                        
                  <input type="email" className="form-control primary_inp" onChange={inputChangeemail} id="email" value={email} placeholder="Email*"/>
                </div>
              </div>
              {/*<div className="custom_checkbox">
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck2"/>
                  <label className="custom-control-label" htmlFor="customCheck2">Lorem ipsum dolor sit amet rivacy elit sed.</label>
                </div>
              </div>*/}
              <div className="mt-3 text-center">
                <Button className="primary_btn" onClick={submitemail}>Subscribe</Button>
              </div>
            </form>
          </div>
        </div>

      </div>
      <Footer />

       {/* Buy Modal */}
       <div className="modal fade primary_modal" id="buy_modal" tabindex="-1" role="dialog" aria-labelledby="buy_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="buy_modalLabel">Checkout</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body px-0">
              <div className="row mx-0 bor_bot_modal">
                <div className="col-12 col-md-12 px-4">
                  <p className="buy_title_sm">Seller</p>
                  <p className="buy_title_md">Uniaqua</p>
                </div>
                <div className="col-12 col-md-12 px-4">
                  <p className="buy_title_sm">Buyer</p>
                  <p className="buy_title_md">NFTbuyer</p>
                </div>
              </div>
              <div className="bor_bot_modal mb-3">
                <p className="buy_title_md px-4 py-3 text-center mb-0">1.01 TAT2</p>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Your balance</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">10.25 TAT2</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 TAT2</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will pay</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 TAT2</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_modal">Proceed to payment</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end buy modal */}

      {/* proceed Modal */}
      <div className="modal fade primary_modal" id="proceed_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <p className="mt-0 purchase_text text-center">Purchase</p>
                <p className="mt-0 purchase_desc text-center">Send transaction with your wallet</p>

                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Inprogress</Button>
                </div>


              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed modal */}

      {/* place_bid Modal */}
      <div className="modal fade primary_modal" id="place_bid_modal" tabindex="-1" role="dialog" aria-labelledby="place_bid_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="place_bid_modalLabel">Place a bid</h5>
              <p className="text-center place_bit_desc text-light">You are about to place a bid for</p>
              <p className="place_bit_desc_2"><span className="text-light mr-2">Uniaqua</span><span className="text-muted">by</span><span className="text-light ml-2">NFTbuyer</span></p>


              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="px-4 bid_form">
                <div class="input-group mb-3 input_grp_style_1">
                  <input type="text" id="bid" class="form-control" placeholder="Enter your bid amount" aria-label="bid" aria-describedby="basic-addon2" />
                  <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">TAT2</span>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">10.25 TAT2</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Your bidding balance</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">0.012 TAT2</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">Service fee</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 TAT2</p>
                  </div>
                </div>
                <div className="row pb-3">
                  <div className="col-12 col-sm-6">
                    <p className="buy_desc_sm">You will pay</p>
                  </div>
                  <div className="col-12 col-sm-6 text-sm-right">
                    <p className="buy_desc_sm_bold">1.112 TAT2</p>
                  </div>
                </div>

                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close" data-toggle="modal" data-target="#proceed_bid_modal">Place a bid</Button>

                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end place_bid modal */}

       {/* proceed_bid Modal */}
       <div className="modal fade primary_modal" id="proceed_bid_modal" tabindex="-1" role="dialog" aria-labelledby="proceed_bid_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="proceed_bid_modalLabel">Follow Steps</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3 pro_complete" aria-hidden="true"></i>
                  {/* <i className="fa fa-spinner mr-3 spinner_icon" aria-hidden="true"></i> */}
                  <div className="media-body">
                    <p className="mt-0 approve_text">Approve</p>
                    <p className="mt-0 approve_desc">Checking balance and approving</p>
                  </div>
                </div>
                <div className="text-center my-3">
                  <Button className="btn_outline_red btn-block">Done</Button>
                </div>
                <div className="media approve_media">
                  <i className="fas fa-check mr-3" aria-hidden="true"></i>
                  <div className="media-body">
                    <p className="mt-0 approve_text">Signature</p>
                    <p className="mt-0 approve_desc">Create a signature to place a bid</p>
                  </div>
                </div>
                <div className="text-center mt-3">
                  <Button className="create_btn btn-block" disabled>Start</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end proceed_bid modal */}

      {/* accept bid Modal */}
      <div className="modal fade primary_modal" id="accept_modal" tabindex="-1" role="dialog" aria-labelledby="accept_modalCenteredLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header text-center">
              <h5 className="modal-title" id="accept_modalLabel">Accept bid</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="img_accept text-center">
                <img src={require("../assets/images/info_01.png")} alt="Collections" className="img-fluid" />
              </div>
              <p className="text-center accept_desc">
                <span className="buy_desc_sm">You are about to accept bid for</span>
                <span className="buy_desc_sm_bold pl-2">Uniaqua</span>
                <span className="buy_desc_sm pl-2">from</span>
                <span className="buy_desc_sm_bold pl-2">0xb6631a76645...b7de</span>
              </p>
              <p className="info_title text-center">0 TAT2 for 1 edition(s)</p>


              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">Service fee</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">0.012 TAT2</p>
                </div>
              </div>
              <div className="row mx-0 pb-3">
                <div className="col-12 col-sm-6 px-4">
                  <p className="buy_desc_sm">You will get</p>
                </div>
                <div className="col-12 col-sm-6 px-4 text-sm-right">
                  <p className="buy_desc_sm_bold">1.112 TAT2</p>
                </div>
              </div>
              <form className="px-4">
                <div className="text-center">
                  <Button className="create_btn btn-block" data-dismiss="modal" aria-label="Close">Accept bid</Button>
                  <Button className="btn_outline_red btn-block" data-dismiss="modal" aria-label="Close">Cancel</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* end accept bid modal */}

    </div>
  );
}
